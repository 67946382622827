export const PettyCash_STATUS = {
  pending: { key: 1, text: 'Pending' },
  approved: { key: 2, text: 'Approved' },
  canceled: { key: 3, text: 'Canceled' },
}

export const PREPAID_STATUS = {
  void: { key: 1, text: 'Void' },
  penalty: { key: 2, text: 'Penalty' },
  add_prepaid: { key: 3, text: 'Add Prepaid', factor: 1 },
  clear_prepaid: { key: 4, text: 'Clear Prepaid', factor: -1 },
}

export const instituteNoScheduleObj = {
  fail: { key: 1, text: "Fail" },
  drop: { key: 2, text: "Drop" },
  coming_back: { key: 3, text: "Coming Back" },
  change_program: { key: 4, text: "Change Program" },
};
export const scheduleModuleObj = {
  academic_schedule: { key: 'academic_schedule', text: 'Academic schedule', order: 1, icon: 'how_to_vote' },
  freshman_schedule: { key: 'freshman_schedule', text: 'Admission schedule', order: 2, icon: 'face' },
  accelerated_schedule: { key: 'accelerated_schedule', text: 'Accelerated schedule', order: 3, icon: 'layers' },
  abc_schedule: { key: 'abc_schedule', text: 'ABC class schedule', order: 4, icon: 'flag' },
  short_course_schedule: { key: 'short_course_schedule', text: 'Short course schedule', order: 5, icon: 'favorite' },
  institute: { key: 'institute', text: 'Institute and Center', order: 6, icon: 'school' },
}
export const academicProgram = [
  { key: 'associate', routerId: 'associate', active: true, icon: 'supervised_user_circle', name: 'Associate', order: 0, text: 'Associate', level: 'Undergraduate', type: 'ACADEMIC' },
  { key: 'bachelor', routerId: 'bachelor', active: true, icon: 'supervised_user_circle', name: 'Bachelor', order: 1, text: 'Bachelor', level: 'Undergraduate', type: 'ACADEMIC' },
  { key: 'pre_master', routerId: 'pre_master', active: true, icon: 'supervised_user_circle', name: 'Pre Master', order: 2, text: 'Pre Master', level: 'Pre Master', type: 'ACADEMIC' },
  { key: 'master', routerId: 'master', active: true, icon: 'supervised_user_circle', name: 'Master', order: 3, text: 'Master', level: 'Graduate', type: 'ACADEMIC' },
  { key: 'postgraduate', routerId: 'postgraduate', active: true, icon: 'supervised_user_circle', name: 'Doctor or PhD', order: 4, text: 'Doctor or PhD', level: 'Graduate', type: 'ACADEMIC' },
]

export const DISPLAY_STATUS_OBJECT = {
  'ACTIVE': { key: 1, text: 'Active' },
  'DISABLED': { key: 2, text: 'Disabled' },
}


export const BOOKS_INVENTORY_STATUS = [
  { key: 1, text: 'អាចខ្ចីបាន' },
  { key: 2, text: 'អាចអាន​បានតែក្នុងបណ្ណាល័យ' },
  { key: 3, text: 'រក្សាទុក្ខក្នុងស្តុក​' },
  { key: 4, text: 'សៀវភៅខូច​' },
  { key: 5, text: 'សៀវភៅ​បាត់​' },
];
export const DEVICE_INVENTORY_STATUS = [
  { key: 1, text: 'អាចខ្ចីបាន' },
  { key: 2, text: 'អាចប្រើប្រាស់តែក្នុងបណ្ណាល័យ' },
  { key: 3, text: 'រក្សាទុក្ខក្នុងស្តុក​' },
  { key: 4, text: 'ឧបករណ៍ខូច​' },
  { key: 5, text: 'ឧបករណ៍​បាត់​' },
];

export const STATUS_OBJECT = {
  'draft': { key: 1, text: 'Draft', router: 'draft' },
  'submitted': { key: 2, text: 'Submitted', router: 'submitted' },
  'deleted': { key: 3, text: 'Deleted', router: 'deleted' },
}
export const DISPLAY_STATUS = [
  { key: 0, text: 'All' },
  { key: 1, text: 'Active' },
  { key: 2, text: 'Disabled' },
]
export const ACADEMIC_YEAR_STATUS = [
  { key: 1, text: 'Hold' },
  { key: 2, text: 'Active' },
  { key: 3, text: 'Close' },
];
export const emStatus = [
  { key: 1, text: 'Active' },
  { key: 2, text: 'Inactive' },
  { key: 3, text: 'Terminate' },
  { key: 4, text: 'Resign' },
]
export const emStatusCompare = [
  { key: 1, text: 'active' },
  { key: 2, text: 'inactive' },
  { key: 3, text: 'terminate' },
  { key: 4, text: 'resign' },
]

export const SESSION_SHOW_STATUS = [
  { key: 1, text: 'Primary (Odd Number)' },
  { key: 2, text: 'Secondary (Even numbers)' },
]


export const MONTH_NUMBERS = [
  { key: 1, text: 'Odd Number' },
  { key: 2, text: 'Even numbers' },
]

export const AllowID = [
  { key: 1, text: 'No' },
  { key: 2, text: 'Yes' },
]

export const WorkingTypes = [
  { key: '1', text: "Full-time" },
  { key: '2', text: "Part-time" },
];

export const PromotiionType = [
  { key: 1, text: 'Promoted' },
  { key: 2, text: 'Assigned' },
]
export const Sector = [
  { key: 1, text: 'Private Sector' },
  { key: 2, text: 'Government' },
  { key: 3, text: 'NGO' },
  { key: 4, text: 'Government' },
  { key: 5, text: 'Other' },
]

export const EmployeeTypes = [
  { key: 1, text: "Khmer" },
  { key: 2, text: "Foreign" },
];

export const EmploymentStatus = [
  { key: 1, text: "Full Time Contract" },
  { key: 2, text: "Full Time Internship" },
  { key: 3, text: "Full Time Permanent" },
  { key: 4, text: "Part Time Contract" },
  { key: 5, text: "Part Time Internship" },
  { key: 6, text: "Part Time Permanent" },
];
export const MaritalStatus = [
  { key: 1, text: "Single" },
  { key: 2, text: "Married" },
  { key: 3, text: 'Divorced' },

];
export const titles = [
  { key: 1, text: "Prof." },
  { key: 2, text: "Dr." },
  { key: 3, text: "Mr." },
  { key: 4, text: "Mis." },
  { key: 5, text: "Ms." },
  { key: 6, text: "Mis." }
];
export const subjectTypes = [
  { key: 1, text: 'Foundation Course' },
  { key: 2, text: 'Directed Course' },
  { key: 3, text: 'Oriented Course' },
  { key: 4, text: 'Functional Skills Course' },
  { key: 5, text: 'Institutional Skills Course' },
  { key: 6, text: 'Basic Major Course' },
  { key: 7, text: 'Major Course' },
  { key: 8, text: 'Elective Course' },
  { key: 9, text: 'Graduation Path' },
  { key: 10, text: 'Other' },
]


export const DiplomaType = [
  { key: 1, text: 'NA' },
  { key: 2, text: 'High School Diploma' },
  { key: 3, text: 'Training Certificate' },
  { key: 4, text: 'AA' },
  { key: 5, text: 'BA' },
  { key: 6, text: 'BS' },
  { key: 7, text: 'LLB' },
  { key: 8, text: 'MA' },
  { key: 9, text: 'MBA' },
  { key: 10, text: 'MSc' },
  { key: 11, text: 'DBA' },
  { key: 12, text: 'PhD' },
]
export const EducationLevel = [
  { key: 1, text: 'NA' },
  { key: 2, text: 'Informal Training' },
  { key: 3, text: 'High School' },
  { key: 4, text: 'Vocational Training' },
  { key: 5, text: 'Associate Degree' },
  { key: 6, text: 'Bachelor' },
  { key: 7, text: 'Master' },
  { key: 8, text: 'Doctor PhD' },
]
export const enrollCourseStatus = {
  drop: { key: 1, text: 'Drop' },
  quit: { key: 2, text: 'Quit' },
}
export const SCHOOL_PROGRAM = [
  { key: 1, text: 'Nursery', },
  { key: 2, text: 'Kindergarten' },
  { key: 3, text: 'Primary School' },
  { key: 4, text: 'Secondary School' },
  { key: 5, text: 'High school' },
];
export const MONTHS_LIST = [
  { key: 1, text: '1 Month' },
  { key: 2, text: '2 Months' },
  { key: 3, text: '3 Months' },
  { key: 4, text: '4 Months' },
  { key: 5, text: '5 Months' },
  { key: 6, text: '6 Months' },
  { key: 7, text: '7 Months' },
  { key: 8, text: '8 Months' },
  { key: 9, text: '9 Months' },
  { key: 10, text: '10 Months' },
  { key: 11, text: '11 Months' },
  { key: 12, text: '12 Months' },
];
export const classroomFilterBy = [
  { key: "full_name", text: "Name" },
  { key: "mobile_phone", text: "Phone" },
  { key: "code", text: "Code" },
];
export const enrollStatusObj = {
  pending: { key: 1, text: "Pending" },
  add: { key: 2, text: "Add" },
  change: { key: 3, text: "Changed" },
  drop: { key: 4, text: "Drop" },
  quit: { key: 5, text: "Quit" },
};
export const PROGRAM_OPTION_OBJ = {
  yearBase: { key: 1, text: 'Year Base' },
  termBase: { key: 2, text: 'Term Base' },
}
export const appObjects = {
  cashier: { key: 'cashier', text: "Cashier" },
  // class_manager: { key: 'class_manager', text: "Class Manager" },
  testing: { key: 'testing_center', text: "Testing Center" },
  scholarship: { key: 'scholarship', text: "Scholarship" },
  finance: { key: 'finance', text: "Finance" },
  reporting: { key: 'reporting', text: "Reporting" },
  enrollment: { key: 'enrollment', text: "Class Manager" },
  human_resource: { key: 'human_resource', text: "Human Resource" },
  registrar: { key: 'registrar', text: "Registrar" },
  payroll: { key: 'payroll', text: "Payrolls" },
  inventory: { key: 'inventory', text: "Inventory" },
  analytics: { key: 'analytics', text: "Analytics" },
  reporting_app: { key: 'reporting_app', text: "Reporting App" },
  admin: { key: 'admin', text: "Administrator" },
};


export const appArray = [
  { key: 'testing_center', text: "Testing Center" },
  { key: 'cashier', text: "Cashier" },
  // { key: 'class_manager', text: "Class Manager" },
  { key: 'enrollment', text: "Class Manager" },
  { key: 'human_resource', text: "Human Resource" },
  { key: 'scholarship', text: "Scholarship" },
  { key: 'registrar', text: "Registrar" },
  { key: 'finance', text: "Finance" },
  { key: 'payroll', text: "Payrolls" },
  { key: 'inventory', text: "Inventory" },
  { key: 'analytics', text: "Analytics" },
  { key: 'reporting_app', text: "Reporting App" },
  { key: 'admin', text: "Administrator" },
]

// export const appModule = {
//   cashier: { key: 'cashier', text: "Cashier" },
//   class_manager: { key: 'class_manager', text: "Class Manager" },
//   testing: { key: 'testing_center', text: "Testing Center" },
//   scholarship: { key: 'scholarship', text: "Scholarship" },
//   finance: { key: 'finance', text: "Finance" },
//   reporting: { key: 'reporting', text: "Reporting" },
//   enrollment:{ key: 'enrollment', text: "Enrollment" },
//   human_resource:{ key: 'human_resource', text: "Human Resource" },
//   registrar:{ key: 'registrar', text: "Registrar" },
//   payroll:{ key: 'payroll', text: "Payrolls" },
//   inventory:{ key: 'inventory', text: "Inventory" },
//   analytics:{ key: 'analytics', text: "Analytics" },
//   reporting_app:{ key: 'reporting_app', text: "Reporting App" },
//   admin:{ key: 'admin', text: "Administrator" },
// };

export const memberOf = [
  { key: 0, text: "Administrator" },
  { key: 1, text: "Branch Manager" },
  { key: 2, text: "Guest" },
];

export const memberOfObj = {
  administrator: { key: 0, text: "Administrator" },
  branch_manager: { key: 1, text: "Branch Manager" },
  guest: { key: 2, text: "Guest" },
};

export const REPORT_ORDER = [
  { key: '1', text: 'Invoice No', orderString: 'invoice_no' },
  { key: '2', text: 'Payment Term', orderString: 'payment_term.key' },
  { key: '3', text: 'Cashier', orderString: 'received_by.key' },
  { key: '4', text: 'Campus', orderString: 'received_by.campus.key' },
];

export const Genders = [
  { key: 1, text: 'Male', text_kh: 'ប្រុស' },
  { key: 2, text: 'Female', text_kh: 'ស្រី' },
  // { key: 3, text: 'Monk' },
];

export const TermStatus = [
  { key: 1, text: 'Pending' },
  { key: 2, text: 'Active' },
  { key: 3, text: 'Close' },
];

export const batchStatusObj = {
  active: { key: 1, text: 'Active' },
  disable: { key: 2, text: 'Disable' },
  close: { key: 3, text: 'Close' },
}
export const SCAN_CARD_TYPE = {
  // students: { key: 1, name: 'Students', kh_name: 'សិស្ស' },
  lectures: { key: 2, name: 'Lectures', kh_name: 'សាស្ត្រាចារ្យ' },
  employees: { key: 3, name: 'Employees', kh_name: 'បុគ្គលិក' }
}

export const SCAN_CARD_TYPE_ARRAY = [
  // students: { key: 1, name: 'Students', kh_name: 'សិស្ស' },
  { key: 2, name: 'Lectures', kh_name: 'សាស្ត្រាចារ្យ' },
  { key: 3, name: 'Employees', kh_name: 'បុគ្គលិក' }
]
export const Scholarships = {
  Approval: { key: 0, text: "Approval" },
  Reject: { key: 1, text: "Reject" },
  Pending: { key: 2, text: "Pending" }
};
export const daysOfWeek = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7
};
export const ShiftStatusObj = {
  opening: { key: 1, name: 'Opening' },
  closed: { key: 2, name: 'Closed' },
}

export const Status = [
  { key: 1, text: 'Pending' },
  { key: 2, text: 'Active' },
  { key: 3, text: 'Close' },
]
export const programTypesList = [
  { key: 0, text: 'All' },
  { key: 1, text: 'Week Day' },
  { key: 2, text: 'Weekend' },
  { key: 3, text: 'WD/WK' },
]
export const programsObj = {
  undergraduate: 1,
  graduate: 2,
  postgraduate: 3,
  association: 4,
  short_course: 5,
  others: 6,
  abc_course: 7,
  testing_fee: 8,
  spel_fee: 9,
  english_program: 10,
  psis_program: 11,
}
export const programs = [
  { key: 1, text: 'Undergraduate' },
  { key: 2, text: 'Graduate' },
  { key: 3, text: 'Postgraduate' },
  { key: 4, text: 'Association' },
  { key: 5, text: 'Short Course' },
  { key: 6, text: 'Miscellaneous', name: 'Miscellaneous' },
  { key: 7, text: 'ABC Course' },
  { key: 10, text: 'English Program' },
  { key: 11, text: 'Japanese Program' },
]
export const programTypes = [
  { key: 1, text: 'Week Day' },
  { key: 2, text: 'Weekend' },
  { key: 3, text: 'Executive' },
]
export const feeStatus = [
  { key: 1, text: 'Active' },
  { key: 2, text: 'Disabled' },
]

export const paymentType = {
  cash: { key: 1, text: 'Cash' },
  wingOnline: { key: 2, text: 'Wing Online', note: "PAY WITH WING ONLINE" },
  wingMerchant: { key: 3, text: 'Wing Merchant', note: "PAY WITH WING MERCHANT PAYMENT" },
  abaBilling: { key: 4, text: 'ABA Billing', note: "PAY WITH ABA BILL PAYMENT" },
  acledaBilling: { key: 5, text: 'ACLEDA Billing', note: "PAY WITH ACLEDA BILL PAYMENT" },
  tmBilling: { key: 6, text: 'True Money Billing', note: "PAY WITH True Money BILL PAYMENT" },
}

export const BANK_PAYMENT = {
  WINGO: { key: "WING_O", text: 'WING ONLINE', order: 2 },
  WING: { key: "WING", text: 'WING', order: 3 },
  ABA: { key: "ABA", text: 'ABA', order: 4 },
  AC: { key: "AC", text: 'ACLEDA', order: 5 },
  TM: { key: "TM", text: 'TM', order: 6 },
}

export const InvoiceTypes = [
  { key: 1, text: "Testing" },
  { key: 2, text: "Tuition Fee" },
  { key: 3, text: "Short Course" },
  { key: 4, text: "Academic Bridging Course" },
  { key: 5, text: "SPEL" },
  { key: 6, text: "Miscellaneous" },
  { key: 7, text: "Scholarship Penalty" },
];

export const invoiceTypesObj = {
  testing: { key: 1, text: "Testing" },
  tuitionFee: { key: 2, text: "Tuition Fee" },
  shortCourse: { key: 3, text: "Short Course" },
  abcCourse: { key: 4, text: "Academic Bridging Course" },
  spel: { key: 5, text: "SPEL" },
  miscellaneous: { key: 6, text: "Miscellaneous" },
  scholarshipPenalty: { key: 7, text: "Scholarship Penalty" },
  registrationFee: { key: 8, text: "Registration Fee" },
  admissionFee: { key: 9, text: "Admission Fee" },
  otherFee: { key: 10, text: "Other Fee" },
  posFee: { key: 11, text: "Inventory Fee" },
};

export const invoiceTypeParams = {
  testing: { key: 1, text: "Testing" },
  tuition_fee: { key: 2, text: "Tuition Fee" },
  academic_program: { key: 2, text: "Academic Tuition Fee" },
  institute_and_center: { key: 2, text: "Institute Tuition Fee" },
  short_course: { key: 3, text: "Short Course" },
  abc: { key: 4, text: "Academic Bridging Course" },
  miscellaneous: { key: 6, text: "Miscellaneous" },
  scholarshipPenalty: { key: 7, text: "Scholarship Penalty" },
  registrationFee: { key: 8, text: "Registration Fee" },
  admissionFee: { key: 9, text: "Admission Fee" },
  otherFee: { key: 10, text: "Other Fee" },
  inventoryFee: { key: 11, text: "Inventory Fee" },
};

export const TestingStatus = {
  active: { key: 1, text: 'Active' },
  disables: { key: 2, text: 'Disabled' },
  expired: { key: 3, text: 'Expired' },
}

export const studentStatus = {
  activePsis: { key: "psis", active: true, firstPayment: false },
  activeFreshPsis: { key: "psis", active: true, firstPayment: true },
  disablePsis: { key: "psis", active: false, firstPayment: false },
  activeInstitute: { key: "institute", active: true, firstPayment: false },
  activeFreshInstitute: { key: "institute", active: true, firstPayment: true },
  disableInstitute: { key: "institute", active: false, firstPayment: false },
  activeAcademic: { key: "academic", active: true, firstPayment: false },
  activeFreshAcademic: { key: "academic", active: true, firstPayment: true },
  disableAcademic: { key: "academic", active: false, firstPayment: false },
}

export const enrollPrograms = {
  academic: { key: 1, text: 'Academic Program' },
  abc: { key: 2, text: 'Academic Bridging Course' },
  institutes: { key: 3, text: 'Institutes & Center' },
  shortCourse: { key: 4, text: 'Short Course' },
}

export const RegistrarStatus = {
  pending: { key: 1, text: 'Pending' },
  done: { key: 2, text: 'Done' },
  complete: { key: 3, text: 'Complete' },
  disables: { key: 4, text: 'Disabled' },
}

export const InvoiceTypesObj = {
  testing: { key: 1, text: 'Testing' },
  schoolFee: { key: 2, text: 'School Fee' },
  shortCourse: { key: 3, text: 'Short Course' },
}

export const enrollStatus = [
  { key: 1, text: "Pending" },
  { key: 2, text: "Add" },
  { key: 3, text: "Drop" }
];

export const allocatedStatus = [
  { key: 0, text: "Opening" },
  { key: 1, text: "Pending" },
  { key: 2, text: "Hold" },
  { key: 3, text: "Complete" }
];

export const allocatedStatusObj = {
  opening: { key: 0, text: "Opening" },
  pending: { key: 1, text: "Pending" },
  hold: { key: 2, text: "Hold" },
  complete: { key: 3, text: "Complete" }
};

export const enrollmentTypes = {
  PSIS: { key: "PSIS", text: "PSIS Program" },
  institute: { key: "INSTITUTE", text: "Institute program" },
  academic: { key: "ACADEMIC", text: "Academic Program" }
};

export const status = [
  { key: 1, text: "Active" },
  { key: 2, text: "Pending" },
  { key: 3, text: "Disabled" },
  { key: 4, text: "Closed" }
];

export const recordStatus = {
  active: { key: 1, text: 'Active' },
  disables: { key: 2, text: 'Disabled' },
}

export const admissionPrograms = {
  academic: { key: 1, text: 'Academic Program' },
  abc: { key: 2, text: 'ABC Course' },
  institutes: { key: 3, text: 'Institutes & Center' },
  shortCourse: { key: 4, text: 'Short Course' },
}

export const TestFeeStatus = {
  paid: { key: 1, name: 'Paid' },
  unpaid: { key: 2, name: 'Unpaid' },
  prepaid: { key: 3, name: 'Prepaid' },
  void: { key: 4, name: 'Void' },
  disables: { key: 5, name: 'Disables' },
}


export const searchQRFilterBy = [
  { key: "name", text: "Class" },
  { key: "serial_id", text: "Serial No" },
  { key: "puc_id", text: "Student ID" },
  { key: "full_name", text: "Student Name" },
];

export const searchFilterBy = [
  { key: "puc_id", text: "Student ID" },
  { key: "serial_id", text: "Serial No" },
  { key: "full_name", text: "Full Name" },
  { key: "first_name", text: "First Name" },
  { key: "last_name", text: "Last Name" },
  { key: "mobile_phone", text: "Phone Number" }
];

export const scheduleProgram = {
  academic: { key: "academic", text: "Academic Program" },
  abc: { key: "abc", text: "Academic Bridging Course" },
  shortCourse: { key: "short_course", text: "Short Course" },
  psis: { key: "psis", text: "PSIS Program" },
}

export const Days = [
  { key: 1, name: 'Monday' },
  { key: 2, name: 'Tuesday' },
  { key: 3, name: 'Wednesday' },
  { key: 4, name: 'Thursday' },
  { key: 5, name: 'Friday' },
  { key: 6, name: 'Saturday' },
  { key: 7, name: 'Sunday' },
]

export const Days_session = [
  { key: 1, name: 'Monday', name_kh: 'ចន្ទ', text: 'monday', shortName: 'Mon' },
  { key: 2, name: 'Tuesday', name_kh: 'អង្គារ', text: 'tuesday', shortName: 'Tue' },
  { key: 3, name: 'Wednesday', name_kh: 'ពុធ', text: 'wednesday', shortName: 'Wed' },
  { key: 4, name: 'Thursday', name_kh: 'ព្រហស្បតិ៍', text: 'thursday', shortName: 'Thu' },
  { key: 5, name: 'Friday', name_kh: 'សុក្រ', text: 'friday', shortName: 'Fri' },
  { key: 6, name: 'Saturday', name_kh: 'សៅរ៍', text: 'saturday', shortName: 'Sat' },
  { key: 7, name: 'Sunday', name_kh: 'អាទិត្យ', text: 'sunday', shortName: 'Sun' },
]

export const DaysNumber = [
  { key: 1, name: 'Day 1', code: 'day1' },
  { key: 2, name: 'Day 2', code: 'day2' },
]

export const locationTypes = [
  { key: 1, text: "Phnom Penh" },
  { key: 2, text: "Province" }
];

export const roomTypes = [
  { key: 1, text: "Class Room" },
  { key: 2, text: "Computer Lab" },
  { key: 3, text: "Conference Room" },
  { key: 4, text: "General" },
  { key: 5, text: "Meeting Room" },
  { key: 6, text: "LCD" },
  { key: 7, text: "Big" },
  { key: 8, text: "Medium" },
  { key: 9, text: "Small" },
  { key: 10, text: "Science Lab" },
];

export const floors = [
  { key: 0, text: "Ground Floor" },
  { key: 1, text: "1st Floor" },
  { key: 2, text: "2nd Floor" },
  { key: 3, text: "3rd Floor" },
  { key: 4, text: "4th Floor" },
  { key: 5, text: "5th Floor" },
  { key: 6, text: "6th Floor" },
  { key: 7, text: "7th Floor" },
  { key: 8, text: "8th Floor" },
  { key: 9, text: "9th Floor" },
  { key: 10, text: "10th Floor" },
  { key: 11, text: "11th Floor" },
  { key: 12, text: "12th Floor" },
  { key: 13, text: "13th Floor" },
  { key: 14, text: "14th Floor" },
  { key: 15, text: "15th Floor" },
];

export const studyTypes = [
  { key: 1, text: "Week Day" },
  { key: 2, text: "Weekend" }
];

export const STATUS_ARRAY_DATA = [
  { key: 1, text: "Active" },
  { key: 3, text: "Disabled" },
];

export const ATTENDANCE_TYPE = [
  { key: 1, text: "Check In" },
  { key: 2, text: "Check Out" },
];

export const statusArray = [
  { key: 1, text: "Active" },
  { key: 2, text: "Pending" },
  { key: 3, text: "Disabled" },
  { key: 4, text: "Closed" }
];
export const SCHOLARSHIP_TYPE = [
  { key: 1, text: "Academic" },
  { key: 2, text: "Other" },
];
export const SCHOLARSHIP_TYPE_OBJ = {
  academic: { key: 1, text: "Academic" },
  other: { key: 2, text: "Other" },
};

export const CAPITAL_FEE_TYPE = [
  { key: 1, name: "Registration fee", nameKh: "ថ្លៃចុះឈ្មោះ" },
  { key: 2, name: "Material Fee", nameKh: "ថ្លៃសម្ភារៈ" },
  { key: 3, name: "Building Fee", nameKh: "ថ្លៃអាគារ" },
];

export const YEAR_LIST = [
  { key: 2019, text: "2019-2020" },
  { key: 2020, text: "2020-2021" },
  { key: 2021, text: "2021-2022" },
  { key: 2022, text: "2022-2023" },
  { key: 2023, text: "2023-2024" },
  { key: 2024, text: "2024-2025" },
  { key: 2025, text: "2025-2026" },
  { key: 2026, text: "2026-2027" },
  { key: 2027, text: "2027-2028" },
  { key: 2028, text: "2028-2029" },
  { key: 2029, text: "2029-2030" },
  { key: 2030, text: "2030-2031" },
];
export const scholarStatus = [
  { key: 1, text: "Active" },
  { key: 2, text: "Finished" },
  { key: 3, text: "Terminate" },
  { key: 4, text: "Disabled" },
];

export const pucsearchFilterBy = [
  { key: "puc_id", text: "Student ID" },
  { key: "full_name", text: "Full Name" },
  { key: "first_name", text: "First Name" },
  { key: "last_name", text: "Last Name" },
  { key: "mobile_phone", text: "Phone Number" }
];
export const paymentStatus = {
  paid: { key: 1, text: "Paid" },
  unpaid: { key: 2, text: "Unpaid" },
  prepaid: { key: 3, text: "Prepaid" },
  void: { key: 4, text: "Void" },
};

export const EnrollStatus = {
  none: { key: 1, text: "None" },
  add: { key: 2, text: "Add" },
  change: { key: 3, text: "Changed" },
  drop: { key: 4, text: "Dropped" },
  assign: { key: 5, text: "Assign" }
};

export const scholarshipTypes = {
  monk: { key: 0, text: 'Monk' },
  scholarship: { key: 1, text: 'Scholarship' },
  loan: { key: 2, text: 'Loan' },
  sibling: { key: 3, text: 'Sibling' },
};


export const reportFilterBy = {
  today: { key: 'today', text: 'Today' },
  this_week: { key: 'this_week', text: 'This week' },
  this_month: { key: 'this_month', text: 'This month' },
  this_term: { key: 'this_term', text: 'This term' },
  this_year: { key: 'this_year', text: 'This year' },
  custom: { key: 'custom', text: 'Custom' },
};
export const reportFilterByCampus = [
  { path: '/campus/', slabel: '/today', label: 'Today', icon: "playlist_add_check" },
  { path: '/campus/', slabel: '/this_week', label: 'This week', icon: "location_on" },
  { path: '/campus/', slabel: '/this_month', label: 'This month', icon: "local_offer" },
  { path: '/campus/', slabel: '/this_term', label: 'This term', icon: "phone_iphone" },
  { path: '/campus/', slabel: '/this_year', label: 'This year', icon: "phone_iphone" },
  { path: '/campus/', slabel: '/custom', label: 'Custom', icon: "phone_iphone" },
];

export const miscellaneousPayment = {
  today: { key: 'today', text: 'Today' },
  this_week: { key: 'this_week', text: 'This week' },
  this_month: { key: 'this_month', text: 'This month' },
  this_term: { key: 'this_term', text: 'This term' },
  this_year: { key: 'this_year', text: 'This year' },
  custom: { key: 'custom', text: 'Custom' },
};


export const userRoles = [
  { key: 1, text: "Administrator" },
  { key: 2, text: "Testing" },
  // { key: 3, text: "Cashier" },
  { key: 4, text: "DAA" },
  { key: 5, text: "Class Manager" },
  { key: 6, text: "Registrar" },
  { key: 7, text: "Faculty Manager" },
  { key: 8, text: "Scholarship" },
  { key: 9, text: "Human Resource" },
  { key: 10, text: "Financial" },
  { key: 11, text: "Payroll" },
  { key: 12, text: "English Program" },
  { key: 13, text: "Reporting Server" },
];

export const userRolesObj = {
  administrator: { key: 1, text: "Administrator" },
  testing: { key: 2, text: "Testing" },
  cashier: { key: 3, text: "Cashier" },
  DAA: { key: 4, text: "DAA" },
  enrollment: { key: 5, text: "Class Manager" },
  registrar: { key: 6, text: "Registrar" },
  faculty_manager: { key: 7, text: "Faculty Manager" },
  scholarship: { key: 8, text: "Scholarship" },
  human_resource: { key: 9, text: "Human Resource" },
  financial: { key: 10, text: "Financial" },
  payroll: { key: 11, text: "Payroll" },
  englishProgram: { key: 12, text: "English Program" },
  reportingServer: { key: 13, text: "Reporting Server" },
};


export const studentTypes = {
  pucStudent: { key: 1, text: "Student" },
  assign: { key: 2, text: "Assign to Academic Student" },
  transferStudent: { key: 3, text: "Transfer Student" }
};

export const admissionType = {
  psis: { key: 1, text: 'PSIS', },
  institute: { key: 2, text: 'Institute' },
  academic: { key: 3, text: 'Academic' },
}

export const academicProgramObj = {
  associate: { key: 'associate', routerId: 'associate', active: true, icon: 'supervised_user_circle', name: 'Associate', order: 0, text: 'Associate', level: 'Undergraduate', type: 'ACADEMIC' },
  bachelor: { key: 'bachelor', routerId: 'bachelor', active: true, icon: 'supervised_user_circle', name: 'Bachelor', order: 1, text: 'Bachelor', level: 'Undergraduate', type: 'ACADEMIC' },
  pre_master: { key: 'pre_master', routerId: 'pre_master', active: true, icon: 'supervised_user_circle', name: 'Pre Master', order: 2, text: 'Pre Master', level: 'Pre Master', type: 'ACADEMIC' },
  master: { key: 'master', routerId: 'master', active: true, icon: 'supervised_user_circle', name: 'Master', order: 3, text: 'Master', level: 'Graduate', type: 'ACADEMIC' },
  postgraduate: { key: 'postgraduate', routerId: 'postgraduate', active: true, icon: 'supervised_user_circle', name: 'Doctor or PhD', order: 4, text: 'Doctor or PhD', level: 'Graduate', type: 'ACADEMIC' },
}

export const DISCOUNT_TYPES = [
  { key: 1, text: 'Percentage' },
  { key: 2, text: 'Cash' },
]

export const PROGRAM_OPTION = [
  { key: 1, text: 'Year Base' },
  { key: 2, text: 'Term Base' },
]

export const PROGRAM_TERMS = [
  { key: 1, text: 'Term 1' },
  { key: 2, text: 'Term 2' },
  { key: 3, text: 'Term 3' },
  { key: 4, text: 'Term 4' },
]

export const PROGRAM_TERMS_OBJ = {
  term1: { key: 1, text: 'Term 1' },
  term2: { key: 2, text: 'Term 2' },
  term3: { key: 3, text: 'Term 3' },
  term4: { key: 4, text: 'Term 4' },
}

export const StatusObj = {
  DISABLED: { key: 0, text: "Disabled" },
  ACTIVE: { key: 1, text: "Active" },
};

export const instructorFilterBy = [
  { key: "full_name", text: "Name" },
  { key: "code", text: "Code" },
];

export const scheduleTypes = {
  accelerate: { key: 0, text: "Accelerate Course" },
  abc: { key: 1, text: "ABC Course" },
  freshman: { key: 2, text: "Freshman Batch Schedules" },
  academic: { key: 3, text: "Academic Schedules" },
  shortCourse: { key: 4, text: "Short Course Schedules" },
  other: { key: 5, text: "Other Course Schedules" }
};


export const PARENTS_STATUS = [
  { key: 1, text: 'Living Together' },
  { key: 2, text: 'Living Apart' },
  { key: 3, text: 'Divorce' },
  { key: 4, text: 'Widow / Widower' },
];


export const STUDENT_STAY_WITH = [
  { key: 1, text: 'Parents' },
  { key: 2, text: 'Mother' },
  { key: 3, text: 'Father' },
  { key: 4, text: 'Others' },
];
export const GEP_TESTING_TYPES = {
  MCQ: { key: 1, name: 'MCQ' },
  READING: { key: 2, name: 'Reading' },
  WRITING: { key: 3, name: 'Writing' },
}

export const EXAM_STATUS = [
  { key: 1, text: "Inactive" },
  { key: 2, text: "Active" },
  { key: 3, text: "Start" },
];
export const EXAM_STATUS_OBJ = {
  inactive: { key: 1, text: "Inactive" },
  active: { key: 2, text: "Active" },
  start: { key: 3, text: "Start" },
};
export const Gender = [
  { key: 1, text: 'Male' },
  { key: 2, text: 'Female' },
  { key: 3, text: 'Monk' },
];

export const GenderObj = {
  male: { key: 1, text: 'Male' },
  female: { key: 2, text: 'Female' },
  monk: { key: 3, text: 'Monk' },
};


export const CAN_STATUS = [
  { key: 1, text: 'Yes' },
  { key: 2, text: 'No' },
];

export const SUBJECT_DATA = {
  khmer: { key: 1, name: 'ភាសាខ្មែរ', enName: 'Khmer' },
  math: { key: 2, name: 'គណិតវិទ្យា', enName: 'Math' },
  english: { key: 3, name: "ភាសាអង់គ្លេស", enName: "English" }
}

export const khChar = [
  { key: 1, text: 'ក' },
  { key: 2, text: 'ខ' },
  { key: 3, text: 'គ' },
  { key: 4, text: 'ឃ' },
  { key: 5, text: 'ង' },
  { key: 6, text: 'ច' },
  { key: 7, text: 'ឆ' },
  { key: 8, text: 'ជ' },
  { key: 9, text: 'ឈ' },
  { key: 10, text: 'ញ' },
  { key: 11, text: 'ដ' },
  { key: 12, text: 'ឋ' },
  { key: 13, text: 'ឌ' },
  { key: 14, text: 'ឍ' },
  { key: 15, text: 'ណ' },
  { key: 16, text: 'ត' },
  { key: 17, text: 'ថ' },
  { key: 18, text: 'ទ' },
  { key: 19, text: 'ធ' },
  { key: 20, text: 'ន' },
  { key: 21, text: 'ប' },
  { key: 22, text: 'ផ' },
  { key: 23, text: 'ព' },
  { key: 24, text: 'ភ' },
  { key: 25, text: 'ម' },
  { key: 26, text: 'យ' },
  { key: 27, text: 'រ' },
  { key: 28, text: 'ល' },
  { key: 29, text: 'វ' },
  { key: 30, text: 'ស' },
  { key: 31, text: 'ហ' },
  { key: 32, text: 'ឡ' },
  { key: 33, text: 'អ' },
]
export const romanChar = [
  { key: 1, text: 'I' },
  { key: 2, text: 'II' },
  { key: 3, text: 'III' },
  { key: 4, text: 'IV' },
  { key: 5, text: 'V' },
  { key: 6, text: 'VI' },
  { key: 7, text: 'VII' },
  { key: 8, text: 'VIII' },
  { key: 9, text: 'IX' },
  { key: 10, text: 'X' },
  { key: 11, text: 'XI' },
  { key: 12, text: 'XII' },
  { key: 13, text: 'XIII' },
  { key: 14, text: 'XIV' },
  { key: 15, text: 'XV' },
  { key: 16, text: 'XVI' },
  { key: 17, text: 'XVII' },
  { key: 18, text: 'XVIII' },
  { key: 19, text: 'XIX' },
  { key: 20, text: 'XX' },
  { key: 21, text: 'XXI' },
  { key: 22, text: 'XXII' },
  { key: 23, text: 'XXIII' },
  { key: 24, text: 'XXIV' },
  { key: 25, text: 'XXV' },
  { key: 26, text: 'XXVI' },
  { key: 27, text: 'XXVII' },
  { key: 28, text: 'XXVIII' },
  { key: 29, text: 'XXIX' },
  { key: 30, text: 'XXX' },
]

export const ACCOUNT_TRANSACTION_TYPES_OBJ = {
  'DEBIT': { key: 1, text: 'Debit' },
  'CREDIT': { key: 2, text: 'Credit' },
}

export const ACCOUNT_TRANSACTION_TYPES_LIST = [
  { key: 1, text: 'Debit' },
  { key: 2, text: 'Credit' },
]

export const ACCOUNT_LINE_TYPE_OBJ = {
  'JOURNAL_ENTRY': { key: 1, text: 'Journal Entry' },
}

export const DISCOUNT_POLICY_STUDENT = [
  { key: 1, text: 'All' },
  { key: 2, text: 'Old' },
  { key: 3, text: 'New' },
]

export const DISCOUNT_POLICY_STUDENT_OBJ = {
  all: { key: 1, text: 'All' },
  old: { key: 2, text: 'Old' },
  new: { key: 3, text: 'New' },
}


export const MODULE_FUNCTIONS = [
  { key: 'add', name: 'Add' },
  { key: 'edit', name: 'Edit' },
  { key: 'delete', name: 'Delete' },

]

export const MODULE_FUNCTIONS_OBJ = {
  add: { key: 'add', name: 'Add' },
  edit: { key: 'edit', name: 'Edit' },
  delete: { key: 'delete', name: 'Delete' },

}


export const HR_FUNCTION = {
  policy_remark: {
    add: "add_policy_remark",
    edit: "edit_policy_remark",
    delete: "delete_policy_remark",
  },
  shift: {
    add: "add_shift",
    edit: "edit_shift",
    delete: "delete_shift",
  },
  policy: {
    add: "add_policy",
    edit: "edit_policy",
    delete: "delete_policy",
  },
  //Resource
  add_employee: { key: "add_employee", name: "Add Employee" },
  edit_employee: { name: "Edit Employee", key: "edit_employee" },
  resign_employee: { name: "Resign Employee", key: "resign_employee" },
  terminate_employee: { name: "Terminate Employee", key: "terminate_employee" },
  add_contract: { name: "Add Contract", key: "add_contract" },
  edit_contract: { name: "Edit Contract", key: "edit_contract" },
  delete_contract: { name: "Delete Contract", key: "delete_contract" },
  add_salary: { name: "Add Salary", key: "add_salary" },
  edit_salary: { name: "Edit Salary", key: "edit_salary" },
  delete_salary: { key: "delete_salary", name: "Delete Salary" },
  add_contact: { name: "Add Contact", key: "add_contact" },
  edit_contact: { name: "Edit Contact", key: "edit_contact" },
  delete_contact: { key: "delete_contact", name: "Delete Contact" },
  add_education: { key: "add_education", name: "Add Education" },
  edit_education: { key: "edit_education", name: "Edit Education" },
  delete_education: { key: "delete_education", name: "Delete Education" },
  add_experience: { name: "Add Experience", key: "add_experience" },
  edit_experience: { key: "edit_experience", name: "Edit Experience" },
  delete_experience: { name: "Delete Experience", key: "delete_experience" },

  //cashier
  add_cashier: { key: "add_cashier", name: "Add Cashier" },
  edit_cashier: { name: "Edit Cashier", key: "edit_cashier" },
  reset_cashier_password: { key: "reset_cashier_password", name: "Reset Cashier Password" },

  //user
  add_user: { key: "add_user", name: "Add User" },
  edit_user: { name: "Edit User", key: "edit_user" },
  delete_user: { key: "delete_user", name: "Delete User" },
  reset_user_password: { name: "Reset User Password", key: "reset_user_password" },

  //Group Acc
  group: {
    add: "add_group",
    edit: "edit_group",
    delete: "delete_group",
    add_member: "add_group_member",
    remove_member: "remove_group_member",

  },
  app_module: {
    add: "add_module",
    edit: "edit_module",
    delete: "delete_module",
    add_function: "add_function",
    edit_function: "edit_function",
    delete_function: "delete_function",
  },
  //App Module


  //Company Profile
  edit_company: { key: "edit_company", name: "Edit Company" },

  //Building

  add_building: { name: "Add Building", key: "add_building" },
  edit_building: { key: "edit_building", name: "Edit Building" },
  delet_building: { name: "Delet Building", key: "delet_building" },
  add_campus: { key: "add_campus", name: "Add Campus" },
  edit_campus: { name: "Edit Campus", key: "edit_campus" },
  delete_campus: { name: "Delete Campus", key: "delete_campus" },
  add_room: { key: "add_room", name: "Add Room" },
  edit_room: { name: "Edit Room", key: "edit_room" },
  delete_room: { name: "Delete Room", key: "delete_room" },
  //Department
  add_department: { key: "add_department", name: "Add Department" },
  edit_department: { name: "Edit Department", key: "edit_department" },
  delete_department: { key: "delete_department", name: "Delete Department" },

  //Holiday
  add_holiday: { name: "Add Holiday", key: "add_holiday" },
  edit_holiday: { name: "Edit Holiday", key: "edit_holiday" },
  delete_holiday: { key: "delete_holiday", name: "Delete Holiday" },
  //Position
  add_position: { key: "add_position", name: "Add Position" },
  edit_position: { key: "edit_position", name: "Edit Position" },
  delete_position: { name: "Delete Position", key: "delete_position" },
  //Title
  add_title: { name: "Add Title", key: "add_title" },
  edit_title: { name: "Edit Title", key: "edit_title" },
  delete_title: { key: "delete_title", name: "Delete Title" },
  //report
  print_employee_listing: { key: "print_employee_listing", name: "Print" },
  download_employee_listing: { name: "Download", key: "download_employee_listing" },
}
export const SCHOLARSHIP_FUNCTION = {
  //SCHOLARSHIP_MANAGER
  add_scholarship: { key: "add_scholarship", name: "Add Scholarship" },
  edit_scholarship: { name: "Edit Scholarship", key: "edit_scholarship" },
  delete_scholarship: { key: "delete_scholarship", name: "Delete Scholarship" },
  edit_student_info: { key: "edit_student_info", name: "Edit Student Info" },
  edit_student_contact: { name: "Edit Student Contact", key: "edit_student_contact" },
  add_sibling: { key: "add_sibling", name: "Add Sibling" },
  delete_sibling: { key: "delete_sibling", name: "Delete Sibling" },

  //Discount Voucher
  add_discount_voucher: { name: "Add Discount Voucher", key: "add_discount_voucher" },
  edit_discount_voucher: { key: "edit_discount_voucher", name: "Edit Discount Voucher" },
  delete_discount_voucher: { name: "Delete Discount Voucher", key: "delete_discount_voucher" },

  //Payment Discount
  add_payment_discount: { name: "Add Payment Discount", key: "add_payment_discount" },
  edit_payment_discount: { name: "Edit Payment Discount", key: "edit_payment_discount" },
  delete_payment_discount: { key: "delete_payment_discount", name: "Delete Payment Discount" },

  //Scholarship Type
  add_scholarship_type: { key: "add_scholarship_type", name: "Add Scholarship Type" },
  edit_scholarship_type: { key: "edit_scholarship_type", name: "Edit Scholarship Type" },
  delete_scholarship_type: { key: "delete_scholarship_type", name: "Delete Scholarship Type" },

  //Scholarship Approval
  add_scholarship_approval: { name: "Add Scholarship Approval", key: "add_scholarship_approval" },
  edit_scholarship_approval: { name: "Edit Scholarship Approval", key: "edit_scholarship_approval" },
  delete_scholarship_approval: { name: "Delete Scholarship Approval", key: "delete_scholarship_approval" },

  //Report
  print_scholarship_listing: { key: "print_scholarship_listing", name: "Print Scholarship Listing" }
}
export const TESTING_FUNCTION = {
  //Analytics
  dashboard: { excel: 'export_testing_lists' },
  // export_testing_lists: { key: "export_testing_lists", name: "Export Testing Lists" },
  //Programs
  programs: {
    take_exam: 'test_take_exam', check_exam: 'test_check_exam', interview: 'test_interview',
    edit_student: 'test_edit_student_profile', admission: 'test_add_student_admission',
    reset_test: 'test_reset_test', edit_contact: 'test_edit_student_contact',
    edit_education: 'test_edit_ecudation', add_sibling: 'test_add_sibling', delete_sibling: 'test_delete_sibling'
  },

  exam_preparation: {
    add: 'add_exam_preparation', edit: 'edit_exam_preparation', delete: 'delete_exam_preparation',
    add_exercise: 'test_add_exercise', edit_exercise: 'test_edit_exercise', delete_exercise: 'test_delete_exercise',
    delete_question: 'test_delete_question'
  },

  examination: { add: 'add_exam_schedule', edit: 'edit_exam_schedule', delete: 'delete_exam_schedule', start: 'start_exam' },

  add_exam_schedule: { name: "Add Exam Schedule", key: "add_exam_schedule" },
  edit_exam_schedule: { key: "edit_exam_schedule", name: "Edit Exam Schedule" },
  delete_exam_schedule: { name: "Delete Exam Schedule", key: "delete_exam_schedule" },
  start_exam: { key: "start_exam", name: "Start Exam" },
  //

  english_test: {
    add: 'add_general_placement_test', edit: 'edit_add_general_placement_test', delete: 'delete_add_general_placement_test',
    add_question: 'add_general_placement_test_question', edit_question: 'edit_add_general_placement_test_question',
    delete_question: 'delete_add_general_placement_test_question',
    add_answer: 'add_general_placement_test_answer', edit_answer: 'edit_add_general_placement_test_answer',
    delete_answer: 'delete_add_general_placement_test_answer'
  },

  khmer_test: {
    add: 'add_khmer_placement_test', edit: 'edit_khmer_placement_test', delete: 'delete_khmer_placement_test',
    add_question: 'add_khmer_placement_test_question', edit_question: 'edit_khmer_placement_test_question', delete_question: 'delete_khmer_placement_test_question',
    add_answer: 'add__khmer_placement_test_answer', edit_answer: 'edit_khmer_placement_test_answer', delete_answer: 'delete_khmer_placement_test_answer'
  },

  //Placement Test
  //Reports

}
export const INVENTORY_FUNCTION = {
  bills: { add: 'add_bill', edit: 'edit_bill', delete: 'delete_bill' },
  suppliers: { add: 'add_supplier', edit: 'edit_supplier', delete: 'delete_supplier' },
  inventory_center: { consolidated: 'consolidated_stocks' },
  inventory_transfer: { add: 'add_inventory_transfer', edit: 'edit_inventory_transfer', delete: 'delete_inventory_transfer' },
  inventory_adjustment: { add: 'add_inventory_adjustment', edit: 'edit_inventory_adjustment', delete: 'delete_inventory_adjustment' },
  receive_transfer: {},
  product: { add: 'add_product', edit: 'edit_product', delete: 'delete_product' },
  category: { add: 'add_category', edit: 'edit_category', delete: 'delete_category' },

  unit_measurement: { add: 'add_unit_measurement', edit: 'edit_unit_measurement', delete: 'delete_unit_measurement' },
  payment_term: { add: 'add_payment_term', edit: 'edit_payment_term', delete: 'delete_payment_term' },
}

export const REGISTRAR_FUNCTION = {
  add_grade_level: { name: "Add Grade Level", key: "add_grade_level" },
  edit_grade_level: { name: "Edit Grade Level", key: "edit_grade_level" },
  delete__grade_level: { name: "Delete  Grade Level", key: "delete__grade_level" },
  add_grade: { key: "add_grade", name: "Add Annual Grade" },
  edit_grade: { name: "Edit Annual Grade", key: "edit_grade" },
  delete_grade: { name: "Delete Annual Grade", key: "delete_grade" }

}
export const ANALYTICS_FUNCTION = {
  print_statistic_listing: { key: "print_statistic_listing", name: "Print " },
  download_statistic_listing: { key: "download_statistic_listing", name: "Download" },
  print_general_statistic: { name: "Print", key: "print_general_statistic" },
  download_general_statistic: { key: "download_general_statistic", name: "Download" },
  print_byinstructor_statistic: { key: "print_byinstructor_statistic", name: "Print" },
  download_byinstructor_statistic: { name: "Download", key: "download_byinstructor_statistic" },
}
export const FINANCE_FUNCTION = {
  home: {
    campus: 'finance_campus', online_cashier: 'finance_online_cashier', receive_payment: 'finance_received_payment',
    e_payment: 'finance_e-payments', wind_payment: 'finance_2.2_e-payment_-_wing', aba_payment: 'finance_e-payment_-_aba',
    acleda_payment: 'finance_2.3_e-payment_-_acleda', cashier_report: 'finance_cashier_report', daily_campus_listing: 'finance_3.1_cashier_-_daily_campus_listing',
    daily_shift_listing: 'finance_3._cashier_-_daily_campus_listing', expense_listing: 'finance_3.2_cashier_-_expense_listing',
    finance_report: 'finance_report', operating_class: 'finance_6.1_reporting_-_operating_class_listing',
    instructor_schedule: 'finance_6.2_reporting_-_instructor_schedule_listing', campus_print: 'campus_print', online_cashier_print: 'online_cashier_print',
    confirm_payment: 'confirm_payment', received_payment_print: 'received_payment_print', wing_print: 'wing_print', wing_download: 'wing_download',
    acleda_print: 'acleda_print', acleda_download: 'acleda_download', aba_print: 'aba_print', aba_download: 'aba_download'
  },
  cashier_report: {
    print_daily_campus: 'print_daily_campus', download_daily_campus: 'download_daily_campus',
    print_expense: "print_expense",
    download_expense: "download_expense",
    print_match_term: "print_match_term",
    download_match_term: "download_match_term",
    print_request_penalty: "print_request_penalty",
    download_request_penalty: "download_request_penalty",
    print_request_void: "print_request_void",
    download_request_void: "download_request_void",
    print_daily_shift: "print_daily_shift",
    download_daily_shift: "download_daily_shift"
  },
  report: {
    print_operating_class: "print_operating_class",
    download_operating_class: "download_operating_class",
    print_instructor_schedule: "print_instructor_schedule",
    download_instructor_schedule: "download_instructor_schedule",
    print_testing_student: "print_testing_student",
    download_testing_student: "download_testing_student",
    print_new_student: "print_new_student",
    download_new_student: "download_new_student",
    print_old_student: "print_old_student",
    download_old_student: "download_old_student",
    print_paid_student: "print_paid_student",
    download_paid_student: "download_paid_student",
    print_unpaid_student: "print_unpaid_student",
    download_unpaid_student: "download_unpaid_student",

  }
}

export const ENROLLMENT_MODULE_OBJ = {
  develop: "enrollment_1._develop",
  profile: "enrollment_1.1_student_profile",
  freshmen: "enrollment_1.2_freshmen",
  new_registration: "new_registration",
  enrollment: "enrollment_1.3_enrollment",
  batch_enrollment: "enrollment_1.4_batch_enrollment",
  batch_schedules: "enrollment_1.2_batch_schedules",
  administrator: "enrollment_2._administrator",
  training_program: "enrollment_2.1_training_program",
  school_fee: "enrollment_2.2_school_fee",
  fee_category: "enrollment_2.3_fee_category",
  curricular: "enrollment_2.4_curricular",
  subjects: "enrollment_2.5_subjects",
  levels: "enrollment_2.6_levels",
  programs: "enrollment_2.7_programs",
  education_levels: "enrollment_2.8_education_levels",
  payment_option: "enrollment_2.9_payment_option",
  account: "enrollment_3._account_&_data",
  lecture_account: "enrollment_3.1_lecture_account",
  student_account: "enrollment_3.2_student_account",
  parent_account: "enrollment_3.3_parent_account",
  shift: "enrollment_3.4_shift",
  sessions: "enrollment_3.5_sessions",
  Instructors: "enrollment_3.6_instructors",
  academic: "class_manager_2_academic_year",
}

export const ENROLLMENT_FUNCTION = {
  //BATCH SCHEDULE
  new_registration: { key: "new_registration", name: "New Registration" },
  add_batch: { name: "Add Batch", key: "add_batch" },
  edit_batch: { name: "Edit Batch", key: "edit_batch" },
  delete_batch: { name: "Delete Batch", key: "delete_batch" },
  add_student: { name: "Add Student", key: "add_student" },
  remove_student: { key: "remove_student", name: "Remove Student" },
  offer_new_course: { key: "offer_new_course", name: "Offer New Course" },
  edit_course: { name: "Edit Course", key: "edit_course" },
  delete_course: { name: "Delete Course", key: "delete_course" },
  export_batch_student: { name: "Export Batch Student", key: "export_batch_student" },

  add_training_program: { key: "add_training_program", name: "Add Training Program" },
  add_training_level: { name: "Add Training Level", key: "add_training_level" },
  edit_training_level: { key: "edit_training_level", name: "Edit Training Level" },
  delete_training_level: { key: "delete_training_level", name: "Delete Training Level" },
  training_level_fee: { key: "training_level_fee", name: "Training Level Fee" },
  remove_fee: { name: "Remove Fee", key: "remove_fee" },
  add_fee_category: { name: "Add Fee Category", key: "add_fee_category" },
  edit_fee_category: { key: "edit_fee_category", name: "Edit Fee Category" },
  delete_fee_category: { name: "Delete Fee Category", key: "delete_fee_category" },
  add_curricular: { key: "add_curricular", name: "Add Curricular" },
  edit_curricular: { name: "Edit Curricular", key: "edit_curricular" },
  delete_curricular: { key: "delete_curricular", name: "Delete Curricular" },
  add_subject: { name: "Add Subject", key: "add_subject" },
  edit_subject: { name: "Edit Subject", key: "edit_subject" },
  delete_subject: { name: "Delete Subject", key: "delete_subject" },
  add_level: { key: "add_level", name: "Add Level" },
  edit_level: { key: "edit_level", name: "Edit Level" },
  delete_level: { name: "Delete Level", key: "delete_level" },
  add_payment_option: { key: "add_payment_option", name: "Add Payment Option" },
  edit_payment_option: { name: "Edit Payment Option", key: "edit_payment_option" },
  delete_payment_option: { key: "delete_payment_option", name: "Delete Payment Option" },
  add_education_level: { key: "add_education_level", name: "Add Education Level" },
  edit_education_level: { key: "edit_education_level", name: "Edit Education Level" },
  delete_education_level: { name: "Delete Education Level", key: "delete_education_level" },

  add_program: { key: "add_program", name: "Add Program" },
  edit_program: { name: "Edit Program", key: "edit_program" },
  delete_program: { name: "Delete Program", key: "delete_program" },

  reset_lecture_account_password: { key: "reset_lecture_account_password", name: "Reset Lecture Account Password" },
  reset_student_account_password: { key: "reset_student_account_password", name: "Reset Student Account Password" },
  add_parent: { key: "add_parent", name: "Add Parent" },
  edit_parent: { key: "edit_parent", name: "Edit Parent" },
  delete_parent: { name: "Delete Parent", key: "delete_parent" },
  add_child: { name: "Add Child", key: "add_child" },
  remove_child: { name: "Remove Child", key: "remove_child" },
  add_shift: { name: "Add Shift", key: "add_shift" },
  edit_shift: { name: "Edit Shift", key: "edit_shift" },
  delete_shift: { name: "Delete Shift", key: "delete_shift" },

  add_session: { name: "Add Session", key: "add_session" },
  edit_session: { key: "edit_session", name: "Edit Session" },
  delete_session: { name: "Delete Session", key: "delete_session" },
  add_instructor: { name: "Add Instructor", key: "add_instructor" },
  edit_instructor: { name: "Edit Instructor", key: "edit_instructor" },
  delete_instructor: { name: "Delete Instructor", key: "delete_instructor" },

  academic_year: { add: "add_academic_year", edit: "edit_academic_year", active: "set_active_academic_year" }
}


export const ADMIN_FUNCTION = {

  //App Center
  announcement: { edit: "edit_announcement", add: "add_announcement", delete: "delete_announcement" },
  event: { edit: "edit_event", delete: "delete_event", add: "add_event" },
  school_program: { edit: "edit_add_school_program", delete: "delete_add_school_program", add: "add_school_program" },
  partner: { edit: "edit_partner", delete: "delete_partner", add: "add_partner" },
  management_team: { edit: "edit_management_team", delete: "delete_management_team", add: "add_management_team" },
  news_video: { edit: "edit_add_news_video", delete: "delete_add_news_video", add: "add_news_video" },
  news: { edit: "edit_news", delete: "delete_news", add: "add_news" },
  video_lecture: { edit: "edit_video", delete: "delete_video", add: "add_video" },
  video_level: { edit: "edit_grade", delete: "delete_grade", add: "add_grade" },
  video_subject: { edit: "edit_subject", delete: "delete_subject", add: "add_subject" },
  video_teacher: { edit: "edit_teacher", delete: "delete_teacher", add: "add_teacher" },
  video_lesson: { edit: "edit_lesson", delete: "delete_lesson", add: "add_lesson" },
  // video: { lesson: 'lesson', video: 'video', grade: 'grade', subject: 'subject', teacher: 'teacher' }

  //Library
  inventory: { edit: "edit_inventory", delete: "delete_inventory", add: "add_inventory" },
  confirm_booking: { edit: "edit_confirm_booking", delete: "delete_confirm_booking", add: "add_confirm_booking" },
  book: { edit: "edit_book", delete: "delete_book", add: "add_book" },
  device: { edit: "edit_device", delete: "delete_device", add: "add_device" },
  section: { edit: "edit_book_section", delete: "delete_book_section", add: "add_book_section" },
  genre: { edit: "edit_book_genre", delete: "delete_book_genre", add: "add_book_genre" },
  bookcase: { edit: "edit_bookcase", delete: "delete_bookcase", add: "add_bookcase" },
  printing_places: { edit: "edit_printing_place", delete: "delete_printing_place", add: "add_printing_place" },
  bookcase_type: { edit: "edit_bookcase_type", delete: "delete_bookcase_type", add: "add_bookcase_type" },
  author: { edit: "edit_book_author", delete: "delete_book_author", add: "add_book_author" },
  publisher: { edit: "edit_book_publisher", delete: "delete_book_publisher", add: "add_book_publisher" },
  slide: { edit: "edit_slide", delete: "delete_slide", add: "add_slide" },
  card: { edit: "edit_library_card", delete: "delete_library_card", add: "add_library_card" },
  basic_textbooks: { edit: "edit_basic_textbooks", delete: "delete_basic_textbooks", add: "add_basic_textbooks" },
  reservation_session: { edit: "edit_reservation_session", delete: "delete_reservation_session", add: "add_reservation_session" },

  //Hospital services
  type_of_disease: { edit: "edit_type_of_disease", delete: "delete_type_of_disease", add: "add_type_of_disease" },
  type_of_medicine: { edit: "edit_type_of_medicine", delete: "delete_type_of_medicine", add: "add_type_of_medicine" }

}

