export const NUBB_SCHOOL = {
  key: 'national_university_of_battambang',
  name: "National University of Battambang",
  khName: "សាកលវិទ្យាល័យជាតិបាត់ដំបង",
  email: "info@nubb.edu.kh",
  website: "http://nubb.edu.kh/",
  img: "/assets/images/nubb.png",
  maxID: 7,
  examByCampus: true,
  isNGS: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const BANAN_SCHOOL = {
  key: 'banan_school',
  name: "Banan School",
  khName: "Banan School",
  email: "banan@gmail.com",
  website: "",
  img: "/assets/images/auxswot.jpeg",
  imgFull: "/assets/images/auxswot.jpeg",
  maxID: 7,
  examByCampus: false,
  isNGS: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const SCHOOLLINK_SCHOOL = {
  key: 'school_link',
  name: "School Link Company",
  khName: "ក្រុមហ៊ុន ស្គូលលីង",
  email: "Info@schoollink.edu.kh",
  website: "www.schoollink.edu.kh",
  img: "/assets/images/schoollink_S.png",
  maxID: 7,
  examByCampus: false,
  isNGS: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    person: 'ជំនួយការរដ្ឋបាល',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const PAMA_SCHOOL = {
  key: 'pama_school',
  name: "PAMA International School",
  khName: "សាលាប៉ាម៉ាអន្តរជាតិ",
  email: "info@pamainternationalschool.com",
  website: "https://pamainternationalschool.com",
  img: "/assets/images/pama.png",
  maxID: 9,
  examByCampus: false,
  isNGS: false,
  isShowCampus: true,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    person: 'ជំនួយការរដ្ឋបាល',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  isGradingColor: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const EWIS_SCHOOL = {
  key: 'ewis_school',
  name: "East-West International School",
  khName: "សាលាអន្តរជាតិ អុិសវ៉េស",
  email: "Info@ewiscambodia.edu.kh",
  website: "www.ewiscambodia.edu.kh",
  img: "/assets/images/ewis.png",
  maxID: 7,
  examByCampus: false,
  isNGS: false,
  isShowCampus: true,
  WesterNameOrder: true,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isGradingColor: true,
  isRoundUpGradeSheet: false,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: ''
  },
  disableUnnessaryMenus: true,
  isGradeSheetRoundUpDisPlayOnly: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const SPK_SCHOOL = {
  key: 'sovannaphumi_kids',
  name: "Sovannaphumi Kids",
  khName: "កុមារសុវណ្ណភូមិ",
  email: "info.spk@sovannaphumi.edu.kh",
  website: "spk1.ntcgroup.com.kh/",
  img: "/assets/images/spk.png",
  maxID: 7,
  examByCampus: true,
  isNGS: false,
  isShowCampus: true,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    person: 'ជំនួយការរដ្ឋបាល',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const BVS_SCHOOL = {
  key: 'bvs_school',
  name: "Borey Vichear School",
  khName: "សាលា​ចំណេះទូទៅ បុរីវិជ្ជា",
  email: "Info@boreyvichearschool.edu.kh",
  website: "www.boreyvichearschool.edu.kh",
  img: "/assets/images/bvs.png",
  maxID: 7,
  examByCampus: false,
  isNGS: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    person: 'ជំនួយការរដ្ឋបាល',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const MANNER_SCHOOL = {
  key: 'manner_school',
  name: "Manner International School",
  khName: "សាលាអន្តរជាតិ មែនន័រ",
  email: "mannerinternationalschool.mis@gmail.com",
  website: "http://www.mannerinternationalschool.com/",
  img: "/assets/images/manner.png",
  imgFull: "/assets/images/manner_full.png",
  maxID: 7,
  examByCampus: false,
  isNGS: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  WesterNameOrder: false,
  isGradingColor: false,
  isRoundUpGradeSheet: false,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    person: 'ជំនួយការរដ្ឋបាល',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,

}
export const SPS_SCHOOL = {
  key: 'sovannaphumi_school',
  name: "Sovannaphumi School",
  khName: "សាលារៀនសុវណ្ណភូមិ",
  email: "Info@sovannaphumin.edu.kh",
  website: "www.sovannaphumin.edu.kh",
  img: "/assets/images/sps.png",
  examByCampus: true,
  maxID: 7,
  isNGS: false,
  isShowCampus: true,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'គ្រូបន្ទុកថ្នាក់',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const APIS_SCHOOL = {
  key: 'apis_school',
  name: "American Pacific International School",
  khName: "សាលាអន្តរជាតិអាមេរិកាំងប៉ាស៊ីហ្វិក",
  email: "Info@apis.edu.kh",
  website: "www.apiscambodia.edu.kh",
  img: "/assets/images/apis.png",
  maxID: 7,
  examByCampus: false,
  isNGS: false,
  isShowCampus: true,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: ''
  },
  isGradingColor: true,
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,

}
export const SSW_SCHOOL = {
  key: 'ngs',
  psis: 'ngs',
  name: "Preah Sisowath High School (New Generation School)",
  khName: "វិទ្យាល័យព្រះស៊ីសុវត្ថិ សាសារៀនជំនាន់ថ្មី",
  email: "Info@preahsisowath.edu.kh",
  website: "www.preahsisowath.edu.kh",
  img: "/assets/images/ngs.png",
  shortName: "NGS Preah Sisowath",
  examByCampus: false,
  maxID: 7,
  isNGS: true,
  isShowCampus: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: ''
  },
  listStudent: 'ឃឹម មករា',
  isGradingColor: true,
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  parentAccUseCode: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const PREKLEAP_SCHOOL = {
  key: 'ngs-preakleap',
  psis: 'ngs-preakleap',
  name: "Prek Leap High School (New Generation School)",
  khName: "សាលារៀនជំនាន់ថ្មី វិទ្យាល័យព្រែកលៀប",
  // attendanceSystem: 'https://ngsprekleapadmin.auxswot.com',
  // classroomSystem: 'https://ngsprekleapeclass.auxswot.com',
  // email: "prekleaphighschool@gmail.com",
  // website: "https://www.facebook.com/prekleaphighschool",
  img: "/assets/images/logo_ngs_prekleap.png",
  sgImg: "/assets/images/IMAGE 2022-11-18 14_23_39.png",
  examByCampus: false,
  maxID: 7,
  isNGS: true,
  isShowCampus: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isHaveConvertGrading: false,
  gradeReport: {
    province: 'រាជធានីភ្នំពេញ',
    place: 'វិ. ព្រែកលៀប',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: '',
    insteadSP: 'នាយកវិទ្យាល័យ',
    deputySP: ''
  },
  listStudent: 'ឃឹម មករា',
  isGradingColor: true,
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: true,
  parentAccUseCode: true,
  studentAOver: 30,
  studentApOver: 60,
  studentAApOver: 40,
}
export const PREKANCHANH_SCHOOL = {
  key: 'ngs-prek-anchanh',
  psis: 'ngs-prek-anchanh',

  name: "Chea Sim Prek Anhchanh High School (New Generation School)",
  khName: "វិទ្យាល័យ សម្តេចអគ្គមហាធម្មពោធិសាល ជាស៊ីម ព្រែកអញ្ចាញ សាលារៀនជំនាន់ថ្មី",

  fName: 'Chea Sim Prek Anhchanh High School',
  sName: 'New Generation School',
  attendanceSystem: 'https://ngsprekanchanhadmin.auxswot.com',
  classroomSystem: 'https://ngsprekanchanheclass.auxswot.com',
  email: "",
  website: "https://www.facebook.com/វិទ្យាល័យ-សម្តេចអគ្គមហាធម្មពោធិសាល-ជាស៊ីម-ព្រែកអញ្ចាញ-សាលារៀនជំនាន់ថ្មី-254362375041580/",
  img: "/assets/images/ngs_prek_anchanh.png",
  examByCampus: true,
  maxID: 7,
  isNGS: true,
  isShowCampus: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isGradingColor: false,
  isHaveConvertGrading: false,
  isHaveImportGradeByBatch: true,
  gradeReport: {
    place: 'វិ. ជាស៊ីម ព្រែកអញ្ចាញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: '',
    insteadSP: 'ជ.នាយកវិទ្យាល័យ',
    deputySP: 'នាយករងទទួលបន្ទុកសាលារៀនជំនាន់ថ្មី'
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,

}
export const KAMPONGCHAM_SCHOOL = {
  key: 'ngs_kampong_cham',
  psis: 'ngs_kampong_cham',
  name: "Hun Sen Kampong Cham High School (New Generation School)",
  khName: "វិទ្យាល័យ ហ៊ុន សែន កំពង់ចាម សាលារៀនជំនាន់ថ្មី",
  shortName: "NGSKPC",
  email: "",
  website: "",
  attendanceSystem: 'https://ngskampongchamadmin.auxswot.com',
  classroomSystem: 'https://ngskampongchameclass.auxswot.com',
  img: "/assets/images/ngs_kampong_cham.png",
  examByCampus: true,
  maxID: 7,
  isNGS: true,
  isShowCampus: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡាខេត្តកំពង់ចាម',
  isRoundUpGradeSheet: true,
  isGradingColor: false,
  teacherAccountUsePhone: true,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'វិ.ហស កំពង់ចាម',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: '',
    insteadSP: 'ជ.នាយកវិទ្យាល័យ',
    deputySP: 'នាយករងទទួលបន្ទុកសាលារៀនជំនាន់ថ្មី',
    submitTo: 'ការិយាល័យអប់រំយុជន និងកីឡាក្រុងកំពង់ចាម',
    school: 'វិទ្យាល័យ ហ៊ុន សែន កំពង់ចាម (សាលារៀនជំនាន់ថ្មី)'
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: true,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const PEAMCHIKANG_SCHOOL = {
  key: 'ngs_peam_chikang',
  psis: 'psispl',

  name: "Hun Sen Peam Chikang High School (New Generation School)",
  khName: "វិទ្យាល័យ ហ៊ុន សែន ពាមជីកង សាលារៀនជំនាន់ថ្មី",
  shortName: "NGSPCK",
  email: "",
  website: "",
  attendanceSystem: 'https://ngspeamchikanadmin.auxswot.com',
  classroomSystem: 'https://ngspeamchikangeclass.auxswot.com',
  img: "/assets/images/ngs_peam_chikang.png",
  examByCampus: true,
  maxID: 7,
  isNGS: true,
  isShowCampus: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡាខេត្តកំពង់ចាម',
  isRoundUpGradeSheet: true,
  isGradingColor: false,
  isHaveConvertGrading: true,
  teacherAccountUsePhone: true,
  gradeReport: {
    submitTo: 'មន្ទីរ អយក ខេត្តកំពង់ចាម',
    school: 'វិ ហស ពាមជីកង',
    place: 'វិ.ហស ពាមជីកង',
    office: 'ជំនួយការរដ្ឋបាល',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយករង',
    phone: ''
  },
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: true,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,
}
export const SMS_SCHOOL = {
  key: 'ngs',
  psis: 'psispl',
  name: "Paññāsāstra International School",
  khName: "សាលាបញ្ញាសាស្ត្រអន្តរជាតិ",
  attendanceSystem: '',
  classroomSystem: '',
  email: "psisschool@gmail.com",
  website: "",
  img: "/assets/images/psis.png",
  sgImg: "/assets/images/IMAGE 2022-11-18 14_23_39.png",
  examByCampus: false,
  maxID: 7,
  isNGS: true,
  isShowCampus: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isHaveConvertGrading: false,
  gradeReport: {
    province: 'រាជធានីភ្នំពេញ',
    place: 'វិ. ព្រែកលៀប',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: '',
    insteadSP: 'នាយកវិទ្យាល័យ',
    deputySP: ''
  },
  isGradingColor: true,
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: true,
  parentAccUseCode: true,
  studentAOver: 30,
  studentApOver: 60,
  studentAApOver: 40,
}


export const YKT_SCHOOL = {
  key: 'ngs',
  psis: 'ngs',
  name: "Preah Yukunthor High School (New Generation School)",
  khName: "វិទ្យាល័យព្រះយុគន្ធរ សាលារៀនជំនាន់ថ្មី",
  email: "Info@preahyukunthor.edu.kh",
  website: "www.preahyukunthor.edu.kh",
  img: "/assets/images/ngs_ykt.png",
  sgImg: "/assets/images/ngs_ykt.png",
  shortName: "NGS Preah Yukunthor",
  examByCampus: false,
  maxID: 7,
  isNGS: true,
  isShowCampus: false,
  educationDepartment: 'មន្ទីរអប់រំ យុវជន និងកីឡារាជធានីភ្នំពេញ',
  isRoundUpGradeSheet: false,
  isHaveConvertGrading: false,
  gradeReport: {
    place: 'រាជធានីភ្នំពេញ',
    office: 'ការិយាល័យសិក្សា',
    person: 'ជំនួយការរដ្ឋបាល',
    trackBookApprove: 'នាយករង',
    trackBookApproveFemale: 'នាយិការង',
    phone: ''
  },
  listStudent: 'ឃឹម មករា',
  isGradingColor: true,
  isGradeSheetRoundUpDisPlayOnly: false,
  disableUnnessaryMenus: false,
  parentAccUseCode: false,
  studentAOver: 30,
  studentApOver: 55,
  studentAApOver: 40,

}
export const SCHOOL_CONFIG = SSW_SCHOOL;
