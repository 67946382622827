import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'calculateTime'
})
export class CalculateTimePipe implements PipeTransform {
    transform(startDate, endDate) {
        const start = startDate;
        const end = endDate;
        if(!end){
            return
        }
        const diffInMilliseconds = Math.abs(end.getTime() - start.getTime());
        const diffInSeconds = diffInMilliseconds / 1000;
        const diffInMinutes = diffInSeconds / 60;
        const hours = Math.floor(diffInMinutes / 60);
        const minutes = Math.floor(diffInMinutes % 60);
        const seconds = Math.floor(diffInSeconds % 60);
        return `${hours}h : ${minutes}mn : ${seconds}s`
      }
    
}