<aside class="sidebar">
  <div class="sidebar-brand" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
    <div class="sbar-brand-name">
      {{appName?.name}}
    </div>
  </div>
  <div class="sidebar-header">
    <div class="header-content">
      <div class="list-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
        <div class="icon">
          <i class="material-icons">home</i>
        </div>
        <div class="item-name">
          Overview
        </div>
      </div>
    </div>
  </div>
  <div class="sidebar-list-wrapper">
    <mat-accordion [multi]="true">
      <ng-container *ngFor="let item of menu?.routeItems;">

        <mat-expansion-panel [expanded]="expansions[item?.expansions]" (closed)="onClosed(item?.expansionIndex)"
          (opened)="onOpened(item?.expansionIndex)">
          <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px"
            [class.active]="expansions[item?.expansions]">
            <mat-panel-title>
              {{item?.name}}
            </mat-panel-title>
            <mat-panel-description *ngIf="!expansions[item?.expansions]">{{item?.remark}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="list-content">
            <div class="list-item" *ngFor="let row of item?.data">
              <a class="list-item-row" routerLinkActive="active"
                [routerLink]="['/' + menu?.key+'/' + item.key+'/' + row?.key]">
                <div class="icon">
                  <mat-icon>{{row?.icon}}</mat-icon>
                </div>
                <div class="item-name">
                  {{row?.name}}
                </div>
              </a>
            </div>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
  <div class="sidebar-footer">
    <div class="sidebar-toggle-button" (click)="onToggleSidebar()">
      <div class="arrow-icon"></div>
    </div>
  </div>
</aside>