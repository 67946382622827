import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Pipe({
  name: 'dateOnly'
})
export class DateOnlyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const date = new Date(value);
    return new DatePipe('en-US').transform(date, 'dd MMM, yyyy');
  }

}

@Pipe({
  name: 'khmerYearOnly'
})
export class KhmerYearPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const d = moment(value).locale('km').format('Y');
    return d
  }

}

@Pipe({
  name: 'khmerMonthOnly'
})
export class KhmerMonthPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const d = moment(value).locale('km').format('MMMM');
    return d
  }

}


@Pipe({
  name: 'khmerDayOnly'
})
export class KhmerDayPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const d = moment(value).locale('km').format('D');
    return d
  }

}


@Pipe({
  name: 'khmerDateOnly'
})
export class KhmerDatePipe implements PipeTransform {

  transform(value: any, type?: any): any {
    if (!value) return;
    let d = null;
    if (type === 'day') {
      d = moment(value).locale('km').format('D');
    }
    if (type === 'month') {
      d = moment(value).locale('km').format('MMMM');
    }
    if (type === 'year') {
      d = moment(value).locale('km').format('Y');
    }
    return d
  }

}
@Pipe({
  name: 'eraBE'
})
export class EraBEPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const d = moment(value).format('y');
    const val = moment(544 + Number(d), "YYYY").locale("km").format("YYYY")
    return val
  }

}