import { Pipe, PipeTransform } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexXAxis } from 'ng-apexcharts';
import { formatMoney } from '../services/convert.service';
export type ChartOptions = {
    chart: ApexChart;
    xaxis: ApexXAxis;
    series: ApexAxisChartSeries;
}

@Pipe({
    name: 'findLesson'
})
export class findLessonsPipe implements PipeTransform {

    transform(lessons: any[], chapterKey): any {
        const labels = lessons.filter(m => m.groupKey === chapterKey)
        return labels;
    }
}

@Pipe({
    name: 'gradeSeries'
})
export class GradeSeriesPipe implements PipeTransform {

    transform(value: any[]): any {
        const series = value.map(c => {
            return c.value
        });
        return series;
    }
}


@Pipe({
    name: 'gradeLabels'
})
export class GradeLabelsPipe implements PipeTransform {

    transform(value: any[]): any {
        const labels = value.map(c => {
            return c.name
        });
        return labels;
    }
}

@Pipe({
    name: 'series'
})
export class SeriesPipe implements PipeTransform {

    transform(value: any[]): any {
        const series = value.map(c => {
            return c.value
        });
        return series;
    }
}

@Pipe({
    name: 'labels'
})
export class LabelsPipe implements PipeTransform {

    transform(value: any[]): any {
        const labels = value.map(c => {
            return c.name + ' ' + c.value
        });
        return labels;
    }

}

@Pipe({
    name: 'ChartType'
})
export class ChartTypePipe implements PipeTransform {
    public chartOptions: Partial<ChartOptions>;

    transform(width: number, type: any): any {
        this.chartOptions = {
            chart: {
                width: width,
                type: type,
            },
        }
        return this.chartOptions.chart;
    }

}

@Pipe({
    name: 'ChartXaxis'
})
export class ChartXaxisPipe implements PipeTransform {
    public chartOptions: Partial<ChartOptions>;
    transform(value: any[]): any {
        const labels = value.map(c => {
            return c.name
        });
        this.chartOptions = {
            xaxis: {
                categories: [
                    ...labels
                ]
            }
        }
        return this.chartOptions.xaxis;
    }

}

@Pipe({
    name: 'GradeChartXaxis'
})
export class GradeChartXaxisPipe implements PipeTransform {
    public chartOptions: Partial<ChartOptions>;
    transform(value: any[]): any {
        const labels = value.map(c => {
            return c.schedule_subject?.subject?.name + ' (' + c?.instructor?.full_name + ')'
        });
        this.chartOptions = {
            xaxis: {
                categories: [
                    ...labels
                ]
            }
        }
        return this.chartOptions.xaxis;
    }

}


@Pipe({
    name: 'barSeries'
})
export class BarSeriesPipe implements PipeTransform {
    public chartOptions: Partial<ChartOptions>;

    transform(value: any[]): any {
        const series = value.map(c => {
            return c.value
        });
        this.chartOptions = {
            series: [
                {
                    name: "Total",
                    data: [
                        ...series
                    ]
                }
            ]
        }
        return this.chartOptions.series;
    }

}

@Pipe({
    name: 'gradeBarSeries'
})
export class GradeBarSeriesPipe implements PipeTransform {
    public chartOptions: Partial<ChartOptions>;

    transform(value: any): any {
        if (!value) return
        const chart = value.map(m => {
            return {
                value: Object.values(m.GradeSheetData),
                name: Object.keys(m.GradeSheetData),
            }
        })
        const arrayGrade = [
            {
                name: 'A',
            },
            {
                name: 'B',
            },
            {
                name: 'C',
            },
            {
                name: 'D',
            },
            {
                name: 'E',
            },
            {
                name: 'F',
            },
        ]
        const chartSeries: any[] = arrayGrade.map((obj, n) => {
            return {
                ...obj,
                data: chart.map(x => x.value[n]),
                // label: test.map(x => x.name[n])
            }
        })
        this.chartOptions = {
            series: [
                ...chartSeries
            ]
        }
        return this.chartOptions.series;
    }

}

@Pipe({
    name: 'toGrading'
})
export class ToGradingPipe implements PipeTransform {

    transform(value: any): any {
        if (value) {
            const items = `${value}`.split('.');
            return Number(items[0]);
        }
        return '';
    }
}

@Pipe({
    name: 'toDecimal'
})
export class ToDecimalPipe implements PipeTransform {

    transform(number: any): any {
        if (number) {
            const item = formatMoney(parseFloat((number).toFixed(2)), 2)
            return item;
        }
        return '';
    }

}