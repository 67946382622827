import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_FUNCTIONS, APP_NAME_LIST } from 'src/app/core/dummy/config';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Environment } from 'src/app/core/stores/environment';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  APP_NAME: any = null;
  APP_OPTIONS: any = null;
  showChart: boolean = true;
  user: any = null;
  constructor(
    public store: AuthStore,
    public router: Router,
    public env: Environment
  ) {
    const urls = `${this.router.url}`.split('/');
    // this.APP_NAME = APP_NAME_LIST.find(m => m.key === (urls.length > 0 && urls[1])) || null;
    this.APP_NAME = APP_NAME_LIST.find(m => m.key === 'library') || null;
    this.APP_OPTIONS = (this.APP_NAME && APP_FUNCTIONS[this.APP_NAME.key]) || null;
  }

  async ngOnInit() {
    this.user = await this.store.fetchUser();
    this.store.fetchUserDoc(user => {
      if (user && user.campus) {
        const urls = `${this.router.url}`.split('/');
        const { isAdmin } = user
        if (urls[1] === 'testing-center') {
          const { key } = user.campus;
          this.env.fetchCampusProgram(key, doc => {
            if (isAdmin) {
              const routerItemProgram = this.APP_OPTIONS.routeItems.find(m => m.key === 'programs');
              routerItemProgram.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name, permission: m.key }))
              routerItemProgram.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'programs') return routerItemProgram;
                return m;
              });
            } else {
              const appProgramData: Array<any> = this.store.appPrograms.length > 0 ? this.store.appPrograms[0] : null
              let programDocs = []
              if (doc && doc.length > 0) {
                doc.forEach(p => {
                  const isExist = appProgramData.filter(m => m.key === p.key).length > 0 ? true : false;
                  if (isExist) programDocs.push(p)
                })
                const routerItemProgram = this.APP_OPTIONS.routeItems.find(m => m.key === 'programs');
                routerItemProgram.data = programDocs.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name, permission: m.key }))
                routerItemProgram.remark = programDocs.map(m => m.name).toString()
                this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                  if (m.key === 'programs') return routerItemProgram;
                  return m;
                });

              }

            }

          });

        }
        if (urls[1] === 'scholarships') {
          const { key } = user.campus;
          this.env.fetchCampusProgram(key, doc => {
            if (isAdmin) {
              const routerItemProgram = this.APP_OPTIONS.routeItems.find(m => m.key === 'student-scholarship');
              routerItemProgram.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name }))
              routerItemProgram.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'student-scholarship') return routerItemProgram;
                return m;
              });
            } else {

              const routerItemProgram = this.APP_OPTIONS.routeItems.find(m => m.key === 'student-scholarship');
              routerItemProgram.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name, permission: m.key }))
              routerItemProgram.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'student-scholarship') return routerItemProgram;
                return m;
              });
            }

          });
        }
        if (urls[1] === 'registrar') {

          const { key } = user.campus;

          this.env.fetchCampusProgram(key, (doc: any[]) => {
            if (isAdmin) {
              const routerItemProgram = this.APP_OPTIONS.routeItems.find(m => m.key === 'grading-system');
              routerItemProgram.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name }))
              routerItemProgram.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'grading-system') return routerItemProgram;
                return m;
              });

              const routerItemGradeSheets = this.APP_OPTIONS.routeItems.find(m => m.key === 'grade-sheets');
              routerItemGradeSheets.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name }))
              routerItemGradeSheets.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'grade-sheets') return routerItemGradeSheets;
                return m;
              });

              const routerItemReports = this.APP_OPTIONS.routeItems.find(m => m.key === 'reports');
              routerItemReports.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name }))
              routerItemReports.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'reports') return routerItemReports;
                return m;
              });

              // const routerItemAnalytics = this.APP_OPTIONS.routeItems.find(m => m.key === 'grade-chart');
              // routerItemReports.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name }))
              // routerItemReports.remark = doc.map(m => m.name).toString()
              // this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
              //   if (m.key === 'grade-chart') return routerItemAnalytics;
              //   return m;
              // });

            } else {
              const appProgramData: Array<any> = this.store.appPrograms.length > 0 ? this.store.appPrograms[0] : null
              let programDocs = []
              if (doc && doc.length > 0) {
                doc.forEach(p => {
                  const isExist = appProgramData.filter(m => m.key === p.key).length > 0 ? true : false;
                  if (isExist) programDocs.push(p)
                })
              }
              const routerItemProgram = this.APP_OPTIONS.routeItems.find(m => m.key === 'grading-system');
              routerItemProgram.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name, permission: m.key }))
              routerItemProgram.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'grading-system') return routerItemProgram;
                return m;
              });

              const routerItemGradeSheets = this.APP_OPTIONS.routeItems.find(m => m.key === 'grade-sheets');
              routerItemGradeSheets.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name, permission: m.key }))
              routerItemGradeSheets.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'grade-sheets') return routerItemGradeSheets;
                return m;
              });

              const routerItemReports = this.APP_OPTIONS.routeItems.find(m => m.key === 'reports');
              routerItemReports.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name, permission: m.key }))
              routerItemReports.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'reports') return routerItemReports;
                return m;
              });

              const routerItemAnalytics = this.APP_OPTIONS.routeItems.find(m => m.key === 'grade-chart');
              // routerItemAnalytics.data = doc.map(m => ({ key: m.key, icon: 'arrow_right', name: m.name }))
              // routerItemAnalytics.remark = doc.map(m => m.name).toString()
              this.APP_OPTIONS.routeItems = this.APP_OPTIONS.routeItems.map(m => {
                if (m.key === 'grade-chart') return routerItemAnalytics;
                return m;
              });
            }

          });
        }
      }
    })
  }

  ngOnDestroy() {
    const { fetchCampusProgramRef, getUserRef } = this.env;
    fetchCampusProgramRef && fetchCampusProgramRef.unsubscribe();
    getUserRef && getUserRef.unsubscribe();
  }

}
