import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment'
import { daysOfWeek } from 'src/app/core/dummy/stauts';
import { months, periodData } from 'src/app/core/dummy/report';

@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  constructor() { }

  static countDay(endDate, currentData) {
    return moment(endDate).diff(moment(currentData), 'days')
  }

  static formatDateToKhmer(date: any): string {
    // Example format: 'ថ្ងៃទី 6 ខែ មិថុនា ឆ្នាំ 2024'
    const day = convertToKhmerNumeral(moment(date).date());
    const month = moment(date).locale('km').format('MMMM');
    const year = convertToKhmerNumeral(moment(date).year());
    return `ថ្ងៃទី ${day} ខែ ${month} ឆ្នាំ ${year}`;
  }

  static convertAdminFeeAmount(term, adminFee) {
    const { amount } = adminFee
    let value = 0
    switch (term.key) {
      case 1:
        value = amount
        break;
      case 2:
        value = (amount / 4) * 3
        break;
      case 3:
        value = (amount / 4) * 2
        break;
      case 4:
        value = (amount / 4) * 1
        break;
      default:
        value = amount
        break;
    }
    return value;
  }

  static startFromDate(date: any) {
    return moment(date).startOf('days').toDate();
  }
  static endToDate(date: any) {
    return moment(date).endOf("days").toDate();
  }

  static calcBusinessDays(a, b) {
    const d1 = moment(a)
    const d2 = moment(b)

    const days = d2.diff(d1, 'days');
    const weekends = Math.floor(days / 7);
    let businessDays = days - (weekends * 2);

    if (weekends === 0) {
      const cur = d1.clone();
      for (let i = 0; i < days; i++) {
        if (cur.day() === 0 || cur.day() === 6) {
          businessDays--;
        }
        cur.add(1, 'days')
      }
    } else {
      if (d2.day() === 6) {
        businessDays--;
      }
      if (d1.day() === 0) {
        businessDays--;
      }
    }
    return businessDays;
  }

  static getScholarshipOther(data: Array<any>) {
    let value = null
    if (data && data.length) {
      data.map(m => {
        if (value)
          value = m.amount > value.amount ? m : value
        else
          value = m
      })
    }
    return value;
  }

  static getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age;
  }

  static addDateDay(date: any, interval: number) {
    return moment(date).add(interval, 'days').toDate()
  }

  static fromPeriodToDate(dateString: string) {
    return moment(dateString, 'YYYY-MM-DD').toDate();
  }
  static toPeriodToDate(dateString: Date) {
    return moment(dateString).toDate();
  }

  static numberToDate(date: number) {
    return moment(date, 'YYYYMMDD').toDate();
  }

  static toDayKey(date: Date) {
    const dateKey = moment(date).format('DD');
    return dateKey.toString();
  }

  static toOnlyMonthKey(date: Date) {
    const dateKey = moment(date).format('MM');
    return dateKey.toString();
  }

  static getDateOfYearNew() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}1119`),
    };
  }
  static numberOnly(sender: any) {
    let numb = sender.match(/\d/g);
    numb = numb.join("");
    return this.toNumber(numb);
  }
  static addYear(interval: number) {
    return moment().add(interval, 'year').toDate()
  }
  static toNumber(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number.isNaN(Number(value))) return 0;
    return Number(value);
  }
  static toYear(date: Date) {
    return moment(date).format('YYYY');
  }
  static getDaySchedule(days) {
    var daysInput = Object.keys(days).map(function (key) {
      return { name: key, value: days[key] };
    });

    daysInput = daysInput.filter(m => m.value === true)
    daysInput.sort((a: any, b: any) => {
      var day1 = a.name
      var day2 = b.name
      if (daysOfWeek[day1] < daysOfWeek[day2]) {
        return -1;
      } else if (daysOfWeek[day1] > daysOfWeek[day2]) {
        return 1;
      } else {
        return 0;
      }
    });
    let result = '';
    daysInput.forEach(item => {
      if (item.name === 'monday')
        result = result + 'Monday / '
      else if (item.name.toLowerCase() === 'tuesday')
        result = result + 'Tuesday / '
      else if (item.name.toLowerCase() === 'friday')
        result = result + 'Friday / '
      else if (item.name.toLowerCase() === 'saturday')
        result = result + 'Saturday / '
      else if (item.name.toLowerCase() === 'sunday')
        result = result + 'Sunday / '
      else if (item.name.toLowerCase() === 'thursday')
        result = result + 'Thursday / '
      else if (item.name.toLowerCase() === 'day1')
        result = result + 'Day 1 / '
      else if (item.name.toLowerCase() === 'day2')
        result = result + 'Day 2 / '
      else
        result = result + 'Wednesday / '
    })
    if (result.length > 3)
      result = result.substring(0, result.length - 3)
    return result;
  }


  // static getEwisDaySchedule(days) {
  //   var daysInput = Object.keys(days).map(function (key) {
  //     return { name: key, value: days[key] };
  //   });

  //   daysInput = daysInput.filter(m => m.value === true)
  //   // daysInput.sort((a: any, b: any) => {
  //   //   // var day1 = a.name
  //   //   // var day2 = b.name
  //   //   // if (daysOfWeek[day1] < daysOfWeek[day2]) {
  //   //   //   return -1;
  //   //   // } else if (daysOfWeek[day1] > daysOfWeek[day2]) {
  //   //   //   return 1;
  //   //   // } else {
  //   //   //   return 0;
  //   //   // }
  //   // });
  //   let result = '';
  //   daysInput.forEach(item => {
  //     if (item.name.toLowerCase() === 'day1')
  //       result = result + 'Day 1 / '
  //     else if (item.name.toLowerCase() === 'day2')
  //       result = result + 'Day 2 / '
  //     // else if (item.name.toLowerCase() === 'friday')
  //     //   result = result + 'Friday / '
  //     // else if (item.name.toLowerCase() === 'saturday')
  //     //   result = result + 'Saturday / '
  //     // else if (item.name.toLowerCase() === 'sunday')
  //     //   result = result + 'Sunday / '
  //     // else if (item.name.toLowerCase() === 'thursday')
  //     //   result = result + 'Thursday / '
  //     else
  //       result = result + 'Day 3 / '
  //   })
  //   if (result.length > 3)
  //     result = result.substring(0, result.length - 3)
  //   return result;
  // }


  static toFloatFixed(value, fix) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number.isNaN(Number(value))) return 0;
    return Number(value.toFixed(fix));
  }

  static roundUp2(num) {
    const p = Math.pow(10, 1);
    const m = (num * p) * (1 + Number.EPSILON);
    const value = Math.round(m) / p
    return Number(value.toFixed(2));
  }

  static toCapitalize(value) {
    let string = null;
    if (value) string = value.toUpperCase().toString().trim();
    return string;
  }

  static toLowerCase(value) {
    let string = null;
    if (value) string = value.toLowerCase().toString().trim();
    return string;
  }

  static getSessionDayTrue(session) {
    let result = [];
    if (session && session.days) {
      let daysInput = Object.keys(session.days).map(function (key) {
        return { name: key, value: session.days[key] };
      });

      result = daysInput.filter(m => m.value === true);
    }
    return result;
  }

  static getDayString(num: number) {
    const date = moment(num, 'YYYYMMDD').toDate()
    let day = moment(date).format('dddd')
    day = day.toLowerCase()
    return day;
  }

  static getNumber(value: any, getval: any): number {
    if (Number.isNaN(value) || value === null || value === undefined || value === '') {
      return getval;
    }
    return Number(value);
  }

  static toNull(value) {
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static toNullInputValue(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static toNullInputKey(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value.key;
  }

  static yearSuffix() {
    return moment().format('YY').toString();
  }
  static addWeek(interval: number) {
    return moment().add(interval, 'week').toDate()
  }

  static addDays(interval: number) {
    return moment().add(interval, 'days').toDate()
  }

  static addMonth(interval: number) {
    return moment().add(interval, 'month').toDate()
  }

  static addDateMonth(date: any, interval: number) {
    return moment(date).add(interval, 'month').toDate()
  }

  static addMonthDateRemove1Day(date: any, interval: number) {
    return moment(date).add(interval, 'month').subtract(1, 'days').toDate()
  }

  static toHourMM(date: Date) {
    return Number(moment(date).format('HH.mm'))
  }

  static pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

  static hmsKey() {
    return Number(moment().format('HHmmss'))
  }

  static toDateKey(date: Date) {
    return Number(moment(date).format('YYYYMMDD'))
  }

  static toYearMonthKey(date: Date) {
    return Number(moment(date).format('YYYYMM'))
  }

  static dateKey() {
    return Number(moment().format('YYYYMMDD'))
  }
  static toFloatFixed2(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number.isNaN(Number(value))) return 0;
    return Number(value.toFixed(2));
  }
  static hourToNumber(time) {
    return Number(moment(time, 'hh:mm a').format('HH').toString() + '.' + moment(time, 'hh:mm a').format('mm').toString());
  }

  static toHour(time) {
    return moment(time, 'hh:mm a').format('hh:mm a')
  }

  static generate_testing_invoiceNo(config) {
    var number = moment().format('YYMMDD').toString() + (config.invoice_shufit + 1).toString();
    return Number(number);
  }

  static generate_puc_id(config) {
    const value = `0${config.puc_id + 1}`;
    return value;
  }

  static generate_library_book_inventory_number(config) {
    const value = config.inventory_number + 1
    // const format = `000000`.substring(0, 6 - `${value}`.length) + value
    return value;
  }

  static getDefaultDateReport(day: number) {
    const yearMonth = moment(new Date).format('YYYYMM');
    const yearMonthDay = `${yearMonth}${day}`;
    const toDate = moment(yearMonthDay).toDate();
    return {
      form_date: this.toDateSting(moment(toDate).subtract(3, 'months').add(1, 'days')),
      to_date: this.toDateSting(toDate),
    };
  }

  static getDateOfMonth(day: number, months) {
    const yearMonthFrom = moment(new Date).subtract(months, 'months').format('YYYYMM');
    const yearMonthDay = `${yearMonthFrom}${day + 1}`;
    const yearMonth = moment(new Date).format('YYYYMM');
    const toDate = moment(`${yearMonth}${day}`).toDate();
    return {
      form_date: this.toDateSting(yearMonthDay),
      to_date: this.toDateSting(toDate),
    };
  }

  static getDateOfYear(day: number) {
    const lastYearNumber = toNumber(moment(new Date).subtract(1, 'years').format('YYYY'))
    const yearMonth = moment(new Date).format('YYYYMM');
    return {
      form_date: this.toDateSting(moment(`${lastYearNumber}12${day + 1}`, 'YYYYMMDD').toDate()),
      to_date: this.toDateSting(moment(`${yearMonth}${day}`, 'YYYYMMDD').toDate()),
    };
  }

  static getDateCurrentMonth() {
    const current = moment(new Date()).format('YYYYMM')
    const last = toNumber(moment(new Date()).subtract(1, 'months').format('YYYYMM'))
    return {
      form_date: toNumber(`${last}20`),
      to_date: toNumber(`${current}19`),
    };
  }

  static getDateFirstQuarter() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}0219`),
    };
  }

  static getDateSecondQuarter() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current;
    return {
      form_date: toNumber(`${last}0220`),
      to_date: toNumber(`${current}0519`),
    };
  }

  static getDateFirstSemester() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}0519`),
    };
  }

  static getDateSecondSemester() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current;
    return {
      form_date: toNumber(`${last}0520`),
      to_date: toNumber(`${current}1119`),
    };
  }

  static getDateNineMonth() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}0819`),
    };
  }



  static getDateOfLastYearNew() {
    const current = toNumber(moment(new Date()).subtract(1, 'years').format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}1119`),
    };
  }

  static getCurrentYear() {
    const year = moment(new Date).format('YYYY');
    return year;
  }

  static toDateSting(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }
  static getDateOfLastYear(day: number) {
    const lastYearNumber = toNumber(moment(new Date).subtract(2, 'years').format('YYYY'))
    const endLastYear = moment(new Date).subtract(1, 'years').endOf('years').format('YYYYMM');
    return {
      form_date: this.toDateSting(moment(`${lastYearNumber}12${day + 1}`, 'YYYYMMDD').toDate()),
      to_date: this.toDateSting(moment(`${endLastYear}${day}`, 'YYYYMMDD').toDate()),
    };
  }

  static getDateCompareOfLastYear(day: number) {
    const lastYearNumber = toNumber(moment(new Date).subtract(3, 'years').format('YYYY'))
    const endLastYear = moment(new Date).subtract(1, 'years').endOf('years').format('YYYYMM');
    return {
      form_date: this.toDateSting(moment(`${lastYearNumber}12${day + 1}`, 'YYYYMMDD').toDate()),
      to_date: this.toDateSting(moment(`${endLastYear}${day}`, 'YYYYMMDD').toDate()),
    };
  }

}

export function toUpperCase(value: string) {
  if (value === "" || value === undefined) {
    return null;
  }
  return value.toUpperCase();
}

function convertToKhmerNumeral(number: number): string {
  const khmerDigits = ['០', '១', '២', '៣', '៤', '៥', '៦', '៧', '៨', '៩'];
  return number.toString().split('').map(digit => khmerDigits[parseInt(digit)]).join('');
}

export function sum(data: Array<any>, field: string) {
  if (!data) return 0;
  return data.reduce((a, b) => a + toNumber(b[field]), 0)
}

export function calRank(totalScore, data: Array<any>) {
  const value = data.sort((a, b) => { return b - a; }).indexOf(totalScore) + 1;
  return value > 0 ? value : '';
}

export function toNumber(value) {
  if (value === null || value === "" || value === undefined) {
    return 0;
  }
  if (Number.isNaN(Number(value))) return 0;
  return Number(value);
}

export const getDatesBetweenDates = (startDate, endDate) => {
  let dates = []
  //to avoid modifying the original date
  const theDate = new Date(startDate)
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, endDate]
  return dates
}
export const getDatesBetweenDatesSame = (startDate, endDate) => {
  const fromdate = Number(moment(startDate).format('YYYYMMDD'));
  const todate = Number(moment(endDate).format('YYYYMMDD'));
  const isSame = fromdate === todate;
  return isSame
}


export const getDatesDayBetweenDates = (startDate, endDate) => {
  let dates = []
  let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  //to avoid modifying the original date
  const theDate = new Date(startDate)
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate)]
    theDate.setDate(theDate.getDate() + 1)
  }
  dates = [...dates, endDate]
  const data = dates.map(f => (days[f.getDay()]))
  const newData = [...new Set(data)]
  return newData
}

export const createKeywords = name => {
  const arrName = [];
  let curName = '';
  let nextName = '';
  name.trim().split(/[ .\-_ ]/).forEach(letter => {
    curName += letter;
    arrName.push(ConvertService.toCapitalize(letter));
    arrName.push(ConvertService.toCapitalize(curName));
  });

  name.split('').forEach(letter => {
    nextName += letter;
    arrName.push(ConvertService.toCapitalize(nextName));
  });

  return arrName;
}



export function toMonthKh(date: any) {
  const index = moment(date).format("MM");
  return months[index].name
}

export function toMonthNum(date: Date) {
  const index = moment(date).format("MM");
  return months[index].key
}


export function toDayOfMonth(date: any) {
  const index = moment(date).format("DD");
  return index;
}


export function toMonthOfYear(date: Date) {
  const index = moment(date).format("MM");
  return index;
}

export function toYearOfPeriod(date: any) {
  const index = moment(date).format("YYYY");
  return index;
}

export function getDateReport(optionKey) {
  const items = periodData.filter(m => m.key === optionKey)[0];
  switch (toNumber(items.key)) {
    case 1:
      const fromDate1 = ConvertService.getDateCurrentMonth().form_date
      const toDate1 = ConvertService.getDateCurrentMonth().to_date
      return {
        fromDate: fromDate1,
        toDate: toDate1
      }
    case 2:
      const fromDate2 = ConvertService.getDateFirstQuarter().form_date
      const toDate2 = ConvertService.getDateFirstQuarter().to_date
      return {
        fromDate: fromDate2,
        toDate: toDate2
      }
    case 3:
      const fromDate3 = ConvertService.getDateSecondQuarter().form_date
      const toDate3 = ConvertService.getDateSecondQuarter().to_date
      return {
        fromDate: fromDate3,
        toDate: toDate3
      }
    case 4:
      const fromDate4 = ConvertService.getDateFirstSemester().form_date
      const toDate4 = ConvertService.getDateFirstSemester().to_date
      return {
        fromDate: fromDate4,
        toDate: toDate4
      }
    case 5:
      const fromDate5 = ConvertService.getDateSecondSemester().form_date
      const toDate5 = ConvertService.getDateSecondSemester().to_date
      return {
        fromDate: fromDate5,
        toDate: toDate5
      }
    case 6:
      const fromDate6 = ConvertService.getDateNineMonth().form_date
      const toDate6 = ConvertService.getDateNineMonth().to_date
      return {
        fromDate: fromDate6,
        toDate: toDate6
      }
    case 7:
      const fromDate7 = ConvertService.getDateOfYearNew().form_date
      const toDate7 = ConvertService.getDateOfYearNew().to_date
      return {
        fromDate: fromDate7,
        toDate: toDate7
      }
    case 8:
      const fromDate8 = ConvertService.getDateOfLastYearNew().form_date
      const toDate8 = ConvertService.getDateOfLastYearNew().to_date
      return {
        fromDate: fromDate8,
        toDate: toDate8
      }
    default:
      break;
  }

}

export function toNullFromZero(value) {
  if (value === null || value === "" || value === undefined || value === 0) {
    return null;
  }
  return value;
}


export function monthOfDate(date: Date) {
  return moment(date).format('YYYYMM');
}

export function lastMonthOfDate(date: Date) {
  return moment(date).subtract(1, 'month').format('YYYYMM');
}

export function formatMoney(amount: any, decimalCount = 2, decimal = '.', thousands = ',') {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i: any = parseInt((amount = Math.abs(toNumber(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '1' + thousands) +
      (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
    );
  } catch (e) {
  }
}



export class CampusDetail {
  constructor(
    readonly key: string,
    readonly short_name: string,
    readonly schoolKey: any,
    readonly order: any,
    readonly name: any,
    readonly khName: any,
    readonly school: any,

  ) { }

  toString(): string {
    return null
  }
}
export const campusConverterDetail = {
  toFirestore(stock: CampusDetail): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      short_name: stock.short_name,
      schoolKey: stock.schoolKey,
      order: stock.order,
      name: stock.name,
      khName: stock.khName,
      school: stock.school,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): CampusDetail {
    const data = snapshot.data(options)!;
    return new CampusDetail(
      data.key,
      data.short_name,
      data.schoolKey,
      data.order,
      data.name,
      data.khName,
      data.school,
    );
  }

};




export class ScheduleDetail {
  constructor(
    readonly key: string,
    readonly batch: any,
    readonly batchKey: any,
    readonly sessionItems: any,
    readonly instructor: any,
    readonly schedule_subject: any,
    readonly academicYear: any,

  ) { }

  toString(): string {
    return null
  }
}


export const scheduleConverterDetail = {
  toFirestore(stock: ScheduleDetail): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      refNo: stock.batch,
      batchKey: stock.batchKey,
      sessionItems: stock.sessionItems,
      instructor: stock.instructor,
      schedule_subject: stock.schedule_subject,
      academicYear: stock.academicYear,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): ScheduleDetail {
    const data = snapshot.data(options)!;
    return new ScheduleDetail(
      data.key,
      data.batch,
      data.batchKey,
      data.sessionItems,
      data.instructor,
      data.schedule_subject,
      data.academicYear,
    );
  }
};


export class EwisAddmissionStudent {
  constructor(
    readonly key: string,
    readonly grade: string,
    readonly academicYearKey: string,
    readonly academicYear: string,
    readonly campus: string,
    readonly campusKey: string,
    readonly full_name: string,
    readonly studentKey: string,
    readonly puc_id: string,
    readonly gender: string,
    readonly school: string,
    readonly schoolKey: string,
  ) { }

  toString(): string {
    return null
  }
}


export const EwisAddmissionStudentConverterDetail = {
  toFirestore(stock: EwisAddmissionStudent): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      grade: stock.grade,
      academicYearKey: stock.academicYearKey,
      academicYear: stock.academicYear,
      campus: stock.campus,
      campusKey: stock.campusKey,
      full_name: stock.full_name,
      studentKey: stock.studentKey,
      puc_id: stock.puc_id,
      gender: stock.gender,
      school: stock.school,
      schoolKey: stock.schoolKey,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): EwisAddmissionStudent {
    const data = snapshot.data(options)!;
    return new EwisAddmissionStudent(
      data.key,
      data.program_academic.name,
      data.academicYearKey,
      data.academicYear.name,
      data.campus.name,
      data.campusKey,
      data.student.full_name,
      data.student.key,
      data.student.puc_id,
      data.student.gender.key,
      data.school.name,
      data.schoolKey,
    );
  }
};



export class keyOnly {
  constructor(
    readonly key: string,
  ) { }

  toString(): string {
    return null
  }
}

export const keyOnlyConverterDetail = {
  toFirestore(stock: keyOnly): firebase.firestore.DocumentData {
    return {
      key: stock.key,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): keyOnly {
    const data = snapshot.data(options)!;
    return new keyOnly(
      data.key,
    );
  }
};

export class ITAttendance {
  constructor(
    readonly key: string,
    readonly date_key: number,
  ) { }

  toString(): string {
    return null
  }
}

export const converterTAttendance = {
  toFirestore(doc: ITAttendance): firebase.firestore.DocumentData {
    return {
      key: doc.key,
      date_key: doc.date_key,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): keyOnly {
    const data = snapshot.data(options)!;
    return new ITAttendance(
      data.key,
      data.date_key,
    );
  }
};

export class total_enrollOnly {
  constructor(
    readonly key: string,
    readonly total_enroll: string,

  ) { }

  toString(): string {
    return null
  }
}
export const total_enrollOnlyConverterDetail = {
  toFirestore(stock: total_enrollOnly): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      total_enroll: stock.total_enroll,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): total_enrollOnly {
    const data = snapshot.data(options)!;
    return new total_enrollOnly(
      data.key,
      data.total_enroll,
    );
  }

};

export function toDayOfMonthLocal(date: any) {
  const index = moment(date).locale('km').format("DD");
  return index;
}
export function toMonthKhLocal(date: any) {
  const index = moment(date).locale('km').format("MM");
  return months[index].name
}
export function toYearOfPeriodLocal(date: any) {
  const index = moment(date).locale('km').format("YYYY");
  return index;
}



export class EwisGradeschedule {
  constructor(
    readonly key: string,
    readonly academicYearKey: string,
    readonly academicYear: string,
    readonly subject_key: string,
    readonly subject_name: string,
    readonly subject_room: string,
    readonly sessionDays: any,
    readonly sessionItems: any,
    readonly session_show: any,
    readonly shift: any,
    readonly instructorKey: any,
    readonly instructorCode: string,

    readonly instructor_last_name: any,
    readonly instructor_first_name: any,
  ) { }
  toString(): string {
    return null
  }
}

export const EwisGradescheduleConverterDetail = {
  toFirestore(stock: EwisGradeschedule): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      academicYearKey: stock.academicYearKey,
      academicYear: stock.academicYear,
      subject_key: stock.subject_key,
      subject_name: stock.subject_name,
      subject_room: stock.subject_room,
      sessionDays: stock.sessionDays,
      sessionItems: stock.sessionItems,
      session_show: stock.session_show,
      shift: stock.shift,
      instructorKey: stock.instructorKey,
      instructorCode: stock.instructorCode,
      instructor_last_name: stock.instructor_last_name,
      instructor_first_name: stock.instructor_first_name,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): EwisGradeschedule {
    const data = snapshot.data(options)!;
    return new EwisGradeschedule(
      data.key,
      data.academicYear.key,
      data.academicYear.name,
      data.schedule_subject.subject.key,
      data.schedule_subject.subject.name,
      data.room ? data.room : null,
      data.sessionDays,
      data.sessionItems,
      data.session_show,
      data.shift,
      data.instructor.key ? data.instructor.key : null,
      data.instructor.code ? data.instructor.code : null,

      data.instructor.last_name ? data.instructor.last_name : null,
      data.instructor.first_name ? data.instructor.first_name : null,

      // data.instructor.last_name,
      // data.instructor.first_name,
    );
  }
};
