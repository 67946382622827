import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const date = new Date(value);
    return new DatePipe('en-US').transform(date, 'dd MMMM yyyy, hh:MM:ss a');
  }

}

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: any): string | null {
    if (!value) return null;
    if (typeof value === 'object' && 'hour' in value && 'minute' in value && 'second' in value) {
      const { hour, minute, second } = value;
      return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`;
    }
    const date = new Date(value);
    return new DatePipe('en-US').transform(date, 'hh:mm:ss a');
  }

}