<aside class="sidebar">
  <div class="sidebar-brand" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
    <div class="sbar-brand-name">
      Class Manager
      <!-- {{"name" | getSchoolConfig}} -->
    </div>
  </div>
  <div class="sidebar-header">
    <div class="header-content">
      <div class="list-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/']">
        <div class="icon">
          <i class="material-icons">&#xE88A;</i>
        </div>
        <div class="item-name">
          Home
        </div>
      </div>
      <!-- <div class="sitting" [matMenuTriggerFor]="menusitting">
                <i class="material-icons">&#xE8B8;</i>
            </div>
            <mat-menu #menusitting="matMenu" class="cs-position flr">
                <div class="menu-content">
                    <button mat-menu-item>
                        <mat-icon>dialpad</mat-icon>
                        <span>Redial</span>
                    </button>

                </div>

            </mat-menu> -->
    </div>
  </div>
  <div class="sidebar-list-wrapper">
    <mat-accordion [multi]="true">
      <!--
            <mat-expansion-panel [expanded]="expansions.one" (closed)="onClosed(1)" (opened)="onOpened(1)">
                <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px"
                    [class.active]="expansions?.one">
                    <mat-panel-title>
                        E-Payments
                    </mat-panel-title>
                    <mat-panel-description *ngIf="!expansions?.one">Print, Listing and Summary Scholarship
                        Reports.
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="list-content">

                    <div class="list-item">
                        <div class="list-item-row" routerLinkActive="active" [routerLink]="['/payment/aba-e-payment']">
                            <div class="icon">
                                <i class="material-icons">account_balance</i>
                            </div>
                            <div class="item-name">
                                ABA Billing
                            </div>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/payment/wing-merchant-payment']">
                            <div class="icon">
                                <i class="material-icons">account_balance</i>
                            </div>
                            <div class="item-name">
                                Wing merchant
                            </div>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/payment/acleda-e-payment']">
                            <div class="icon">
                                <i class="material-icons">account_balance</i>
                            </div>
                            <div class="item-name">
                                ACLEDA Billing
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="expansions.two" (closed)="onClosed(2)" (opened)="onOpened(2)">
                <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px"
                    [class.active]="expansions?.two">
                    <mat-panel-title>
                        Cashier Reports
                    </mat-panel-title>
                    <mat-panel-description *ngIf="!expansions?.two">ABC, Schedules, Admissions and Teaching
                        Assignment
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="list-content">
                    <div class="list-item">
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/finance-report/daily-campus-listing']">
                            <div class="icon">
                                <i class="material-icons">business</i>
                            </div>
                            <div class="item-name">
                                Daily Campus listing
                            </div>
                        </div>
                    </div>
                    <div class="list-item">
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/finance-report/daily-shift-listing']">
                            <div class="icon">
                                <i class="material-icons">assignment</i>
                            </div>
                            <div class="item-name">
                                Daily Shift listing
                            </div>
                        </div>
                    </div>

                    <div class="list-item">
                        <div class="list-item-row" routerLinkActive="active" [routerLink]="['/finance-report/expense-listing']">
                            <div class="icon">
                                <i class="material-icons">work_off</i>
                            </div>
                            <div class="item-name">
                                Expense listing
                            </div>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>

             -->

      <mat-expansion-panel [expanded]="expansions.two" (closed)="onClosed(2)" (opened)="onOpened(2)" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.develop)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.two">
          <mat-panel-title>
            Develop
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.two">
            Student Profile, New Students, Enroll Courses, Batch Enrollment, Batch Schedules and Teaching
            Assignment
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="list-content">
          <div class="list-item">
            <a class="list-item-row" routerLinkActive="active"
              [routerLink]="['/develop/class-manager/institute-and-center']"
              *ngIf=" auth?.user?.isAdmin || (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.profile)">
              <div class="icon">
                <i class="material-icons">account_circle</i>
              </div>
              <div class="item-name">
                Student Profile
              </div>
            </a>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/assign-batches/students']"
              *ngIf="auth?.user?.isAdmin || (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.freshmen)">
              <div class="icon">
                <i class="material-icons">group_add</i>
              </div>
              <div class="item-name">
                Freshmen
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/class-schedule/data']"
              *ngIf=" auth?.user?.isAdmin || (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.batch_schedules)">
              <div class="icon">
                <i class="material-icons">perm_contact_calendar</i>
              </div>
              <div class="item-name">
                Batch Schedules
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expansions.three" (closed)="onClosed(3)" (opened)="onOpened(3)"
        *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.enrollment) ||  (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.batch_enrollment)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.three">
          <mat-panel-title>
            Batch Enrollment
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.three">
            Student Profile, New Students, Enroll Courses, Batch Enrollment, Batch Schedules and Teaching
            Assignment
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="list-content">
          <div class="list-item">
            <!-- <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/develop/class-manager/enroll-courses']" *ngIf="
                            auth?.user?.isAdmin ||
                            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.enrollment)">
              <div class="icon">
                <i class="material-icons">playlist_add</i>
              </div>
              <div class="item-name">
                Enrollment
              </div>
            </div> -->
            <div class="list-item-row" routerLinkActive="active"
              [routerLink]="['/develop/class-manager/batch-enrollment']" *ngIf="
                            auth?.user?.isAdmin ||
                            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.batch_enrollment)">
              <div class="icon">
                <i class="material-icons">view_carousel</i>
              </div>
              <div class="item-name">
                Batch Enrollment
              </div>
            </div>


            <!-- <div *ngIf="isEwis" class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/grade-schedule/data']">
              <div class="icon">
                <i class="material-icons">table_chart</i>
              </div>
              <div class="item-name">
                Grade Schedule
              </div>
            </div> -->
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expansions.four" (closed)="onClosed(4)" (opened)="onOpened(4)" *ngIf="auth?.user?.isAdmin ||
      (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.administrator)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.four">
          <mat-panel-title>
            Administrator
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.four">
            Training Program, Fee Category, Curricular, Subjects, Levels, Programs
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/academic-year/data']" *ngIf="auth?.user?.isAdmin ||
                            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.academic)">
              <div class="icon">
                <i class="material-icons">today</i>
              </div>
              <div class="item-name">
                Academic Year
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/program-fee/data']" *ngIf="
                            auth?.user?.isAdmin ||
                            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.training_program)">
              <div class="icon">
                <i class="material-icons">local_mall</i>
              </div>
              <div class="item-name">
                Training Program
              </div>
            </div>
            <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/school-fee/data']" *ngIf="
                            auth?.user?.isAdmin ||
                            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.school_fee)">
              <div class="icon">
                <i class="material-icons">euro_symbol</i>
              </div>
              <div class="item-name">
                School Fee
              </div>
            </div> -->
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/fee-category/data']" *ngIf="
                            auth?.user?.isAdmin ||
                            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.fee_category)">
              <div class="icon">
                <i class="material-icons">receipt</i>
              </div>
              <div class="item-name">
                Fee Category
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/academic-grade']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.curricular)">
              <div class="icon">
                <i class="material-icons">work_outline</i>
              </div>
              <div class="item-name">
                Curricular
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/subject/data']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.subjects)">
              <div class="icon">
                <i class="material-icons">school</i>
              </div>
              <div class="item-name">
                Subjects
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/levels/data']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.levels)">
              <div class="icon">
                <i class="material-icons">verified_user</i>
              </div>
              <div class="item-name">
                Levels
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/programs/data']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.programs)">
              <div class="icon">
                <i class="material-icons">dashboard</i>
              </div>
              <div class="item-name">
                Programs
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/education-levels/data']"
              *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.education_levels)">
              <div class="icon">
                <i class="material-icons">dns</i>
              </div>
              <div class="item-name">
                Education Levels
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/payment-option/data']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.payment_option)">
              <div class="icon">
                <i class="material-icons">filter_frames</i>
              </div>
              <div class="item-name">
                Payment Option
              </div>
            </div>


            <!-- <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/develop/payment-discount/data']">
                            <div class="icon">
                                <i class="material-icons">money_off</i>
                            </div>
                            <div class="item-name">
                                Payment Discount
                            </div>
                        </div>
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/develop/scholarship-type/data']">
                            <div class="icon">
                                <i class="material-icons">card_giftcard</i>
                            </div>
                            <div class="item-name">
                                Scholarship Type
                            </div>
                        </div>
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/develop/scholarship-approval/data']">
                            <div class="icon">
                                <i class="material-icons">accessibility</i>
                            </div>
                            <div class="item-name">
                                Scholarship Approval
                            </div>
                        </div> -->
          </div>
        </div>

      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="expansions.five" (closed)="onClosed(5)" (opened)="onOpened(5)" *ngIf="
      auth?.user?.isAdmin ||
      (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.account)">
        <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px" [class.active]="expansions?.five">
          <mat-panel-title>
            Account & Data
          </mat-panel-title>
          <mat-panel-description *ngIf="!expansions?.five">
            Lecturer Account, Student Account, Parent Account, Shift, Sessions and Instructors
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="list-content">
          <div class="list-item">
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/classroom-account']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.lecture_account)">
              <div class="icon">
                <i class="material-icons">folder_shared</i>
              </div>
              <div class="item-name">
                Lecturer Account
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/student-account/data']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.student_account)">
              <div class="icon">
                <i class="material-icons">accessibility_new</i>
              </div>
              <div class="item-name">
                Student Account
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/parent-account/data']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.parent_account)">
              <div class="icon">
                <i class="material-icons">people_outline</i>
              </div>
              <div class="item-name">
                Parent Account
              </div>
            </div>
            <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/classroom/data']">
                            <div class="icon">
                                <i class="material-icons">room</i>
                            </div>
                            <div class="item-name">
                                Rooms & Building
                            </div>
                        </div> -->
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/shift/data']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.shift)">
              <div class="icon">
                <i class="material-icons">schedule</i>
              </div>
              <div class="item-name">
                Shift
              </div>
            </div>
            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/session/data']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.sessions)">
              <div class="icon">
                <i class="material-icons">alarm_add</i>
              </div>
              <div class="item-name">
                Sessions
              </div>
            </div>

            <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/instructor/data']" *ngIf="
            auth?.user?.isAdmin ||
            (auth?.appModules | permissionModule: ENROLLMENT_MODULE_OBJ.Instructors)">
              <div class="icon">
                <i class="material-icons">supervisor_account</i>
              </div>
              <div class="item-name">
                Instructors
              </div>
            </div>

            <!-- <div class="list-item-row" routerLinkActive="active" [routerLink]="['/develop/color']">
              <div class="icon">
                <i class="material-icons">color_lens</i>
              </div>
              <div class="item-name">
                Color
              </div>
            </div> -->

            <!-- <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/develop/public-holiday/data']">
                            <div class="icon">
                                <i class="material-icons">public</i>
                            </div>
                            <div class="item-name">
                                Public Holiday
                            </div>
                        </div> -->
          </div>
        </div>

      </mat-expansion-panel>

      <!-- <mat-expansion-panel [expanded]="expansions.six" (closed)="onClosed(6)" (opened)="onOpened(6)">
                <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px"
                    [class.active]="expansions?.six">
                    <mat-panel-title>
                        Cashier Report
                    </mat-panel-title>
                    <mat-panel-description *ngIf="!expansions?.six">Term, Instructor and Room
                        Reports.
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="list-content">
                    <div class="list-item">
                        <div class="list-item-row" routerLinkActive="active" [routerLink]="['/cashier/testing/all']">
                            <div class="icon">
                                <i class="material-icons">public</i>
                            </div>
                            <div class="item-name">
                                Registration
                            </div>
                        </div>
                        <div class="list-item-row" routerLinkActive="active" [routerLink]="['/cashier/petty-cash']">
                            <div class="icon">
                                <i class="material-icons">local_mall</i>
                            </div>
                            <div class="item-name">
                                Expense
                            </div>
                        </div>
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/cashier/other-payment-fee']">
                            <div class="icon">
                                <i class="material-icons">rss_feed</i>
                            </div>
                            <div class="item-name">
                                Other Fees
                            </div>
                        </div>
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/cashier/daily-shift-listing']">
                            <div class="icon">
                                <i class="material-icons">library_books</i>
                            </div>
                            <div class="item-name">
                                Daily shift listing
                            </div>
                        </div>
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/cashier/receipt-listing']">
                            <div class="icon">
                                <i class="material-icons">receipt</i>
                            </div>
                            <div class="item-name">
                                Receipt listing
                            </div>
                        </div>
                        <div class="list-item-row" routerLinkActive="active"
                            [routerLink]="['/cashier/expense-listing']">
                            <div class="icon">
                                <i class="material-icons">money_off</i>
                            </div>
                            <div class="item-name">
                                Expense listing
                            </div>
                        </div>
                    </div>
                </div>

            </mat-expansion-panel> -->
      <!--
            <mat-expansion-panel [expanded]="expansions.seven" (closed)="onClosed(7)" (opened)="onOpened(7)">
                <mat-expansion-panel-header expandedHeight="58px" collapsedHeight="78px"
                    [class.active]="expansions?.seven">
                    <mat-panel-title>
                        Testing Center
                    </mat-panel-title>
                    <mat-panel-description *ngIf="!expansions?.seven">New registration test, online test and result
                        test</mat-panel-description>
                </mat-expansion-panel-header>
                <div class="list-content">
                    <div class="list-item">
                        <div class="list-item-row" *ngFor="let item of programList" routerLinkActive="active"
                            [routerLink]="['/testing-center/' + item.key + '/all']">
                            <div class="icon">
                                <i class="material-icons">arrow_right</i>
                            </div>
                            <div class="item-name">
                                {{item?.name}}
                            </div>
                        </div>
                    </div>

                </div>
            </mat-expansion-panel> -->
    </mat-accordion>
  </div>
  <div class="sidebar-footer">
    <div class="sidebar-toggle-button" (click)="togglesidebar()">
      <div class="arrow-icon"></div>
    </div>
  </div>
</aside>
