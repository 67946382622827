import { Observable } from 'rxjs';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { map, startWith } from 'rxjs/operators';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import { firestore } from 'firebase/app';
import * as moment from 'moment';
import { ConvertService } from './convert.service';
import * as firebase from 'firebase/app'
import { IBasicTextbookInventory, IBook, IBookcase, IGenres, ISection, ITypeOfDisease, ITypeOfMedicine } from 'src/app/core/interfaces/book-store';
import { IAuthor, IAuthorRole, IBookShelf, IDevice, IPrintingPlace } from 'src/app/core/interfaces/resource';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor() { }
  static uniqOnTop(data: Array<any>, field: string, filedOrderBy: string) {
    const rows = _.orderBy(data, [filedOrderBy], ['desc'])
    return _.uniqBy(rows, field)
  }

  static instituteObj(item: any) {
    return {
      key: item.key,
      shortName: item.shortName,
      name: item.name,
      instituteType: item.instituteType,
    };
  }
  static groupByOrderDesc(data: Array<any>, field: string, orderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [orderBy], ['desc'])
  }
  static sumTuitionFees(data: Array<any>) {
    let value = 0;
    const unpaidDocs = data.filter(m => m.scholarshipRef && m.scholarshipRef.key === "unpaid")
    if (unpaidDocs.length > 0) {
      value = unpaidDocs[0].price;
    } else {
      data.forEach(doc => {
        value += doc.amount + ConvertService.toNumber(doc.loan) + + ConvertService.toNumber(doc.scholarship)
      })
    }
    return value;
  }

  static pushToArray(data: firestore.QuerySnapshot) {
    if (data.empty) return [];
    return data.docs.map(m => { return { ...m.data() } })
  }
  static pushToObject(doc: firestore.DocumentSnapshot) {
    if (!doc.exists) return null;
    return { ...doc.data() };
  }

  static sumCreditAttempted(data: Array<any>) {
    return data.reduce((acc, cur) => acc + Number(cur.credits_attempted), 0)
  }

  static sumCreditCalculator(data: Array<any>) {
    return data.reduce((acc, cur) => acc + Number(cur.credits_calculator), 0)
  }

  static sumTotalPoint(data: Array<any>) {
    return data.reduce((acc, cur) => acc + cur.total, 0)
  }

  static sumCreditEarned(data: Array<any>) {
    return data.reduce((acc, cur) => acc + cur.credits_earned, 0)
  }
  static fromDateFilter(key) {
    switch (key) {
      case "today":
        return moment().startOf('day').toDate();
      case "yesterday":
        return moment().subtract(1, 'day').startOf('day').toDate();
      case "this_week":
        return moment().startOf('week').toDate();
      case "this_month":
        return moment().startOf('month').toDate();
      case "this_year":
        return moment().startOf('year').toDate();
      default:
        return moment().toDate();
    }
  }

  static toDateFilter(key) {
    switch (key) {
      case "today":
        return moment().endOf('day').toDate();
      case "yesterday":
        return moment().subtract(1, 'day').endOf('day').toDate();
      case "this_week":
        return moment().endOf('week').toDate();
      case "this_month":
        return moment().endOf('month').toDate();
      case "this_year":
        return moment().endOf('year').toDate();
      default:
        return moment().toDate();
    }
  }

  static startDateAttendanceSheet(date: Date) {
    const month = moment(date).format('MM');
    const year = moment(date).format('YYYY');
    const period = moment('27' + month.toString() + year.toString(), 'DDMMYYYY').add(0, 'month').add(1, 'day').toDate();
    // const period = moment(date,"DDMMYYYY").toDate();
    return period;
  }
  static endEndAttendanceSheet(startDate: Date) {
    return moment(startDate).add(1, 'month').toDate();
  }
  static durationByDays(startDate, endDate) {
    const now = moment(startDate);
    const end = moment(endDate);
    return moment.duration(now.diff(end)).asDays();
  }
  static dateAdd(date, day) {
    const days = moment(date).add(day, 'day').toDate();
    return days;
  }
  static getMoth(date: Date) {
    return moment(date).format('MMM');
  }
  static getDayName(date: Date) {
    return moment(date).format('dd');
  }
  static getDay(date: Date) {
    return moment(date).format('D');
  }
  static toYear(date: Date) {
    return moment(date).format('YYYY');
  }

  static getDayNumber(date: Date) {
    return Number(moment(date).format('D'));
  }

  static studentObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        id: this.toNull(item.StudentId),
        puc_id: this.toNull(item.puc_id),
        serial_id: this.toNull(item.serial_id),
        PUCCODE: this.toNull(item.PUCCODE),
        first_name: this.toNull(item.first_name),
        last_name: this.toNull(item.last_name),
        full_name: this.toNull(item.full_name),
        mobile_phone: this.toNull(item.mobile_phone),
        email_address: this.toNull(item.email_address),
        email: this.toNull(item.email),
        gender: this.toNull(item.gender),
      };
    } else return null;
  }

  static shiftObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: this.toNull(item.key),
        id: this.toNull(item.id),
        duration: this.toNull(item.duration),
        paymentRate: this.toNull(item.paymentRate),
        name: this.toNull(item.name),
        order: this.toNull(item.order),
      }
    } else return null;
  }

  static sessionTimeObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        shortName: this.toNull(item.shortName),
        shift: {
          key: item.shift.key,
          name: item.shift.name,
          id: item.shift.id,
        }
      };
    } else return null;
  }

  static feeObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: this.toNull(item.key),
        code: this.toNull(item.code),
        is_academic: this.toNull(item.is_academic),
        foreigner: this.toNull(item.foreigner),
        name: this.toNull(item.name),
        page_key: this.toNull(item.page_key),
        price: this.toNull(item.price),
        program: this.toNull(item.program),
        program_type: this.toNull(item.program_type),
        priority: this.toNull(item.priority),
      }
    } else return null;
  }

  static batchObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        level: this.toNull(item.level),
        shortName: this.toNull(item.shortName),
      }
    } else return null;
  }

  static campusObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        shortName: this.toNull(item.shortName),
      };
    } else return null;
  }

  static schoolObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        khName: this.toNull(item.khName),
        shortName: this.toNull(item.shortName),
        order: this.toNull(item.order),
      };
    } else return null;
  }

  static userObj(item: any) {
    return {
      key: this.toNull(item.key),
      name: this.toNull(item.name),
      displayName: this.toNull(item.displayName),
      email: this.toNull(item.email),
      role: this.toNull(item.role),
      campus: this.campusObj(item.campus),
      uid: this.toNull(item.uid),
    };
  }
  static generationObj(item: any) {
    return {
      key: item.key,
      campus: this.toNull(item.campus),
      generation: this.toNull(item.generation),
      term: {
        key: item.term.key,
        name: item.term.name,
      },
    };
  }
  static employeeObj(item: any) {
    return {
      key: item.key,
      name: this.toNull(item.name),
      display_name: this.toNull(item.display_name),
      email: this.toNull(item.email),
      dob: this.toNull(item.dob),
      first_name: this.toNull(item.first_name),
      last_name: this.toNull(item.last_name),
      full_name: this.toNull(item.full_name),
    };
  }
  static dailyShiftObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        page_key: this.toNull(item.page_key),
        start_cashier: this.userObj(item.start_cashier),
        start_date: this.toNull(item.start_date),
        start_date_key: this.toNull(item.start_date_key),
      };
    } else return null;
  }
  static abcCourseObj(item: any) {
    return {
      key: item.key,
      campus: this.toNull(item.campus),
      code: this.toNull(item.code),
      endTermKey: this.toNull(item.endTermKey),
      endcourse: this.toNull(item.endcourse),
      generation: {
        key: item.generation.key,
        generation: item.generation.generation,
      },
      name: this.toNull(item.name),
      page_key: this.toNull(item.page_key),
      startDateKey: this.toNull(item.startDateKey),
      startcourse: this.toNull(item.startcourse),
      term: {
        key: item.term.key,
        name: item.term.name,
      },
    };
  }
  static calScholarship(amount, arg: any): any {
    let value = 0;
    let { percentage, cash, loan } = arg;
    const cashAmount = ConvertService.toNumber(cash);
    const percentAmount = ConvertService.toNumber(percentage);
    const loanAmount = ConvertService.toNumber(loan);
    let balanceDue = amount;
    let totalScholarship = 0;
    let totalLoan = 0;
    if (cashAmount !== 0) {
      balanceDue = balanceDue - cashAmount;
      totalScholarship = totalScholarship + cashAmount;
    }
    if (percentAmount !== 0) {
      const totalDisP = balanceDue * percentAmount / 100;
      totalScholarship = totalScholarship + totalDisP;
    }
    if (loanAmount !== 0) {
      const totalDisL = balanceDue * loanAmount / 100;
      totalLoan = totalDisL;
    }
    const totalDis = totalScholarship + totalLoan;
    balanceDue = balanceDue - totalDis;

    value = balanceDue;
    const result = {
      totalLoan: totalLoan,
      totalScholarship: totalScholarship,
      balanceDue: value,
      deductAmount: totalDis
    }
    return result;
  }

  static otherFeeObj(item: any) {
    const obj = this.toNull(item)
    if (obj) {
      return {
        key: item.key,
        name: this.toNull(item.name),
        isPeriod: this.toNull(item.isPeriod),
        price: this.toNull(item.price),
        period: this.toNull(item.period),
        otherFee: this.toNull(item.otherFee),
      };
    } else return null;
  }
  static orderBy(data: Array<any>, field: string) {
    return _.orderBy(data, [field], ['asc'])
  }

  static sum(data: Array<any>, field: string) {
    return data.reduce((a, b) => a + Number(b[field]), 0)
  }

  static orderByDesc(data: Array<any>, field: string) {
    return _.orderBy(data, [field], ['desc'])
  }

  static groupByDesc(data: Array<any>, field: string, filedOrderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [filedOrderBy], ['desc'])
  }

  static filter(data: Array<any>, field: string, keyword: any) {
    return _.filter(data, [field, keyword])
  }

  static search(item: Observable<string>) {
    return item.debounceTime(400).distinctUntilChanged()
  }

  static groupBy(data: Array<any>, field: string, orderBy: string) {
    const rows = _.uniqBy(data, field)
    return _.orderBy(rows, [orderBy])
  }

  static groupByYear(data: Array<any>, field) {
    const list = data.map(m => {
      return moment(m[field].toDate()).format('YYYY');
    })
    const items = _.uniqBy(list)
    return _.orderBy(items, ['desc'])
  }


  static filterStates(data: any, value: any, type: any): any[] {
    if (!data) return;
    if (value && value.key) return data.filter(state => state.key === value.key);
    if (value && value.id) return data.filter(state => state.id === value.id);
    switch (type) {
      case "nationality":
        return data.filter(state => state.nationality.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "code":
        return data.filter(state => state.code.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "name":
        return data.filter(state => state.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "keyword":
        return data.filter(state => state.keyword.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "subject.name_en":
        return data.filter(state => state.subject.name_en.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "full_name":
        return data.filter(state => state.full_name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "subject.name":
        return data.filter(state => state.subject.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "schedule_subject.subject.name":
        return data.filter(state => state.schedule_subject.subject.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "schedule_subject.subject.name":
        return data.filter(state => state.schedule_subject.subject.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      case "gradeSheet.name":
        return data.filter(state => state.gradeSheet.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
      default:
        return data.filter(state => state[type] && state[type].toLowerCase().indexOf(value.toLowerCase()) > -1);
    }
  }

  static getItemByKey(list: Array<any>, key) {
    const data = list.filter(m => m.key === key);
    return data.length > 0 ? data[0] : null;
  }

  static autoComplete(stateCtrl: AbstractControl, data: any, type) {
    return stateCtrl.valueChanges.pipe(startWith(''),
      map(state => state ? this.filterStates(data, stateCtrl.value, type) : data.slice())
    );
  }

  public static validSelected(control: AbstractControl): { [s: string]: boolean } {
    const value = control.value;
    if (value !== undefined && value !== null && value !== '') {
      if (!value.key && !value.id) {
        return { validKey: true }
      }
    }
  }

  public static toNull(value) {
    if (value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static age18() {
    return moment().add(-18, 'year').toDate();
  }

}

export function getDayName(date) {
  const day = moment(date).format('dddd').toLowerCase();
  return day;
}

export function getShortDayName(date) {
  const day = moment(date).format('ddd').toLowerCase();
  return day;
}

export const createKeywords = (value: string) => {
  const name = value?.toString();
  const arrName: any[] = [];
  let nextLetter = ''
  if (!name) return [];
  name.trim().split(/[ .\-_\s ]/).forEach(letter => {
    let nextWord = ''
    return (letter.split('').forEach(word => {
      nextWord += word;
      arrName.push(toCapitalize(nextWord));
    }))
  })
  name.trim().split("").forEach(word => {
    nextLetter += word;
    arrName.push(toCapitalize(nextLetter));
  })
  return arrName
}

export const generateKeywords = (names: string[]) => {
  const keywordName = _.flattenDeep(_.map(names, (m) => createKeywords(m))).filter(a => a)
  return [
    ...new Set([
      '~N/A~',
      ...keywordName
    ])
  ];
}

export function getAdminUser(user: any) {
  let isAdmin = false;
  const { email } = user;
  if (email === "sitha.b@puc.edu.kh" || email === "payroll@puc.edu.kh") isAdmin = true;
  return isAdmin;
}

export function toCapitalize(value) {
  let string = null;
  if (value) string = value.toUpperCase().toString().trim();
  return string;
}


export function toCapitalizeText(value) {
  return value[0].toUpperCase() + value.slice(1);
}

export function pushToArray(snapshot: firestore.QuerySnapshot): any[] {
  if (snapshot.empty) return [];
  return snapshot.docs.map(m => ({ ...m.data() }));
}

export function pushToObject(snapshot: firestore.DocumentSnapshot): any {
  if (!snapshot.exists) return null;
  return { ...snapshot.data() }
}

export function pushObjArray(data: Array<any>, newItem: any) {
  let value = [];
  if (data && data.length > 0) {
    value = data;
    const exist = value.filter(m => m.key === newItem.key);
    if (exist && exist.length > 0) {
      const index = value.findIndex((obj => obj.key == newItem.key));
      value[index] = newItem;
    } else {
      value.push(newItem);
    }
  } else {
    value.push(newItem);
  }

  return value;
}

export function pushStringArray(data: Array<any>, key: string) {
  let value = [];
  if (data && data.length > 0) {
    value = data;
    const exist = value.filter(m => m === key);
    if (exist && exist.length > 0) {
      const index = value.findIndex((obj => obj == key));
      value[index] = key;
    } else {
      value.push(key);
    }
  } else {
    value.push(key);
  }

  return value;
}

export function removeObjArray(data: Array<any>, key: string): any {
  let value = [];
  if (data && data.length > 0) {
    value = data.filter(m => m.key !== key);
  }
  return value;
}

export function removeStingArray(data: Array<any>, key: string): any {
  let value = [];
  if (data && data.length > 0) {
    value = data.filter(m => m !== key);
  }
  return value;
}

export function toNull(value) {
  if (value === "" || value === undefined) {
    return null;
  }
  return value;
}

export function toNumber(value) {
  if (value === null || value === "" || value === undefined) {
    return 0;
  }
  if (Number.isNaN(Number(value))) return 0;
  return Number(value);
}

export function getInvoiceNo(index: number) {
  const number = moment().format('YYMMDD').toString() + (index + 1).toString();
  return Number(number);
}

export function geoPoint(lat, long) {
  return new firebase.firestore.GeoPoint(lat, long)
}


//NEW OBJECT

export function shiftObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
    };
  } else return null;
}

export function feeObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      code: toNull(item.code),
      is_academic: toNull(item.is_academic),
      name: toNull(item.name),
      price: toNull(item.price),
      foreigner: toNull(item.foreigner),
      program: toNull(item.program),
      program_type: toNull(item.program_type),
    }
  } else return null;
}

export function gradeObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      displayName: toNull(item.displayName),
      order: toNull(item.order),
      program: toNull(item.program),
      type: toNull(item.type),
    }
  } else return null;
}

export function programObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      khName: toNull(item.khName),
      programOption: toNull(item.programOption),
    }
  } else return null;
}

export function academicYearShortOj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      code: toNull(item.code),
      endPayment: toNull(item.endPayment),
      endPaymentKey: toNull(item.endPaymentKey),
      startDate: toNull(item.startDate),
      startDateKey: toNull(item.startDateKey),
    }
  } else return null;
}


export function academicTrainingFeeObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      order: toNull(item.order),
      grade: gradeObj(item.grade),
      gradeNext: gradeObj(item.gradeNext),
      program: toNull(item.program),
      period: toNull(item.period),
    }
  } else return null;
}

export function shortUserObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      // campus: campusObj(item.campus),
      name: toNull(item.name),
      displayName: toNull(item.displayName),
      email: toNull(item.email),
      // role: toNull(item.role),
    };
  } else return null;
}

export function userObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      campus: campusObj(item.campus),
      name: toNull(item.name),
      displayName: toNull(item.displayName),
      email: toNull(item.email),
      role: toNull(item.role),
    };
  } else return null;
}

export function authorObj(item: Partial<IAuthor>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      first_name: item.first_name,
      last_name: item.last_name,
      name: item.name,
    };
  } else return null;
}

export function publisherObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      description: item.description,
    };
  } else return null;
}
export function genresObj(item: Partial<IGenres>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      description: item.description,
      nameKh: item.nameKh,
      descriptionKh: item.descriptionKh,
    };
  } else return null;
}

export function sectionObj(item: Partial<ISection>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      description: item.description,
      nameKh: item.nameKh,
      descriptionKh: item.descriptionKh,
    };
  } else return null;
}


export function bookSourceObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      description: item.description,
    };
  } else return null;
}
export function typeAssignmentObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      name_en: item.name_en,
      description: item.description,
    };
  } else return null;
}

export function printingPlaceObj(item: Partial<IPrintingPlace>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      nameKh: item.nameKh,
      description: item.description,
    };
  } else return null;
}

export function typeMedicinceObj(item: Partial<ITypeOfMedicine>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item?.key,
      name: item?.name,
      nameKh: item?.nameKh,
    };
  } else return null;
}

export function typeDiseaseObj(item: Partial<ITypeOfDisease>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item?.key,
      name: item?.name,
      nameKh: item?.nameKh,
    };
  } else return null;
}




export function authorRoleObj(item: Partial<IAuthorRole>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      description: item.description,
      name: item.name,
    };
  } else return null;
}


export function deweyObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      nameKh: item.nameKh,
      code: item.code,
      codeKh: toNull(item.codeKh),

    };
  } else return null;
}

export function bookShelfdeweyObj(item: Partial<IBookShelf>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      nameKh: item.nameKh,
      code: item.code,
      bookcase: item?.bookcase || null,
    };
  } else return null;
}

export function discountVoucherObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      discount_type: toNull(item.discount_type),
      amount: toNull(item.amount),
      expired_date: toNull(item.expired_date),
      expired_date_key: toNull(item.expired_date_key),
      total_voucher: toNull(item.total_voucher),
      start_voucher: toNull(item.start_voucher),
      note: toNull(item.note),
    };
  } else return null;
}

export function scholarshipApprovalObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      title: toNull(item.title),
    };
  } else return null;
}

export function studentObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      id: toNull(item.StudentId),
      puc_id: toNull(item.puc_id),
      serial_id: toNull(item.serial_id),
      PUCCODE: toNull(item.PUCCODE),
      first_name: toNull(item.first_name),
      last_name: toNull(item.last_name),
      full_name: toNull(item.full_name),
      mobile_phone: toNull(item.mobile_phone),
      email_address: toNull(item.email_address),
      email: toNull(item.email),
      gender: toNull(item.gender),
    };
  } else return null;
}

export function academicYearObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      code: toNull(item.code),
      dropClass: toNull(item.dropClass),
      dropClassKey: toNull(item.dropClassKey),
      endDate: toNull(item.endDate),
      endDateKey: toNull(item.endDateKey),
      endEnroll: toNull(item.endEnroll),
      endEnrollKey: toNull(item.endEnrollKey),
      endPayment: toNull(item.endPayment),
      endPaymentKey: toNull(item.endPaymentKey),
      startDate: toNull(item.startDate),
      startDateKey: toNull(item.startDateKey),
      startEnroll: toNull(item.startEnroll),
      startEnrollKey: toNull(item.startEnrollKey),
      termType: toNull(item.termType),
      academic_year: toNull(item.academic_year),
      program_term: toNull(item.program_term),
    }
  } else return null;
}

export function campusObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      khName: toNull(item.khName),
      order: toNull(item.order)
    };
  } else return null;
}
export function schoolObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      khName: toNull(item.khName)
    };
  } else return null;
}
export function gradeLibraryObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: toNull(item?.program_academic?.key),
      name: toNull(item.program_academic?.name),
    };
  } else return null;
}
export function trainingFeeObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      amount: toNull(item.amount),
      fee: toNull(item.fee),
      paymentOption: toNull(item.paymentOption),
    };
  } else return null;
}

export function subjectObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name_kh),
      name_kh: toNull(item.name_kh),
      name_en: toNull(item.name_en),
      short_name: toNull(item.short_name),
    }
  } else return null;
}

export function trainingProgramObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      khName: toNull(item.khName),
      description: toNull(item.description),
      programOption: toNull(item.programOption),
    }
  } else return null;
}

export function trainingLevelObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      khName: toNull(item.khName),
      description: toNull(item.description),
      order: toNull(item.order),
      shortName: toNull(item.shortName),
      educationLevel: {
        key: item.educationLevel.key,
        name: item.educationLevel.name,
        order: item.educationLevel.order,
      },
    }
  } else return null;
}

export function feeCategoryObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
    }
  } else return null;
}

export function paymentOptionObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      order: toNull(item.order),
      period: toNull(item.period),
    }
  } else return null;
}

export function academicTrainingProgramObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      khName: toNull(item.khName),
      programOption: toNull(item.programOption),
    }
  } else return null;
}
export function sessionObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      days: toNull(item.days),
      shift: shiftObj(item.shift),
      fromHours: toNull(item.fromHours),
      fromHoursNumber: toNull(item.fromHoursNumber),
      toHours: toNull(item.toHours),
      toHoursNumber: toNull(item.toHoursNumber),
    }
  } else return null;
}
export function classroomObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      building: toNull(item.building),
      campus: toNull(item.campus),
      floor: toNull(item.floor),
      roomType: toNull(item.roomType),
      max_student: toNull(item.max_student),
      min_student: toNull(item.min_student),
    }
  } else return null;
}
export function instructorObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      code: toNull(item.code),
      display_name: toNull(item.display_name),
      email: toNull(item.email),
      first_name: toNull(item.first_name),
      last_name: toNull(item.last_name),
      full_name: toNull(item.full_name),
      gender: toNull(item.gender),
      khmer_first_name: toNull(item.khmer_first_name),
      khmer_last_name: toNull(item.khmer_last_name),
      khmer_full_name: toNull(item.khmer_full_name),
      mobile_phone: toNull(item.mobile_phone),
    }
  } else return null;
}
export function subjectScoreObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      subject: toNull(item.subject),
      grade: toNull(item.grade),
      for_exam: toNull(item.for_exam),
      for_month: toNull(item.for_month),
      for_semester: toNull(item.for_semester),
      max_score: toNull(item.max_score),
      multiple: toNull(item.multiple),
      pos: toNull(item.pos),
    }
  } else return null;
}

export function scholarshipObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item.name),
      code: toNull(item.code),
    }
  } else return null;
}

export function addDays(date: Date, numberOfDays: number) {
  return moment(date).add(numberOfDays, 'days').toDate()
}



export function fileSize(size: any) {
  if (size) {
    const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
    let j = 0;
    while (size > 900) { size /= 1024; j++; };
    const exactSize = (Math.round(size * 100) / 100) + ' ' + fSExt[j];
    return exactSize
  }
  return null
}

export function toDateKey(date: Date) {
  return Number(moment(date).format('YYYYMMDD'))
}
export function numberToDate(date: number) {
  return moment(date, 'YYYYMMDD').toDate();
}

export function accountBalance(accountType, recordTypeKey, amount) {
  switch (accountType) {
    case 'assets':
      return recordTypeKey === 1 ? amount : amount * -1;
    case 'liabilities':
      return recordTypeKey === 1 ? amount * -1 : amount;
    case 'equity':
      return recordTypeKey === 1 ? amount * -1 : amount;
    case 'income':
      return recordTypeKey === 1 ? amount * -1 : amount;
    case 'expenses':
      return recordTypeKey === 1 ? amount : amount * -1;
    default:
      break;
  }
}

export function chartOfAccountObj(account) {
  return {
    key: account.key,
    name: account.name,
    accountType: account.accountType,
    parent: account.parent,
    path: account.path,
    level: account.level,
    node: account.node || null,
    order: account.order || null,
  }
}

export function getCurrentMonthName() {
  return moment().format('MMMM').toLowerCase();
}


export function getYoutubeId(youtubeUrl: any) {
  // const url = validURL(youtubeUrl);
  // if(!url) return;
  if (!youtubeUrl) return null;
  let video_id = `${youtubeUrl}`.split('v=')[1];
  if (!video_id) return
  let ampersandPosition = video_id.indexOf('&');
  if (ampersandPosition != -1) {
    video_id = video_id.substring(0, ampersandPosition);
  }
  return video_id;
}

export function orderBy(rows: Array<any>, field: string, ascDesc = "asc") {
  return _.orderBy(rows, [field], ascDesc)
}

export function orderByDesc(rows: Array<any>, field: string, ascDesc = "desc") {
  return _.orderBy(rows, [field], ascDesc)
}


export function groupBy(data: Array<any>, field: string, filedOrderBy: string) {
  const rows = _.uniqBy(data, field)
  return _.orderBy(rows, [filedOrderBy])
}

export function stringToKey(name: any) {
  return `${`${name}`.trim()}`.toLowerCase().replace(/ /g, "_")
}

export function yearKey() {
  return Number(moment().format('YYYY'))
}
export class KeyOnly {
  constructor(
    readonly key: string,
    readonly student: any,

  ) { }

  toString(): string {
    return null
  }
}

export const KeyOnlyConverter = {
  toFirestore(stock: KeyOnly): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      student: stock.student,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): KeyOnly {
    const data = snapshot.data(options)!;
    return new KeyOnly(
      data.key,
      data.student
    );
  }

};


export class getSchedule {
  constructor(
    readonly key: string,
    readonly instructor: any,
    readonly schedule_subject: any,
  ) { }

  toString(): string {
    return null
  }
}
export const getScheduleConverter = {
  toFirestore(stock: getSchedule): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      instructor: stock.instructor,
      schedule_subject: stock.schedule_subject,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): getSchedule {
    const data = snapshot.data(options)!;
    return new getSchedule(
      data.key,
      data.instructor,
      data.schedule_subject,
    );
  }

};

export class cSchedule {
  constructor(
    readonly key: string,
    readonly schedule_subject: any,
    readonly subjectKey: any,
    readonly scheduleKey: any,
    readonly studentKey: any,
    readonly gradingScale: any,

  ) { }

  toString(): string {
    return null
  }
}

export const cScheduleConverter = {
  toFirestore(stock: cSchedule): firebase.firestore.DocumentData {
    return {
      key: stock.key,
      schedule_subject: stock.schedule_subject,
      subjectKey: stock.subjectKey,
      scheduleKey: stock.scheduleKey,
      studentKey: stock.studentKey,
      gradingScale: stock.gradingScale,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): cSchedule {
    const data = snapshot.data(options)!;
    return new cSchedule(
      data.key,
      data.schedule_subject,
      data.subjectKey,
      data.scheduleKey,
      data.studentKey,
      data.gradingScale,
    );
  }
}

export function getDuration(fromDate: any, toDate: any) {
  const fromMoment = moment(fromDate?.toDate());
  const toMoment = moment(toDate?.toDate());
  const duration = moment.duration(toMoment.diff(fromMoment));
  return parseInt(`${duration.asDays().toFixed(0)}`);
}



export function getCurrentYear(currentDate: Date = new Date()) {
  return currentDate.getFullYear();
}

export function getYears(fromYear: number, toYear?: number) {
  const years = [];
  const currentYear = toYear ? toYear : getCurrentYear();
  for (let index = 0; index <= currentYear - fromYear; index++) {
    const val = fromYear + index;
    years.push({ key: val, value: val });
  }
  return years;
}

export function uniqArray(array: any) {
  return _.uniqBy(array, (item: any) => {
    return item.key;
  });
}

export function uniqArrayByField(array: any, field: string) {
  return _.uniqBy(array, field)
}

export function groupByData(data: Array<any>, field: string, fields?: string[] | string) {
  const rows = _.chain(data).groupBy(fields ? fields : field).map((value, key) => ({ [field]: value[0][field], data: value })).value()
  return rows
}

export function toStudent(item: any) {
  const { key, khmer_first_name, khmer_last_name, mobile_phone, last_name, first_name, full_name, fileUrl, fileName, puc_id, gender } = item;
  return {
    key,
    khmer_first_name: khmer_first_name || null,
    khmer_last_name: khmer_last_name || null,
    mobile_phone: mobile_phone || null,
    last_name: last_name || null,
    first_name: first_name || null,
    full_name: full_name || null,
    fileUrl: fileUrl || null,
    fileName: fileName || null,
    puc_id: puc_id || null,
    gender: gender || null,

  }
}
export function toStudentNew(item: any) {
  const { key, khmer_first_name, khmer_last_name, mobile_phone, last_name, first_name, full_name, fileUrl, fileName, puc_id, gender, program_academic
  } = item;
  return {
    key,
    khmer_first_name: khmer_first_name || null,
    khmer_last_name: khmer_last_name || null,
    mobile_phone: mobile_phone || null,
    last_name: last_name || null,
    first_name: first_name || null,
    full_name: full_name || null,
    fileUrl: fileUrl || null,
    fileName: fileName || null,
    puc_id: puc_id || null,
    gender: gender || null,
    studentGrade: program_academic.name || null,
    gradeKey: program_academic.key || null
  }
}

export function toInstructor(item: any) {
  const { key, khmer_first_name, khmer_last_name, mobile_phone, last_name, first_name, full_name, fileUrl, fileName, code, gender } = item;
  return {
    key,
    khmer_first_name: khmer_first_name || null,
    khmer_last_name: khmer_last_name || null,
    mobile_phone: mobile_phone || null,
    last_name: last_name || null,
    first_name: first_name || null,
    full_name: full_name || null,
    fileUrl: fileUrl || null,
    fileName: fileName || null,
    code: code || null,
    gender: gender || null,
  }
}
export function delayFn(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export function bookObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      code: item.code,
      name: item.name,
      genres: item.genres,
      bookType: item.bookType
    };
  } else return null;
}


export function addDayToCurrentDate(currentDate: Date, day: number) {
  console.log('currentDate', currentDate);
  return new Date(currentDate.setDate(currentDate.getDate() + day));
}

export function atLeastOneFieldRequiredValidator(): any {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const values = Object.values(formGroup.controls);
    const hasValue = values.some(control => control.value !== null && control.value !== '');

    return hasValue ? null : { requireAtLeastOne: true };
  };
}



export function toBookObject(item: Partial<IBook>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: toNull(item?.name),
      authorName: toNull(item?.author?.name),
      bookDcc: toNull(item?.bookDcc),
      file: toNull(item?.bookFileDetail?.downloadURL),
      cover: toNull(item?.coverFileDetail?.downloadURL),
      description: toNull(item?.description),
    }
  } else return null;
}
export function toDeviceObject(item: Partial<IDevice>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      deviceName: toNull(item?.deviceName),
    }
  } else return null;
}

export function toSubjectBookObject(item: Partial<IBasicTextbookInventory>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      grade: toNull(item?.grade),
      subject: toNull(item?.subject),
    };
  } else return null;
}

export function tobookCaseTypeObj(item: any) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      nameKh: item.nameKh,
    };
  } else return null;
}

export function tobookCaseObj(item: Partial<IBookcase>) {
  const obj = toNull(item)
  if (obj) {
    return {
      key: item.key,
      name: item.name,
      nameKh: item.nameKh,
      bookcaseType: item.bookcaseType,
    };
  } else return null;
}

export function assets(...path: string[]): string {
  return (
    location.protocol + '//' + location.host + '/assets/' + path?.join('/')
  );
}


export function startDate(date: Date, type: any) {
  return moment(date).startOf(type).toDate();
}
export function endDate(date: Date, type: any) {
  return moment(date).endOf(type).toDate();
}



export function calculatePercentageChange(oldValue, newValue) {
  const percentageChange = ((oldValue - newValue) / oldValue) * 100;
  return percentageChange;
}


export function getLastWeekDates() {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getTime() - 6 * 24 * 60 * 60 * 1000); // Start date is 7 days ago
  const datesArray = []; // Array to hold the dates

  for (let i = 0; i <= 6; i++) { // Loop through 7 days
    const date = new Date(startDate.getTime() + i * 24 * 60 * 60 * 1000); // Calculate each day
    datesArray.push(date);
  }
  const mapDateArray = datesArray.map(m => {
    return {
      key: toDateKey(m),
      date: m,
      dayName: getShortDayName(m),
    }
  })
  return mapDateArray;
}
