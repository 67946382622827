import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getStudentSchedule'
})
export class GetStudentSchedulePipe implements PipeTransform {
  transform(schedules: Array<any>, sessionName: string): any {

    let sessionItemData = [];
    if (schedules && schedules.length > 0) {
      schedules.map(m => {

        if (m.sessionItems && m.sessionItems.length) {
          const sessionItemDoc = m.sessionItems.map(s => {
            return {
              ...s,
              scheduleKey: m.key
            }
          })
          sessionItemData = sessionItemData.concat(sessionItemDoc)
        }
      })

      const validSchedule = sessionItemData.find(m => m.name === sessionName)
      if (validSchedule) {
        const schedule = schedules.find(m => m.key === validSchedule.scheduleKey)
        return schedule.schedule_subject.subject.name
      }
    } else return 'N/A'
  }
}
