import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date, args?: any): any {
    return moment(value).format('dddd, MMM do YYYY');
  }

}


@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  transform(dateString: string): string {
    if (!dateString) return '';

    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  }
}

@Pipe({
  name: 'khmerNumber'
})
export class KhmerNumberPipe implements PipeTransform {
  private arabicToKhmerMap = {
    '0': '០',
    '1': '១',
    '2': '២',
    '3': '៣',
    '4': '៤',
    '5': '៥',
    '6': '៦',
    '7': '៧',
    '8': '៨',
    '9': '៩'
  };
  transform(value: number | string | null | undefined): string {
    if (value === null || value === undefined) {
      return '';
    }
    let valueStr = value.toString();
    let result = '';
    for (let char of valueStr) {
      result += this.arabicToKhmerMap[char] || char;
    }
    return result;
  }
}