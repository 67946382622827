import { SCHOOL_CONFIG } from "src/environments/schoolConfig";

export const STORE_CONFIG = {
  COMPANY_KEY: 'profile'
}
export const SCHOOL = SCHOOL_CONFIG


export const LEVEL_OF_MANAGEMENT = [
  { key: 1, text: "Management" },
  { key: 2, text: "Schools" },
  { key: 3, text: "University" },
];

export const LEVEL_OF_MANAGEMENT_OBJECT = {
  management: { key: 1, text: "Management" },
  schools: { key: 2, text: "schools" },
  university: { key: 3, text: "University" },
};
export const PRODUCT_TYPE_OPTIONS = [
  { key: 1, router: 'inventory', name: 'Inventory' },
  { key: 0, router: 'non-inventory', name: 'Non-Inventory' },
  { key: 3, router: 'bundle', name: 'bundle' },
]

export const FILTER_OPTIONS = [
  { key: "code", text: "ID" },
  { key: "full_name", text: "Full name" },
  { key: "first_name", text: "First name" },
  { key: "last_name", text: "Last name" },
  { key: "mobile_phone", text: "Phone number" }
];

export const FILTER_USER = [
  { key: "email", text: "Email" },
  { key: "full_name", text: "Full name" },
  { key: "first_name", text: "First name" },
  { key: "last_name", text: "Last name" },
  { key: "mobile_phone", text: "Phone number" }
];
export const FILTER_OPTIONS_STUDENT = [
  { key: "code", text: "ID" },
  { key: "full_name", text: "Full name" },
];

export const APP_NAME = {
  'human-resource': { key: 'human-resource', router: 'human-resource', name: 'Human Resource' }
}

export const APP_NAME_LIST = [
  { key: 'human-resource', router: 'human-resource', name: 'Human Resource' },
  { key: 'inventory', router: 'inventory', name: 'Inventory Management' },
  { key: 'testing-center', router: 'testing-center', name: 'Testing Center' },
  { key: 'finance-report', router: 'finance-report', name: 'Account & Finance' },
  { key: 'scholarships', router: 'scholarships', name: 'Scholarships Office' },
  { key: 'registrar', router: 'registrar', name: 'Registrar Office' },
  { key: 'administrator', router: 'administrator', name: 'Administrator' },
  { key: 'analytics', router: 'analytics', name: 'Analytics' },
  { key: 'class-manager', router: 'class-manager', name: 'Class Manager' },
  { key: 'library', router: 'library', name: 'Library' },
]

export const PRODUCT_TYPE_OPTION_OBJ = {
  'inventory': { key: 1, router: 'inventory', name: 'Inventory' },
  'non-inventory': { key: 0, router: 'non-inventory', name: 'Non-Inventory' },
  'bundle': { key: 3, router: 'bundle', name: 'Bundle' },
  'services': { key: 2, router: 'services', name: 'Services' },
}

export const APP_FUNCTIONS = {

  'library': {
    key: 'library',
    routeItems: [
      // {
      //   key: 'book-store',
      //   name: 'Hospital Services',
      //   expansions: 'three',
      //   module: 'administrator_2._library',

      //   expansionIndex: 6,
      //   icon: '',
      //   remark: 'Hospital Check In Out, Type of Disease, Type of medicine. ',
      //   data: [
      //     { key: 'hospital-check-in-out', name: 'Hospital Check In Out', icon: 'how_to_reg', permission: 'administrator_2.1_book' },
      //     {
      //       key: 'type-of-disease', name: 'Type of Disease', icon: 'local_hospital', permission: 'administrator_2.1_book'
      //     },
      //     { key: 'type-of-medicine', name: 'Type of medicine', icon: 'healing', permission: 'administrator_2.1_book' },
      //   ]
      // },

      // {
      //   key: 'book-store',
      //   name: 'Reports',
      //   expansions: 'three',
      //   module: 'administrator_2.reports',

      //   expansionIndex: 4,
      //   icon: '',
      //   remark: 'Report Check In Out Hospital. ',
      //   data: [
      //     { key: 'hospital-report', name: 'Reports', icon: 'book', permission: 'administrator_reports' },
      //   ]
      // },

      {
        key: 'book-store',
        name: 'Library Services',
        expansions: 'three',
        module: 'administrator_2._library',

        expansionIndex: 3,
        icon: '',
        remark: 'Books Search, Borrow Books, Return Books, Daily Check In Out. ',
        data: [
          { key: 'books-search', name: 'Books Search', icon: 'search', permission: 'administrator_2.1_book' },
          { key: 'books-borrow', name: 'Borrow Items', icon: 'book_2', permission: 'administrator_2.1_book' },
          { key: 'books-return', name: 'Return Items', icon: 'book_2', permission: 'administrator_2.1_book' },
          { key: 'daily-check-in-out', name: 'Daily Check In Out', icon: 'how_to_reg', permission: 'administrator_2.1_book' },

        ]
      },
      {
        key: 'book-store',
        name: 'Library Cataloging',
        expansions: 'three',
        module: 'administrator_2.reports',

        expansionIndex: 4,
        icon: '',
        remark: 'Inventory, Books, Devices.',
        data: [
          { key: 'inventory', name: 'Inventory', icon: 'inventory', permission: 'administrator_2.1_book' },
          { key: 'books', name: 'Books', icon: 'library_books', permission: 'administrator_2.1_book' },
          { key: 'devices', name: 'Devices', icon: 'devices', permission: 'administrator_2.1_book' },
        ]
      },

      // {
      //   key: 'book-store',
      //   name: 'Booking Library Room',
      //   expansions: 'three',
      //   module: 'administrator_3.Bookings',

      //   expansionIndex: 4,
      //   icon: '',
      //   remark: 'Confirm Booking Room. ',
      //   data: [
      //     { key: 'booking-library-room', name: 'Confirm Booking Room', icon: 'meeting_room', permission: 'administrator_3.1_bookings' },

      //   ]
      // },

      {
        key: 'book-store',
        name: 'Classification',
        expansions: 'three',
        module: 'administrator_2.reports',

        expansionIndex: 4,
        icon: '',
        remark: 'Author, Publishers, Sections, Shelf, Dewey Classification. ',
        data: [
          { key: 'author', name: 'Author', icon: 'supervisor_account', permission: 'administrator_2.4_author' },
          { key: 'author-role', name: 'Author Role', icon: 'supervisor_account', permission: 'administrator_2.4_author' },
          { key: 'printing-places', name: 'Printing Places', icon: 'pin_drop', permission: 'administrator_2.7_printing_places' },
          { key: 'publisher', name: 'Publisher', icon: 'public', permission: 'administrator_2.5_publisher' },
          { key: 'shelf', name: 'Shelf', icon: 'business', permission: 'administrator_2.5_publisher' },
          { key: 'bookcases', name: 'Bookcases', icon: 'business', permission: 'administrator_2.6_bookcases' },
          { key: 'bookcase-types', name: 'Bookcase Type', icon: 'business', permission: 'administrator_2.6_bookcases' },
          { key: 'dewey', name: 'Dewey Classification', icon: 'looks_one', permission: '' },
          { key: 'genres', name: 'Genres', icon: 'sort_by_alpha', permission: 'administrator_2.3_genres' },
          { key: 'sections', name: 'Sections', icon: 'supervisor_account', permission: 'administrator_2.2_sections' },
          { key: 'book-sources', name: 'Book Sources', icon: 'supervisor_account', permission: 'administrator_2.2_sections' },
          { key: 'library-type-assignment', name: 'Type Assignment of Library', icon: 'bookmarks', permission: 'administrator_2.2_sections' },
        ]
      },
      {
        key: 'book-store',
        name: 'Reports',
        expansions: 'three',
        module: 'administrator_2.reports',

        expansionIndex: 4,
        icon: '',
        remark: 'Borrow Book listing, Report Check In Out. ',
        data: [

          { key: 'borrow-book-listing', name: 'Borrow Book listing', icon: 'supervisor_account', permission: 'administrator_2.2_listings' },
          { key: 'overdue-item-listing', name: 'Overdue Item Listing', icon: 'supervisor_account', permission: 'administrator_2.2_listings' },
          { key: 'report-check-in-out', name: 'Report Check In Out', icon: 'assignment_turned_in', permission: 'administrator_2.1_book' },
          { key: 'report', name: 'Reports', icon: 'book', permission: 'administrator_reports' },
        ]
      },
      {
        key: 'book-store',
        name: 'Account & Data',
        expansions: 'three',
        module: 'administrator_2.reports',

        expansionIndex: 4,
        icon: '',
        remark: 'Guest Card, Reservation Session. ',
        data: [

          { key: 'guestcard', name: 'Guest Card', icon: 'book', permission: 'administrator_2.2_listings' },
          // { key: 'reservation-session', name: 'Reservation Session', icon: 'schedule', permission: 'administrator_2.2_listings' },
        ]
      },

    ]
  },

}
