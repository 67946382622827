import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'permissionModuleFunction'
})
export class PermissionModuleFunctionPipe implements PipeTransform {
  transform(data: Array<any>, args: string): any {
    let result = false;
    let permission:[];
    if (data && data.length > 0)
      data.forEach(e => {
        if(e.options && e.options.length > 0){
        }
          // const studentEnrollmentData = pushToArray(e);

          // permission = e.push
          // result = e.options.filter(m => m.key === args).length > 0 ? true : false;
      });
    return result;
  }
}
