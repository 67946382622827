import { Pipe, PipeTransform } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { groupBy, MappingService, pushObjArray, pushToArray, pushToObject, toDateKey } from '../services/mapping.service';

@Pipe({
  name: 'sumReport'
})
export class SumReportPipe implements PipeTransform {

  transform(value: any, data?: Array<any>, key?: any, sumField?: any): any {
    if (!data || data.length == 0) return 0;
    const filter = data.filter(m => m.invoice_type.key === key);
    const total = MappingService.sum(filter.slice(), sumField);
    return total;
  }

}


@Pipe({
  name: 'sumAttendence'
})
export class SumAttendencePipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(schoolKey: string, batchKey: string, date: any) {
    const dateKey = toDateKey(date);
    const att = pushToObject(await this.afs.collection('stores').doc(schoolKey).collection('sys_option').doc('general').get().toPromise());
    const data = pushToArray(await this.afs.collection('stores').doc(schoolKey).collection('student_attendance_movement', ref => ref.where('batchKey', '==', batchKey).where('checkIn_date_key', '==', dateKey).where('remark.key', '==', att['attendanceA'].key)).get().toPromise());
    const gdata = groupBy(data, 'studentKey', 'studentKey')
    return att['attendanceA'].kh_name + ' ' + gdata?.length + ' នាក់';
  }

}

@Pipe({
  name: 'attendenceByStudet'
})
export class attendenceByStudetPipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(studentKey: string, studentAttendence: any) {
    if (studentAttendence && studentAttendence.length > 0) {
      const data = studentAttendence.filter(f => f.studentKey === studentKey);
      // if (data) {
      //   return data.remark.kh_name
      // }
    }
    return null;
  }

}


@Pipe({
  name: 'studentAttendence'
})
export class StudentAttendencePipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(studentKey: string, date: any, shift: string) {
    const dateKey = toDateKey(date);
    const data = pushToArray(await this.afs.collection('students').doc(studentKey).collection('time_attendance', ref => ref.where('scan_date_key', '==', dateKey)).get().toPromise());
    let gdata = null
    if (shift === 'morning') {
      gdata = data.filter(f => f.policy_hour.fromHour < 12)[0]
    } else if (shift === 'afternoon') {
      gdata = data.filter(f => f.policy_hour.fromHour >= 12 && f.policy_hour.fromHour <= 17)[0]
    } else if (shift === 'evening') {
      gdata = data.filter(f => f.policy_hour.fromHour > 17 && f.policy_hour.fromHour <= 24)[0]
    }
    return gdata ? gdata.remark.kh_name : null
  }

}


@Pipe({
  name: 'attendenceSubject'
})
export class attendenceSubjectPipe implements PipeTransform {
  constructor(
    private afs: AngularFirestore
  ) { }
  async transform(batchKey: string, scheduleKey: string, field: string) {
    const schedules = pushToObject(await this.afs.collection('institute_training_level_batch').doc(batchKey).collection('schedules').doc(scheduleKey).get().toPromise());
    return schedules.schedule_subject.subject.name;
  }

}
