import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { Router } from '@angular/router';
import { SCHOOL, STORE_CONFIG } from 'src/app/core/dummy/config';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from 'src/app/shared/components/change-password/change-password.component';

@Component({
  selector: 'dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss']
})
export class DashboardNavComponent implements OnInit {
  @Input() user: any;
  @Input() term: any;

  STORE_CONFIG = STORE_CONFIG;
  logo = SCHOOL.img
  constructor(
    public router: Router,
    public auth: AuthStore,
    public dialog: MatDialog,
  ) { }

  async ngOnInit() {
    await this.auth.fetchCampusNotifications();

  }

  onNotification() {
    this.auth.updateBadge()
  }

  _onScroll() {
    this.auth.fetchMoreNotification();
  }
  _signOut() {
    this.auth.signOut();
  }

  toggleSidebar() {
    let body = document.getElementsByClassName("page-wrapper")[0];
    body.classList.toggle("toggled-switch-menu");
  }

  ngOnDestroy(): void {
    const { fetchCampusNotificationsRef } = this.auth;
    fetchCampusNotificationsRef && fetchCampusNotificationsRef.unsubscribe();
  }

  async _toTestingList(item) {
    const studentData = await this.auth.fetchStudentTestingDoc(item.personKey)
    if (studentData && studentData.length > 0) {
      const studentDoc = studentData[0]
      this.router.navigate([`/testing-center/programs/${studentDoc.program.key}/all`])
    }
  }

  changePassword() {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: null,
      panelClass: 'item-list-panel',
      width: '35vw',
      height: '100vh',
      disableClose: false,
      role: 'dialog',
      autoFocus: false,
    });
    dialogRef.updatePosition({ top: '0', right: '0', bottom: '0' });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
