import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TestFeeStatus, invoiceTypesObj, enrollPrograms, paymentStatus, STATUS_OBJECT, status } from '../dummy/stauts';
import { TestOption } from '../interfaces/testing';
import * as moment from "moment";
import { Pages } from '../dummy/pages';
import { ConvertService } from 'src/app/shared/services/convert.service';
import * as firebase from 'firebase/app';
import { IChartAccount, ICompany, IModule } from '../interfaces/company';
import { SCHOOL, STORE_CONFIG } from '../dummy/config';
import { MOVEMENT_STATUS_DATA } from '../dummy/data';
import { APPS, COLLECTION_NAME } from '../dummy/app';
import { orderBy, pushToArray } from 'src/app/shared/services/mapping.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  prefix = "admin_";

  constructor(
    private db: AngularFirestore
  ) { }


  compareObjects(o1: any, o2: any): boolean {
    if (o2) return o1.key === o2.key;
  }

  publicHolidayActive(fromDate, toDate) {
    const fromDateTime = ConvertService.startFromDate(fromDate)
    const toDateTime = ConvertService.endToDate(toDate)

    return this.db.collection("public_holiday", ref => ref
      .where("from_date", ">=", fromDateTime)
      .where("from_date", "<=", toDateTime)
      .orderBy("from_date")
    );
  }

  batchLevelDocFireRef(key) {
    return this.db.firestore.collection("institute_training_level_batch").doc(key);
  }
  getGradeSheetStudentBatchMovementRef(batchKey, monthKey, gradeSheetTypeKey) {
    let ref = this.db.firestore.collection("grade_sheet_student_movements")
    let condition = ref.where("batchKey", '==', batchKey)
    if (gradeSheetTypeKey < 4) {
      condition = condition.where("gradeSheet.gradeSheetType.key", '==', gradeSheetTypeKey)
    }
    if (gradeSheetTypeKey === 4) {
      condition = condition.where("gradeSheet.gradeSheetType.key", '>', 1).orderBy('gradeSheet.gradeSheetType.key')
    }

    if (gradeSheetTypeKey === 1) {
      condition = condition.where("gradeSheet.month.key", '==', monthKey)
    }
    return condition.orderBy('result', 'desc')
  }

  getGradeSheetStudentBatchRef(batchKey, monthKey, gradeSheetTypeKey) {
    let ref = this.db.firestore.collection("grade_sheet_batches")
    let condition = ref.where("batchKey", '==', batchKey)
    if (gradeSheetTypeKey < 4) {
      condition = condition.where("gradeSheet.gradeSheetType.key", '==', gradeSheetTypeKey)
    }
    if (gradeSheetTypeKey === 4) {
      condition = condition.where("gradeSheet.gradeSheetType.key", '>', 1).orderBy('gradeSheet.gradeSheetType.key')
    }
    if (gradeSheetTypeKey === 1) {
      condition = condition.where("gradeSheet.month.key", '==', monthKey)
    }
    return condition.orderBy('result', 'desc')
  }
  groupBatchLevelRef(levelKey, shiftKey, yearKey, campusKey) {
    return this.db.firestore.collection("institute_training_level_batch").where('level.key', '==', levelKey)
      .where('shift.key', '==', shiftKey)
      .where('academicYear.key', '==', yearKey)
      .where('campus.key', '==', campusKey)
  }

  gradeSystemCriteriaRef(schoolKey, criteriaType: any, programKey: any, subjectData: any) {
    return this.storeRef(schoolKey).collection("grading_criteria_system", ref => {
      let condition = ref.orderBy("order");
      if (subjectData) {
        return condition = condition.where('subjectData.subjectKey', '==', subjectData.subjectKey)
          .where('subjectData.levelKey', '==', subjectData.levelKey)
          .where('subjectData.programKey', '==', subjectData.programKey)
      } else if (programKey) {
        return ref.where('program.key', '==', programKey)
      }
      return condition;
    })
  }

  gradeSystemCriteriaDBRef(schoolKey: string, programKey: string) {
    return this.storeRef(schoolKey).collection("grading_criteria_system", ref => ref
      .orderBy("order")
      .where('program.key', '==', programKey)
    )
  }

  groupModulesAppRef(ref?) {
    return this.db.collection('group_users_account', ref)
  }

  groupModulesRef(storeKey, ref?) {
    return this.storesCollectionRef().doc(storeKey).collection('group_users_account', ref)
  }

  groupUserAccountMemberRef(storeKey, groupKey) {
    return this.groupModulesRef(storeKey).doc(groupKey).collection('group_members', ref => ref.orderBy('full_name'));
  }

  groupModulesMovementRef(storeKey, ref?) {
    return this.storesCollectionRef().doc(storeKey).collection('group_users_account_modules', ref)
  }

  groupModulesMovementAppRef(ref?) {
    return this.db.collection('group_users_account_modules', ref)
  }

  companyModulesRef(appKey) {
    return this.db.collection('system_modules', ref => {
      let condition = ref.where('app.key', '==', appKey)
      return condition;
    })
  }


  systemModuleRef() {
    return this.db.collection<IModule>('system_modules')
  }

  companyUserRef(root, schoolKey) {
    if (root)
      return this.db.collection('company').doc<ICompany>('profile')
    else
      return this.db.collection('company').doc<ICompany>(schoolKey)
  }

  companyRef() {
    return this.db.collection('company').doc<ICompany>('profile')
  }
  schedulesByProgramRef(termKey, levelKey, shiftKey, campusKey) {
    return this.db
      .collection("academic_year")
      .doc(termKey)
      .collection("schedules", ref => ref
        .where("isHeader", "==", true)
        .where("allowEnroll", "==", true)
        .where("campus.key", "==", campusKey)
        .where("shift.key", "==", shiftKey)
        .orderBy("schedule_subject.subject.name"))
  }
  // schedulesByProgramRef(termKey, levelKey?, shiftKey,) {
  //   return this.db
  //     .collection("academic_year")
  //     .doc(termKey)
  //     .collection("schedules", ref => ref
  //       .where("isHeader", "==", true)
  //       .where("allowEnroll", "==", true)
  //       .where("academicGrade.key", "==", levelKey)
  //       .where("shift.key", "==", shiftKey)
  //       .orderBy("schedule_subject.subject.name"))
  // }

  fieldValue() {
    return firebase.firestore.FieldValue;
  }

  campusNotificationRef(schoolKey, campusKey) {
    return this.db.collection('statistic_testing').doc(`${schoolKey}_${campusKey}`)
  }

  notificationsRef(isRoot, schoolKey, campusKey, lastVisible) {
    return this.db.collection('school_notifications', ref => {
      let condition = ref.where('schoolKey', '==', schoolKey)
      if (!isRoot) {
        condition = condition.where('campusKey', '==', campusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible["created_at"];
        condition = condition.orderBy("created_at", "desc")
          .startAfter(startAfter)
      }
      else {
        condition = condition
      }
      return condition.limit(APPS.SIZE).orderBy('created_at', 'desc');
    })
  }

  gradeSheetMovementSubjectRef() {
    return this.firestore().collection('grade_sheet_subject_movements')
  }

  gradeSheetMovementRef() {
    return this.firestore().collection('grade_sheet_movements')
  }

  subjectSearchRef(keyword) {
    return this.db.collection<any>("subjects", ref =>
      ref
        .where("name", ">=", keyword)
        .orderBy("name")
        .limit(25)
    );
  }
  collectionRef(collectionName) {
    return this.db.collection(collectionName);
  }

  dataRef() {
    return this.db
  }

  collectionFireRef(collectionName) {
    return this.firestore().collection(collectionName);
  }

  batchProgramRef(campusKey, termKey, programKey, shiftKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.program.key", "==", programKey)
      .where("academicYear.key", "==", termKey)
      .where("campus.key", "==", campusKey)
      .where("shift.key", "==", shiftKey)
      .orderBy('level.order'))
  }

  generalJournalRef(lastVisible?: any) {
    return this.db.collection('general_journals', ref => {
      let condition = ref.where('closing', '==', false)
      // .where('storeKey', '==', this.storeKey)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['journalDate'])
      }
      return condition.orderBy('journalDate', 'desc').orderBy('lineNo').limit(Pages.size)
    })
  }

  pettyCashRef(lastVisible?: any, displayStatus?: any) {
    return this.storeRef('sps').collection('petty_cash', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      const movementStatus = MOVEMENT_STATUS_DATA[displayStatus];
      if (displayStatus && displayStatus !== 'all' && movementStatus) {
        condition = condition.where('movementStatus.key', '==', movementStatus.key)
      }
      return condition;
    })
  }

  fixedAssetsRef(lastVisible?: any, displayStatus?: any) {
    return this.storeRef('sps').collection('fixed_assets', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      const movementStatus = MOVEMENT_STATUS_DATA[displayStatus];
      if (displayStatus && displayStatus !== 'all' && movementStatus) {
        condition = condition.where('movementStatus.key', '==', movementStatus.key)
      }
      return condition;
    })
  }

  journalEntryRef(lastVisible?: any, displayStatus?: any) {
    return this.storeRef('sps').collection('journal_entry', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      const movementStatus = MOVEMENT_STATUS_DATA[displayStatus];
      if (displayStatus && displayStatus !== 'all' && movementStatus) {
        condition = condition.where('movementStatus.key', '==', movementStatus.key)
      }
      return condition;
    })
  }

  displayName(item: any): string {
    return item ? item.name : item;
  }

  displayFullName(item: any): string {
    return item ? item.full_name : item;
  }

  sysAccountTypesRef() {
    return this.db.collection('admin_sys_account_types', ref => ref.orderBy('order'))
  }

  academicYearRefCollection() {
    return this.db.collection("academic_year");
  }

  academicYearFireRefCollection() {
    return this.db.firestore.collection("academic_year");
  }
  collection(name) {
    return this.db.collection(name)
  }

  storesCollectionRef() {
    return this.db.collection('stores', ref => ref.orderBy('order'))
  }


  storeRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey)
  }

  storeFireRef(schoolKey) {
    return this.firestore().collection('stores').doc(schoolKey)
  }

  firestoreRef() {
    return this.db
  }
  userAccountDoc(uid: string) {
    return this.db.collection(this.prefix + "sys_accounts").doc<any>(uid);
  }

  sysAccounting() {
    return this.firestore().collection(this.prefix + "sys_account_types");
  }

  sysConfig() {
    return this.firestore().collection(this.prefix + "sys_configs");
  }

  chartAccountFireRef() {
    // return this.schoolFireRef().doc(this.storeKey).collection("chart_of_accounts");
  }

  schoolsRef() {
    return this.db.collection('schools')
  }

  subAccountByAccountTypeRef(accountTypeKey) {
    // return this.schoolRef().doc(this.storeKey).collection('chart_of_accounts', ref => {
    //   if (accountTypeKey) {
    //     return ref.where('accountType.key', '==', accountTypeKey).orderBy("order").orderBy("node")
    //   }
    // })
  }

  subAccountRef(account) {
    // return this.schoolFireRef().doc(this.storeKey).collection('chart_of_accounts').where('subAccount.key', '==', account.key)
  }

  subOfChartOfAccountRef(account) {
    // return this.schoolRef().doc(this.storeKey).collection('chart_of_accounts', ref => ref.where('subAccount.key', '==', account.key))
  }

  subAccountOfRef() {
    // return this.schoolRef().doc(this.storeKey).collection('chart_of_accounts', ref => ref.where('level', '==', 0).orderBy('index', 'desc').limit(1))
  }
  appSchoolProgramRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("school_programs", ref => ref.orderBy("page_key", "desc"));
  }
  appAnnouncementsRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_announcements", ref => ref.orderBy("page_key", "desc"));
  }
  appVideoRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_videos", ref => ref.orderBy("page_key", "desc"));
  }
  appManagementTeamRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_management_team", ref => ref.orderBy("page_key"));
  }
  appNewsRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_news", ref => ref.orderBy("page_key", "desc"));
  }

  appPartnerRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_partners", ref => ref.orderBy("page_key", "desc"));
  }
  appEventRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("apps_events", ref => ref.orderBy("page_key", "desc"));
  }
  sysAccountDetailRef(key) {
    return this.db
      .collection(this.prefix + "sys_account_types")
      .doc(key)
      .collection("details");
  }
  listRef(collectionName, orderBy, schoolKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.orderBy(orderBy).where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    })
  }
  listRefByCampus(collectionName, orderBy, schoolKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.orderBy(orderBy).where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    })
  }
  chartAccountRef() {
    return this.db.collection(this.prefix + "chart_of_accounts", ref =>
      ref.orderBy("order").orderBy("node")
    );
  }

  balanceOfAccountRef(branch: any, account: any) {
    return this.db.collection<any>(this.prefix + "ledgers", ref =>
      ref
        .where("branch.key", "==", branch.key)
        .where("order", "==", account.order)
        .where("parent", "==", account.parent)
        .where("node", ">", account.node)
        .orderBy("node")
    );
  }

  getBookByCode(code: any, schoolKey: any) {
    console.log('code', code, schoolKey);
    return this.db.collection('stores').doc(schoolKey).collection(COLLECTION_NAME.BOOK_LIBRARY, ref => ref.where('code', '==', code).limit(1))
  }
  getBookByBookNumber(code: any, schoolKey: any) {
    console.log('code', code, schoolKey);
    return this.db.collection('stores').doc(schoolKey).collection(COLLECTION_NAME.BOOK_LIBRARY_INVENTORY, ref => ref.where('bookNumber', '==', code).limit(1))
  }
  getItemByBookNumber(code: any, schoolKey: any, itemType) {
    console.log('code', code, schoolKey);
    return this.db.collection('stores').doc(schoolKey).collection(COLLECTION_NAME.BOOK_LIBRARY_INVENTORY, ref => ref
      .where('isDelete', '==', false)
      .where('bookNumber', '==', code)
      .where('itemType', '==', itemType).limit(1))
  }

  getStudentById(schoolKey: any, studentId: string) {
    return this.db.collection('students', ref => ref.where('schoolKey', '==', schoolKey).where('puc_id', '==', studentId).limit(1))
  }
  getEmployeeById(schoolKey: any, Id: string) {
    return this.db.collection('employees', ref => ref.where('schoolKey', '==', schoolKey).where('code', '==', Id).limit(1))
  }

  employeeDoc(key) {
    return this.db.collection('employees').doc<any>(key)
  }
  guestDoc(key) {
    return this.db.collection('library_guest_card').doc<any>(key)
  }

  getGuestById(schoolKey: any, Id: string) {
    return this.db.collection('library_guest_card', ref => ref.where('schoolKey', '==', schoolKey).where('code', '==', Id).limit(1))
  }




  getUserBorrowByKey(schoolKey: any, key: string) {
    return this.db.collection('stores').doc(schoolKey).collection(COLLECTION_NAME.library_borrow_items, ref => ref.where('borrower.key', '==', key).where('isReturnBook', '==', false).orderBy('created_at', 'desc'))
  }

  // getBorrowBookByUser(schoolKey: any) {
  //   return this.db.collection('stores').doc(schoolKey).collection(COLLECTION_NAME.library_borrow_items)
  // }

  getBorrowBookByUser(lastVisible: any, schoolKey, fromDate: number, toDate: number, itemType, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection(COLLECTION_NAME.library_borrow_items, ref => {
      let condition = ref.orderBy('date_key', 'desc')
      if (fromDate && toDate) {
        condition = condition.where('date_key', '>=', fromDate).where('date_key', '<=', toDate)
      }
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (itemType) {
        condition = condition.where('itemsType.key', '==', itemType)
      }
      if (lastVisible) {
        const startAfter = lastVisible['created_at'];
        condition = condition.orderBy('created_at', 'desc')
          .startAfter(startAfter)
      }
      else {
        condition = condition.orderBy('created_at', 'desc')
      }
      return condition
    })
  }

  getBorrowList(startDate, endDate, schoolKey: any, typeKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection(COLLECTION_NAME.library_borrow_items, ref => {
      let condition = ref.where('borrowDateKey', '>=', startDate).where('borrowDateKey', '<=', endDate).where('type.key', '==', typeKey)
      if (schoolKey) {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    })
  }

  libraryBorrowItems(schoolKey: any) {
    return this.db.collection('stores').doc(schoolKey).collection(COLLECTION_NAME.library_borrow_items, ref => ref.where('type.key', '==', 1))
  }

  getBookcaseType(schoolKey: any) {
    return this.db.collection('stores').doc(schoolKey).collection('book_store_bookcase_types')
  }

  getBookcase(schoolKey: any) {
    return this.db.collection('stores').doc(schoolKey).collection('book_store_bookcases')
  }

  DeviceRef(schoolKey: any) {
    return this.db.collection('stores').doc(schoolKey).collection('book_store_devices')
  }

  chartAccountIndexRef() {
    return this.db.collection(this.prefix + "chart_of_accounts", ref => ref.orderBy("index", "desc").limit(1));
  }

  sysAccountingRef() {
    return this.db.collection(this.prefix + "sys_account_types", ref =>
      ref.orderBy("order")
    );
  }
  sysConfigDocRef() {
    return this.db.collection(this.prefix + "sys_configs").doc("accounts");
  }

  sysConfigSettingRef() {
    return this.firestore().collection(this.prefix + "sys_configs").doc("settings");
  }

  sysSettings() {
    return this.db.collection(this.prefix + "sys_configs").doc("settings");
  }

  chartOfAccountsFireRef() {
    // return this.schoolFireRef().doc(this.storeKey).collection("chart_of_accounts");
  }

  ledgerRef(branch: any, period: any) {
    return this.db.collection<any>(this.prefix + "ledger_movement").doc(period).collection("ending_balances", ref => ref.where("branch.key", "==", branch))
  }

  chartOfAccountsRef(field, type: any) {
    // return this.schoolDocRef().collection<IChartAccount>("chart_of_accounts",
    //   ref => ref.where(field, "==", type)
    //     .orderBy("order")
    //     .orderBy("node")
    // );
  }

  chartOfAccountCheckNameRef(name) {
    return this.storeRef('sps').collection<IChartAccount>("chart_of_accounts", ref => ref.where('name', '==', name).limit(1))
  }

  chartOfAccountRef() {
    return this.storeRef('sps').collection<IChartAccount>("chart_of_accounts",
      ref => ref.orderBy("order").orderBy("node")
    );
  }


  bookingReservationRef(nameKey: string) {
    return this.db.collection('library_reservation', (ref) => {
      let condition = ref
        .orderBy("created_at", "desc")
        .where("isDelete", "==", false)
        .where("status.key", "==", 1);

      if (nameKey === 'pending') {
        condition = condition.where("isPending", "==", true);
      }
      if (nameKey === 'approved') {
        condition = condition.where("isApproved", "==", true);
      }
      if (nameKey === 'rejected') {
        condition = condition.where("isRejected", "==", true);
      }
      else if (nameKey === 'all') {
        condition = condition
      }
      return condition;
    });
  }


  bookLibraryRef(collectionName, lastVisible: any, schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.limit(Pages.size)
      if (lastVisible) {
        const startAfter = lastVisible["name"];
        condition = condition.orderBy("name")
          .startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("name")
      }
      return condition
    })
  }

  bookLibraryInventoryRef(collectionName, lastVisible: any, schoolKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.orderBy("bookNumber")
      // .limit(Pages.size)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible["inventory_number"];
        condition = condition.startAfter(startAfter)
      }
      return condition
    })
  }

  getDataRef(collectionName, schoolKey, field, campusKey, lastVisible: any) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.orderBy(field)
      // .limit(Pages.size)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible[field];
        condition = condition.orderBy(field)
          .startAfter(startAfter)
      }
      return condition
    })
  }
  getDataRefs(collectionName, schoolKey, field, campusKey, lastVisible: any) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.where('isDelete', '==', false).orderBy(field)
      // .limit(Pages.size)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible[field];
        condition = condition.startAfter(startAfter)
      }
      return condition.limit(Pages.size)
    })
  }
  getAndSearchDataRef(collectionName, schoolKey, field, campusKey, search, genresKey, lastVisible: any) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.orderBy(field)
      // .limit(Pages.size)
      if (genresKey) {
        condition = condition.where('genres.key', '==', genresKey)
      }
      if (search) {
        const keyword = search ? ConvertService.toCapitalize(search) : '~N/A~';
        condition = condition.where('keyword', 'array-contains', keyword)
      }
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible[field];
        condition = condition.startAfter(startAfter)
      }
      return condition.limit(Pages.size)
    })
  }
  newBookLibraryInventoryRef(collectionName, schoolKey, campusKey, search, typeKey, lastVisible: any) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.orderBy("bookNumber", 'desc').where('isDelete', '==', false)
      // .limit(Pages.size)
      if (search) {
        const keyword = search ? ConvertService.toCapitalize(search) : '~N/A~';
        condition = condition.where('keyword', 'array-contains', keyword)
      }
      if (typeKey) {
        condition = condition.where('itemType', '==', typeKey)
      }
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible["bookNumber"];
        condition = condition.startAfter(startAfter)
      }
      return condition.limit(Pages.size)
    })
  }

  getData(collectionName, schoolKey, field, campusKey, lastVisible) {
    return this.db.collection(collectionName, ref => {
      let condition = ref.limit(Pages.size).where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible[field];
        condition = condition.orderBy(field)
          .startAfter(startAfter)
      }
      else {
        condition = condition.orderBy(field)
      }
      return condition
    })
  }


  staticBookLibraryRef(collection, key) {
    return this.db.collection(collection).doc(key);
  }


  bookstoreDocRef(collectionName, key, schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName).doc(key);
  }
  bookstoreInventoryRef(collectionName, key, lastVisible: any, displayStatusKey: any, schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.where("book.key", "==", key)
      if (displayStatusKey === STATUS_OBJECT.deleted.key) {
        condition = condition.where("isDelete", "==", true)
      }
      else {
        condition = condition.where("isDelete", "==", false)
          .where("register_status.key", "==", displayStatusKey);
      }
      if (lastVisible) {
        const startAfter = lastVisible["updated_at"];
        condition = condition.orderBy("updated_at", "desc")
          .startAfter(startAfter)
      }
      else {
        condition = condition.orderBy("updated_at", "desc")
      }
      return condition.limit(Pages.size)
    })
  }


  batchCollectionRef() {
    return this.db.firestore;
  }
  booksRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection('book_library')
  }
  booksInventoryRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection('book_library_inventory')
  }
  publisherRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection('book_store_publishers')
  }

  libraryBookInventoryRef(schoolKey, key?) {
    return this.db.collection('stores').doc(schoolKey).collection('book_library_inventory', ref => ref.where('book.key', '==', key))
  }
  libraryBookInventoryDoc(schoolKey, key) {
    return this.db.collection('stores').doc(schoolKey).collection('book_library_inventory').doc(key)
  }

  bookClubRef() {
    return this.db.collection('book_store_books', ref => ref.where('bookClub', '==', true))
  }
  genresRef() {
    return this.db.collection('book_store_genres')
  }

  sectionsRef() {
    return this.db.collection('book_store_sections')
  }

  authorRef() {
    return this.db.collection('book_store_authors')
  }

  cardRef() {
    return this.db.collection('library_guest_card')
  }

  subjectsRef(lastVisible?: any) {
    return this.db.collection('subjects', ref => {
      let condition = ref.orderBy('name_en')
        .limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['name_en'])
      }
      return condition;
    })
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  displayItemNameEn(item: any): string {
    return item ? item.name_en : item;
  }

  displayItemSubjectNameEn(item: any): string {
    return item ? item.subject.name_en : item;
  }

  gradeSystemAnnualRef(schoolKey, programKey: any) {
    return this.storeDocRef(schoolKey).collection("grading_annual_system", ref => {
      let condition = ref.orderBy("order");
      if (programKey) {
        return ref.where('program.key', '==', programKey)
      }
      return condition;
    })
  }

  gradingSystemRef(ref?) {
    return this.db.collection('grading_program_subject_scales', ref)
  }

  gradeSystemSubjectRef(gradingSubjectKey?, gradeTypeKey?: any) {
    return this.db.collection("grading_program_subject_scales", ref => {
      let condition = ref.orderBy("order");
      if (gradingSubjectKey && gradeTypeKey) {
        return ref.where('gradeSubjectKey', '==', gradingSubjectKey)
          .where('gradingType.key', '==', gradeTypeKey)
          .orderBy('order')
      }
      return condition;
    });
  }

  gradingSubjectRef(storeKey, gradeLevelKey) {
    return this.storeDocRef(storeKey).collection("grading_program_subjects", ref => {
      let condition = ref.orderBy("subject.name_en");
      if (gradeLevelKey) {
        return ref.where('gradeLevelKey', '==', gradeLevelKey)
      }
      return condition;
    });
  }

  gradingLevel(storeKey, programKey) {
    return this.storeDocRef(storeKey).collection("grading_program_levels", ref => {
      let condition = ref.orderBy("level.order");
      if (programKey) {
        return ref.where('program.key', '==', programKey)
      }
      return condition;
    });
  }

  getCashierDocRef() {
    return this.db.collection("users", ref => ref.orderBy("create_date", "desc"));
  }

  fecthUserCallback() {
    return this.db.collection("users", ref => ref.where("status.key", "==", 1).limit(15));
  }

  autoSearchUserRef(field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("users", ref => ref
        .where('status.key', '==', 1)
        .where(field, 'array-contains', search)
        .orderBy(field)
        .limit(15)
        .orderBy("update_date", "desc"))
    }
    return this.db.collection("users", ref => ref
      .where('status.key', '==', 1)
      .limit(15)
      .orderBy("update_date", "desc"))
  }


  fecthEmployeesCallback(schoolKey) {
    return this.db.collection("employees", ref => ref.where("status.key", "==", 1).where('schoolKey', '==', schoolKey).limit(15).orderBy("update_date", "desc"));
  }

  autoSearchEmployeesRef(field: string, text: any, schoolKey) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("employees", ref => ref
        .where('schoolKey', '==', schoolKey)
        .where('status.key', '==', 1)
        .where(field, 'array-contains', search)
        .orderBy(field)
        .limit(15)
        .orderBy("update_date", "desc"))
    }
    return this.db.collection("employees", ref => ref
      .where('status.key', '==', 1)
      .limit(15)
      .orderBy("update_date", "desc"))
  }
  fetchBookCallback(schoolKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection("book_library", ref => {
      let condition = ref.limit(15).orderBy("name").where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
    );
  }

  fetchDeviceCallback(schoolKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection("book_store_devices", ref => {
      let condition = ref.limit(15).orderBy("deviceName").where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
    );
  }

  fetchSubjectGradeCallback(schoolKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection("subject_score", ref => {
      let condition = ref
        .limit(100)
        .orderBy("subject.name", 'asc')
        .orderBy("grade.name", 'asc');
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
    );
  }

  autoSearchSubjectGrade(field: string, text: any, schoolKey: string, campusKey: string, status: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      return this.db.collection('stores').doc(schoolKey).collection("subject_score", ref => {
        let condition = ref
          .where(field, 'array-contains', search)
          .where('status.key', '==', status.key == 1)
          .orderBy("subject.name", 'asc')
          .orderBy("grade.name", 'asc')
          .limit(100);
        if (schoolKey === 'ngs') {
          condition = condition.where('fromCampus.key', '==', campusKey);
        }
        return condition;
      });
    }

    return this.db.collection('stores').doc(schoolKey).collection("subject_score", ref => {
      let condition = ref
        .limit(100)
        .orderBy("subject.name", 'asc')
        .orderBy("grade.name", 'asc');
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey);
      }
      return condition;
    });
  }

  fetchDewey(schoolKey, shelf, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection("book_dewey_classification", ref => {
      let condition = ref.limit(15).where('isDelete', '==', false)
      // if(schoolKey === 'ngs'){
      //   condition = condition.where('isTaken', '==', false)
      // }
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (shelf && shelf.key) {
        condition = condition.where('shelf.key', '==', shelf.key)
      }
      return condition.orderBy('name')
    }
    );
  }

  autoSearchDewey(field: string, text: any, schoolKey, shelf, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection("book_dewey_classification", ref => {
      let condition = ref.limit(15).where('isDelete', '==', false)
      // if(schoolKey === 'ngs'){
      //   condition = condition.where('isTaken', '==', false)
      // }
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (shelf && shelf.key) {
        condition = condition.where('shelf.key', '==', shelf.key)
      }
      if (text && !text.key) {
        const search = text.toUpperCase();
        condition = condition.where(field, 'array-contains', search)
      }
      return condition.orderBy('name')
    })
  }

  DiseaseData(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("type_of_disease", ref => {
      let condition = ref
        .limit(100)
        .where('isDelete', '==', false)
        .where('status.key', '==', 1)
      return condition.orderBy('name')
    }
    );
  }

  autoSearchDiseaseData(field: string, text: any, schoolKey) {
    if (text && !text.key) {
      console.log('text', text);
      const search = text.toUpperCase();
      return this.db.collection('stores').doc(schoolKey).collection("type_of_disease", ref => {
        let condition = ref
          .where(field, 'array-contains', search)
          .orderBy('name')
          .where('isDelete', '==', false)
          .where('status.key', '==', 1)
          .limit(100);
        return condition;
      });
    } else {
      return this.db.collection('stores').doc(schoolKey).collection("type_of_disease", ref => {
        return ref
          .where('isDelete', '==', false)
          .where('status.key', '==', 1)
          .orderBy('name')
          .limit(100);
      });
    }
  }
  MedicineData(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("type_of_medicine", ref => {
      let condition = ref
        .limit(100)
        .where('isDelete', '==', false)
        .where('status.key', '==', 1)
      return condition.orderBy('name')
    }
    );
  }

  autoSearchMedicineData(field: string, text: any, schoolKey: string) {
    if (text && !text.key) {
      console.log('text', text);
      const search = text.toUpperCase();
      return this.db.collection('stores').doc(schoolKey).collection("type_of_medicine", ref => {
        let condition = ref
          .where(field, 'array-contains', search)
          .orderBy('name')
          .where('isDelete', '==', false)
          .where('status.key', '==', 1)
          .limit(100);
        return condition;
      });
    } else {
      return this.db.collection('stores').doc(schoolKey).collection("type_of_medicine", ref => {
        return ref
          .where('isDelete', '==', false)
          .where('status.key', '==', 1)
          .orderBy('name')
          .limit(100);
      });
    }
  }

  fetchBookLimit(schoolKey, deweyKey) {
    return this.db.collection('stores').doc(schoolKey).collection("book_library", ref => ref.where('bookDcc.key', '==', deweyKey).limit(1))
  }

  fetchShelf(schoolKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection("book_store_shelf", ref => {
      let condition = ref.limit(15).orderBy("name").where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
    );
  }
  autoSearchShelf(field: string, text: any, schoolKey, campusKey) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      return this.db.collection('stores').doc(schoolKey).collection("book_store_shelf", ref => {
        let condition = ref
          .where(field, 'array-contains', search)
          .orderBy('name')
          .where('isDelete', '==', false)
          .limit(15)
        if (schoolKey === 'ngs') {
          condition = condition.where('fromCampus.key', '==', campusKey)
        }
        return condition
      }
      )
    }
    return this.db.collection('stores').doc(schoolKey).collection("book_store_shelf", ref => {
      let condition = ref.limit(15).orderBy("name").where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
    )
  }

  fetchBookcase(schoolKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection("book_store_bookcases", ref => {
      let condition = ref.limit(15).orderBy("name").where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
    );
  }

  autoSearchBookcase(field: string, text: any, schoolKey, campusKey) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      return this.db.collection('stores').doc(schoolKey).collection("book_store_bookcases", ref => {
        let condition = ref
          .where(field, 'array-contains', search)
          .where('isDelete', '==', false)
          .orderBy('name')
          .limit(15)
        if (schoolKey === 'ngs') {
          condition = condition.where('fromCampus.key', '==', campusKey)
        }
        return condition
      }
      )
    }
    return this.db.collection('stores').doc(schoolKey).collection("book_store_bookcases", ref => {
      let condition = ref.limit(15).orderBy("name").where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
    )
  }

  fetchBookcaseType(schoolKey, campusKey) {
    return this.db.collection('stores').doc(schoolKey).collection("book_store_bookcase_types", ref => {
      let condition = ref.limit(15).orderBy("name").where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
    );
  }

  autoSearchBookcaseType(field: string, text: any, schoolKey, campusKey) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      return this.db.collection('stores').doc(schoolKey).collection("book_store_bookcase_types", ref => {
        let condition = ref
          .where(field, 'array-contains', search)
          .where('isDelete', '==', false)
          .orderBy('name')
          .limit(15)
        if (schoolKey === 'ngs') {
          condition = condition.where('fromCampus.key', '==', campusKey)
        }
        return condition
      })
    }
    return this.db.collection('stores').doc(schoolKey).collection("book_store_bookcase_types", ref => {
      let condition = ref.limit(15).orderBy("name").where('isDelete', '==', false)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
    )
  }


  autoSearchBook(field: string, text: any, schoolKey, campusKey) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      return this.db.collection('stores').doc(schoolKey).collection("book_library", ref => {
        let condition = ref
          .where('register_status.key', '==', STATUS_OBJECT.submitted.key)
          .where(field, 'array-contains', search)
          .where('isDelete', '==', false)
          .orderBy(field)
          .limit(15)
        if (schoolKey === 'ngs') {
          condition = condition.where('fromCampus.key', '==', campusKey)
        }
        return condition
      }
        // .orderBy("updated_at", "desc")
      )
    }
    return this.db.collection('stores').doc(schoolKey).collection("book_library", ref => {
      let condition = ref
        .where('register_status.key', '==', STATUS_OBJECT.submitted.key)
        .limit(15);
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }
      // .orderBy("updated_at", "desc")
    )
  }

  autoSearchDevice(field: string, text: any, schoolKey, campusKey) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      return this.db.collection('stores').doc(schoolKey).collection("book_store_devices", ref => {
        let condition = ref
          .where('register_status.key', '==', STATUS_OBJECT.submitted.key)
          .where(field, 'array-contains', search)
          .where('isDelete', '==', false)
          .orderBy(field)
          .limit(15)
        if (schoolKey === 'ngs') {
          condition = condition.where('fromCampus.key', '==', campusKey);
        }
        return condition
      }
        // .orderBy("updated_at", "desc")
      )
    }
    return this.db.collection('stores').doc(schoolKey).collection("book_store_devices", ref => {
      let condition = ref
        .where('register_status.key', '==', STATUS_OBJECT.submitted.key)
        .limit(15)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey);
      }
      return condition
    }
      // .orderBy("updated_at", "desc")
    )
  }

  autoSearchBatchLevelRef(field: string, text: any, schoolKey, yearKey, campusKey) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("institute_training_level_batch", ref => ref
        .where('schoolKey', '==', schoolKey)
        .where('academicYear.key', '==', yearKey)
        .where('campus.key', '==', campusKey)
        .where(field, '>=', search)
        .limit(15)
        .orderBy(field)
      )
    }
    return this.db.collection("institute_training_level_batch", ref => ref
      .where('schoolKey', '==', schoolKey)
      .where('academicYear.key', '==', yearKey)
      .where('campus.key', '==', campusKey)
      .limit(15)
      .orderBy("name"))
  }


  // autoSearchBatchLevelRef(field: string, text: any, schoolKey, yearKey, campusKey) {
  //   if (text && text.key == "name") {
  //     const search = text.toUpperCase();
  //     // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
  //     return this.db.collection("institute_training_level_batch", ref => ref
  //       .where('schoolKey', '==', schoolKey)
  //       .where('academicYear.key', '==', yearKey)
  //       .where('campus.key', '==', campusKey)
  //       .where(field, '>=', search)
  //       .limit(15)
  //       .orderBy(field)
  //       )
  //   }
  //   if(text && text.key == "puc_id"){
  //     return this.db.collection("students", ref =>
  //     ref
  //       .where('schoolKey', '==', schoolKey)
  //       .where('puc_id', '==', text)
  //       .orderBy(field, 'desc')
  //   );
  //   }
  //   return this.db.collection("institute_training_level_batch", ref => ref
  //   .where('schoolKey', '==', schoolKey)
  //   .where('academicYear.key', '==', yearKey)
  //   .where('campus.key', '==', campusKey)
  //     .limit(15)
  //     .orderBy("name"))
  // }


  fecthAllEmployeesCallback(schoolKey) {
    return this.db.collection("employees", ref => ref.where('schoolKey', '==', schoolKey).limit(15).orderBy("update_date", "desc"));
  }

  autoSearchAllEmployeesRef(schoolKey, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      // const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("employees", ref => ref
        .where('schoolKey', '==', schoolKey)
        .where(field, 'array-contains', search)
        .orderBy(field)
        .limit(15)
        .orderBy("update_date", "desc"))
    }
    return this.db.collection("employees", ref => ref
      .where('schoolKey', '==', schoolKey)
      .limit(15)
      .orderBy("update_date", "desc"))
  }

  userProfileRef() {
    return this.db.collection("user_profiles")
  }
  kgeTestRef(gradeKey?: any, type?: any) {
    if (type) {
      return this.db.collection("testing_kge", ref => ref
        .where("exam_type.key", "==", type.key)
        .where("programLevelKey", "==", gradeKey)
        .orderBy("created_at"));
    }
    return this.db.collection("testing_kge", ref => ref.orderBy("created_at"));
  }
  kgeQuestionRef(questionKey) {
    return this.db.collection("testing_kge").doc(questionKey).collection('questions', ref => ref.orderBy("created_at"));
  }
  placementTestRef() {
    return this.db.collection('placement_test')
  }
  generalPlacementTestRef() {
    return this.db.collection('general_placement_test', ref => ref.orderBy('page_key'))
  }
  gepRef(type: any) {
    return this.db.collection("testing_gep", ref => ref.where("type.key", "==", type.key).orderBy("created_at"));
  }
  teacherRef() {
    return this.db.collection("instructors");
  }
  kindergartenTestRef() {
    return this.db.collection("testing_kindergarten", ref => ref.orderBy("created_at"));
  }
  gepQuestionRef(questionKey) {
    return this.db.collection("testing_gep").doc(questionKey).collection('questions', ref => ref.orderBy("created_at"));
  }
  kindergartenQuestionRef(questionKey) {
    return this.db.collection("testing_kindergarten").doc(questionKey).collection('questions', ref => ref.orderBy("created_at"));
  }
  userAccountRef() {
    return this.db.collection("users");
  }
  englishTeacherRef() {
    return this.db.collection("employees");
  }
  employeeBySchoolRef(schoolKey) {
    return this.db.collection("employees", ref => ref.where("schoolKey", "==", schoolKey)
      .limit(100)
    );
  }


  // lazyEmployeesRef(lastVisible: any, search, filter, statusKey, schoolKey, campusKey) {
  //   return this.db.collection("employees", ref => {
  //     let condition = ref
  //       .where('schoolKey', '==', schoolKey)
  //       .orderBy("update_date", "desc")
  //       .limit(Pages.size)
  //     if (statusKey) {
  //       condition = condition.where("status.key", "==", statusKey);
  //     }
  //     if (campusKey) {
  //       condition = condition.where("campus.key", "==", campusKey);
  //     }
  //     if (search) {
  //       const txt = ConvertService.toCapitalize(search);
  //       condition = condition.where('keywords', 'array-contains', txt)
  //     }
  //     if (lastVisible) {
  //       condition = condition.startAfter(lastVisible)
  //     }
  //     return condition
  //   })
  // }
  colorBySchoolRef(schoolKey) {
    return this.db.collection("stores").doc(schoolKey).collection("colors", ref => ref
      .limit(100)
    );
  }
  kidMcqQuestionRef(questionKey) {
    return this.db.collection("testing_kindergarten").doc(questionKey).collection('questions', ref => ref.orderBy("created_at"));
  }

  autoCollectionRef(collection) {
    return this.db.collection(collection, ref => ref.where('status.key', '==', 1).limit(Pages.size));
  }

  autoFilterCollectionRef(collectionName: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection(collectionName, ref =>
        ref
          .where("status.key", "==", 1)
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field, "desc")
          .limit(Pages.size)
      );
    }
    return this.db.collection(collectionName, ref =>
      ref
        .where("status.key", "==", 1)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  studentAdmissionDocRef(key) {
    return this.db.collection("academics_major_admission").doc(key);
  }

  studentAdmissionByStudentRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref.where("student.key", "==", studentKey));
  }

  serverTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  settingFireStoreFire() {
    return this.firestore().collection("testing_options").doc("general");
  }

  usersRef() {
    return this.db.collection('users')
  }

  scholarshipDBByStudentRef(studentKey: string) {
    return this.db.collection('scholarships', ref => ref
      .where("student.key", "==", studentKey)
    );
  }

  employeeSearchDocsRef(search) {
    if (search) {
      if (search.key) {
        return this.db.collection("employees", ref => ref
          .where("key", "==", search.key)
        )
      }
      return this.db.collection("employees", ref => ref
        .where("full_name", ">=", search)
        .orderBy("full_name")
        .orderBy("page_key")
        .limit(Pages.size)
      )
    }
    return this.db.collection("employees", ref => ref
      .orderBy("page_key")
      .limit(Pages.size)
    )
  }
  scholarshipFireRef() {
    return this.firestore().collection('scholarships');
  }
  scholarshipMovementFireRef() {
    return this.firestore().collection('scholarship_movement');
  }

  employeesSearchRef(field: string, search: string, schoolKey?) {
    if (!search) {
      return this.db.collection("employees", ref => {
        let condition = ref.orderBy(field);
        if (schoolKey && schoolKey !== STORE_CONFIG.COMPANY_KEY) {
          condition = ref.where('schoolKey', '==', schoolKey)
        }
        return condition.limit(Pages.size)
      });
    } else {
      const text = search.toUpperCase();
      return this.db.collection("employees", ref => {
        let condition = ref.where(field, ">=", text)
        if (schoolKey && schoolKey !== STORE_CONFIG.COMPANY_KEY) {
          condition = condition.where('schoolKey', '==', schoolKey)
        }
        return condition.orderBy(field).limit(Pages.size)
      });
    }


  }

  studentSearchBySchoolRef(schoolKey, field: string, search: string) {
    if (!search) {
      return this.db.collection("students", ref =>
        ref
          .where('schoolKey', '==', schoolKey)
          .orderBy(field, 'desc')
          .limit(Pages.size)
      );
    }

    return this.db.collection("students", ref =>
      ref
        .where('schoolKey', '==', schoolKey)
        .where(field, ">=", search)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  DepartmentArrayRef() {
    return this.db.collection<any>("departments", ref => ref.orderBy("name").limit(30));
  }

  departmentsRef() {
    return this.db.collection<any>("departments", ref => ref.orderBy("name"));
  }

  memberStoreRef() {
    return this.db.collection<any>("stores", ref => ref.orderBy("name"));
  }
  titlesRef() {
    return this.db.collection<any>("titles", ref => ref.orderBy("name"));
  }
  getemployeeFetchRef(t, lastVisible: any) {
    if (t == 'full-time') {
      return this.db.collection("employees", ref =>
        ref
          .where('employmentRef.employment_type.key', '==', 1)
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("employees", ref =>
        ref
          .where('employmentRef.employment_type.key', '==', 2)
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    }

  }
  getemployeeClassRef(tkey, inskey) {
    return this.db.collection<any>("academic_year").doc(tkey).collection('schedules', ref => ref.where('instructor.key', '==', inskey));
  }
  getemployeeExperiencesRef(key) {
    return this.db.collection("employees_experiences", ref => ref.where('employee.key', '==', key));
  }

  employmentContractRef(key: any) {
    return this.db.collection('employees').doc(key).collection("employees_contracts", ref => ref.orderBy('created_at', 'desc'));
  }

  getinstructorInsRef(ins, t) {
    const em = ins + '.employment_type.key';
    const inst = ins + '.institute.key';
    if (t == 'full-time') {
      return this.db.collection("employees", ref => ref.where(inst, '==', ins).where(em, '==', 1));
    } else {
      return this.db.collection("employees", ref => ref.where(inst, '==', ins).where(em, '==', 2));
    }
  }
  getinstructorRef(t) {
    if (t == 'full-time') {
      return this.db.collection("employees", ref => ref.where('academicRef.employment_type.key', '==', '1'));
    } else {
      return this.db.collection("employees", ref => ref.where('academicRef.employment_type.key', '==', '2'));
    }
  }
  getOneemployeeEmploymentRef(key) {
    return this.db.collection("employees_contracts", ref => ref.where('employee.key', '==', key).orderBy('start_date_key', 'asc').limit(1));
  }
  employeeFetchRefSc(lastVisible: any) {
    return this.db.collection("employees", ref =>
      ref
        .orderBy("page_key", "desc")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
    );
  }
  employeeListingRef(code: string) {
    return this.db.collection("employees", ref =>
      ref
        .where("code", "==", code)
        .orderBy("page_key", "desc")
        .limit(Pages.size)
    );
  }

  sysSettingRef() {
    return this
      .firestore()
      .collection("testing_options")
      .doc("general");
  }

  employeeFireStore() {
    return this.firestore().collection("employees");
  }
  employeeCollection() {
    return this.db.collection("employees", ref => ref.orderBy("full_name"));
  }

  instructorsFireStore() {
    return this.firestore().collection("instructors");
  }

  instructorsRateFireStore() {
    return this.firestore().collection("instructor_rates");
  }

  instructorsmayjorRef() {
    return this.db.collection("instructor_mayjor");
  }
  EmployeeEducationsRef() {
    return this.db.collection("employees_educations");
  }
  EmployeeContactsRef() {
    return this.db.collection("employees_contacts");
  }
  EmployeeExperiencesRef() {
    return this.db.collection("employees_experiences");
  }

  employeeEmploymentFireRef() {
    return this.firestore().collection("employees_contracts");
  }

  instituteFireRef() {
    return this.firestore().collection("institutes");
  }

  instituteMemberFireRef() {
    return this.firestore().collection("institutes_members");
  }

  facultyMemberFireRef() {
    return this.firestore().collection("faculties_members");
  }

  positionRef() {
    return this.db.collection("positions");
  }

  positionByLevelRef(levelKey) {
    return this.db.collection("positions");
  }

  nationalityArrayRef() {
    return this.db.collection<any>("nationality");
  }
  countryArrayRef() {
    return this.db.collection<any>("location_countries");
  }
  instructorFireRef() {
    return this.firestore().collection("instructors");
  }

  instructorContractFireRef() {
    return this.firestore().collection("instructor_contract_movement");
  }

  instituteContractFireRef() {
    return this.firestore().collection("institute_contract_movement");
  }
  facultyFireRef() {
    return this.firestore().collection("faculties");
  }
  EmployeeEmploymentRef() {
    return this.db.collection("employees_contracts");
  }

  cityArrayRef(key) {
    return this.db.collection<any>("location_cities", ref => ref.where('country.key', '==', key));
  }
  districtArrayRef(key) {
    return this.db.collection<any>("location_districts", ref => ref.where('city.key', '==', key));
  }
  getemployeeListingRef(t, code: string) {
    if (t == 'full-time') {
      return this.db.collection("employees", ref =>
        ref
          .where('employmentRef.employment_type.key', '==', 1)
          .where("code", "==", code)
          .orderBy("page_key", "desc")
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("employees", ref =>
        ref
          .where('employmentRef.employment_type.key', '==', 2)
          .where("code", "==", code)
          .orderBy("page_key", "desc")
          .limit(Pages.size)
      );
    }

  }
  getInstructorRateRef(key) {
    return this.db.collection<any>("instructor_rates", ref => ref.where('employee.key', '==', key));
  }

  getemployeeSalaryRef(key) {
    return this.db.collection<any>("employees").doc(key).collection('employees_contracts');
  }

  getemployeeEducationsRef(key) {
    return this.db.collection("employees_educations", ref => ref.where('employee.key', '==', key));
  }
  getemployeeProfileRef() {
    return this.db.collection("employees", ref => ref.orderBy('create_date').limit(50));
  }

  lazyEmployeesRef(lastVisible: any, search, filter, statusKey, schoolKey, campusKey) {
    return this.db.collection("employees", ref => {
      let condition = ref
        .where('schoolKey', '==', schoolKey)
        .orderBy("update_date", "desc")
        .limit(Pages.size)
      if (statusKey) {
        condition = condition.where("status.key", "==", statusKey);
      }
      if (campusKey) {
        condition = condition.where("campus.key", "==", campusKey);
      }
      if (search) {
        const txt = ConvertService.toCapitalize(search);
        condition = condition.where('keywords', 'array-contains', txt)
      }
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })
  }
  getemployeeRef(t) {
    if (t == 'full-time') {
      return this.db.collection("employees", ref => ref.where('employmentRef.employment_type.key', '==', 1).limit(Pages.size));
    } else {
      return this.db.collection("employees", ref => ref.where('employmentRef.employment_type.key', '==', 2).limit(Pages.size));
    }
  }
  getemployeeDocRef(key) {
    return this.db.collection("employees").doc(key);
  }

  getRelatedEmployeeDocRef(collection, key) {
    return this.db.collection(collection, ref => ref.where('employee.key', '==', key))
  }

  instructorContractDataRef(employeeKey) {
    return this.db.collection("instructor_contract_movement", ref => ref
      .where("employee.key", "==", employeeKey));
  }

  instituteContractRef(employeeKey, instituteKey) {
    return this.db.collection("institute_contract_movement", ref => ref
      .where("employee.key", "==", employeeKey)
      .where("institute.key", "==", instituteKey)
      .orderBy("create_date", "desc"));
  }
  instructorContractRef(employeeKey, type) {
    return this.db.collection("instructor_contract_movement", ref => ref
      .where("employee.key", "==", employeeKey)
      .where("id", "==", type)
      .orderBy("create_date", "desc"));
  }
  employeereportFetchRef(tabkey, timekey, type) {
    let ref = this.firestore().collection('employees')
    const cdate = moment(new Date()).format('YYYYMMDD');
    if (tabkey == 'staff') {
      if (timekey.key == 0) {
        if (type.key == 1) {
          return ref
            .where('employmentRef.end_date_key', '>', 0)
            .where('employmentRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('employmentRef.end_date_key', '>', 0)
            .where('employmentRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('employmentRef.end_date_key', '>', 0)
        }
      } else if (timekey.key == 1) {
        if (type.key == 1) {
          return ref
            .where('employmentRef.employment_type.key', '==', 1)
            .where('employmentRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('employmentRef.employment_type.key', '==', 1)
            .where('employmentRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('employmentRef.employment_type.key', '==', 1)
        }
      } else {
        if (type.key == 1) {
          return ref
            .where('employmentRef.employment_type.key', '==', 2)
            .where('employmentRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('employmentRef.employment_type.key', '==', 2)
            .where('employmentRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('employmentRef.employment_type.key', '==', 2)
        }
      }
    }

    if (tabkey == 'academic_instructors') {
      if (timekey.key == 0) {
        if (type.key == 1) {
          return ref
            .where('academicRef.end_date_key', '>', 0)
            .where('academicRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('academicRef.end_date_key', '>', 0)
            .where('academicRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('academicRef.end_date_key', '>', 0)
        }
      } else if (timekey.key == 1) {
        if (type.key == 1) {
          return ref
            .where('academicRef.employment_type.key', '==', 1)
            .where('academicRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('academicRef.employment_type.key', '==', 1)
            .where('academicRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('academicRef.employment_type.key', '==', 1)
        }
      } else {
        if (type.key == 1) {
          return ref
            .where('academicRef.employment_type.key', '==', 2)
            .where('academicRef.end_date_key', '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where('academicRef.employment_type.key', '==', 2)
            .where('academicRef.end_date_key', '>=', Number(cdate))
        } else {
          return ref.where('academicRef.employment_type.key', '==', 2)
        }

      }
    } else if (tabkey != 'all' && tabkey != 'staff' && tabkey != 'academic_instructors') {
      const em = tabkey + '.employment_type.key';
      const inst = tabkey + '.institute.key';
      const edate = tabkey + '.end_date_key';

      if (timekey.key == 0) {
        if (type.key == 1) {
          return ref.where(edate, '>', 0).where(edate, '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref.where(edate, '>', 0).where(edate, '>=', Number(cdate))
        } else {
          return ref.where(inst, '==', tabkey)
        }
      } else if (timekey.key == 1) {
        if (type.key == 1) {
          return ref
            .where(inst, '==', tabkey).where(em, '==', 1)
            .where(edate, '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where(inst, '==', tabkey).where(em, '==', 1)
            .where(edate, '>=', Number(cdate))
        } else {
          return ref.where(inst, '==', tabkey).where(em, '==', 1)
        }
      } else {
        if (type.key == 1) {
          return ref
            .where(inst, '==', tabkey).where(em, '==', 2)
            .where(edate, '<=', Number(cdate))
        } else if (type.key == 2) {
          return ref
            .where(inst, '==', tabkey).where(em, '==', 2)
            .where(edate, '>=', Number(cdate))
        } else {
          return ref.where(inst, '==', tabkey).where(em, '==', 2)
        }

      }
    }
    return ref;
  }

  employeesRef(field: string, schoolKey?) {
    return this.db.collection("employees", ref => {
      let condition = ref.orderBy(field)
      if (schoolKey && schoolKey !== STORE_CONFIG.COMPANY_KEY) {
        condition = condition.where('schoolKey', '==', schoolKey)
      }
      return condition.limit(Pages.size)
    });
  }

  userDataRef(field: string, schoolKey?) {
    return this.db.collection("users", ref => {
      let condition = ref.orderBy(field)
      if (schoolKey && schoolKey !== STORE_CONFIG.COMPANY_KEY) {
        condition = condition.where('schoolKey', '==', schoolKey)
      }
      return condition.limit(Pages.size)
    });
  }


  studentBySchoolRef(schoolKey, field: string) {
    return this.db.collection("students", ref =>
      ref
        .where('schoolKey', '==', schoolKey)
        // .orderBy(field, 'desc')
        .limit(Pages.size)
    );
  }

  allStudentBySchoolRef(schoolKey, field: string) {
    return this.db.collection("students", ref =>
      ref
        .where('schoolKey', '==', schoolKey)
        .orderBy(field, 'desc')
    );
  }

  compareObject(o1: any, o2: any): boolean {
    if (o2 && o1) {
      return o1.key === o2.key;
    }
    return false;
  }
  //end hr
  studentAccountFireRef() {
    return this.firestore().collection("student_accounts");
  }

  testingDoc(key: string) {
    return this.db.collection("testing").doc(key);
  }

  pettyCashScrollRef(lastVisible: any) {
    return this.db.collection("petty_cash", ref =>
      ref
        .orderBy("page_key", "desc")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
    );
  }
  pettyCashFireRef() {
    return this.firestore().collection("petty_cash");
  }
  employeeSearchRef(search: any) {
    if (search.key) {
      return this.db.collection("employees", ref => ref
        .where("key", "==", search.key).limit(1)
      );
    }
    return this.db.collection("employees", ref =>
      ref
        .where("full_name", ">=", search)
        .orderBy("full_name")
        .limit(Pages.size)
    );
  }

  employeeSRef() {
    return this.db.collection("employees", ref => ref.limit(Pages.size));
  }
  testingCollectionRef(user: any, status: string, text) {
    if (text) {
      return this.db.collection("testing", ref =>
        ref
          .where("serial_id", "==", text)
          .where("create_by.key", "==", user.key)
      );
    }
    let ref = this.db.collection("testing", ref =>
      ref.orderBy("page_key", "desc").limit(Pages.size)
    );
    switch (status) {
      case "all":
        ref = this.db.collection("testing", ref =>
          ref
            // .orderBy("page_key", "desc")
            .where("create_by.key", "==", user.key)
            .limit(Pages.size)
        );
        break;
      case "unpaid":
        ref = this.db.collection("testing", ref =>
          ref
            .where("isPaidTest", "==", false)
            // .orderBy("page_key", "desc")
            .limit(Pages.size)
        );
        break;
      case "paid":
        ref = this.db.collection("testing", ref =>
          ref
            .where("isPaidTest", "==", true)
            // .orderBy("page_key", "desc")
            .limit(Pages.size)
        );
        break;
      case "own":
        ref = this.db.collection("testing", ref =>
          ref
            .where("create_by.key", "==", user.key)
            // .orderBy("page_key", "desc")
            .limit(Pages.size)
        );
        break;
      default:
        break;
    }
    return ref;
  }
  testingCollectionScrollRef(textSearch: string, lastVisible: any) {
    if (textSearch !== "") {
      return this.db.collection("testing", ref =>
        ref
          .where("serial_id", "==", textSearch)
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("testing", ref =>
        ref
          .orderBy("page_key", "desc")
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    }
  }

  registrationResultFireRef() {
    return this.firestore().collection("registration_result");
  }

  testingResultFireRef() {
    return this.firestore().collection("testing_result");
  }
  testingResultDBRef() {
    return this.db.collection("testing_result");
  }
  studentAdmissionTranscriptFireRef() {
    return this.firestore().collection("academic_student_transcript");
  }

  testingCenterCollectionRef(termKey, campusKey, programKey, sectionKey, serialNumber: any, lastVisible: any) {
    if (serialNumber) {
      return this.db.collection("academic_year").doc(termKey)
        .collection('campus').doc(campusKey)
        .collection("testing", ref => ref.where("serial_id", "==", serialNumber))
    }
    return this.db.collection("academic_year").doc(termKey)
      .collection("campus").doc(campusKey).collection("testing", ref => {
        let condition = ref.where("program.key", "==", programKey).orderBy("create_date", "desc")
        switch (sectionKey) {
          case "campus":
            condition = condition.where("onlineTesting", "==", false)
            break;
          case "online":
            condition = condition.where("onlineTesting", "==", true)
            break;
          case "already_take":
            condition = condition.where("isTakeTest", "==", true)
            break;
          case "unpaid":
            condition = condition.where("isPaid.key", "==", TestFeeStatus.unpaid.key)
            break;
          case "paid":
            condition = condition.where("isPaid.key", "==", TestFeeStatus.paid.key)
            break;
          default:
            break;
        }
        if (lastVisible) {
          condition = condition.startAfter(lastVisible["create_date"])
        }
        return condition.limit(Pages.size);
      })
  }

  invoiceSummeryByShiftRef(shiftKey: string, invoiceTypeKey: number, type) {
    let ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 4)
    );
    switch (type) {
      case "academic_program":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection<any>("invoices", ref =>
          ref
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypeKey)
            .where("school_fee_type.key", "==", enrollPrograms.academic.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "institute_and_center":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection<any>("invoices", ref =>
          ref
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypeKey)
            .where("school_fee_type.key", "==", enrollPrograms.institutes.key)
            .orderBy("page_key", "desc")
        );
        break;
      default:
        ref = this.db.collection("shift_movements").doc(shiftKey).collection<any>("invoices", ref =>
          ref
            .where("isPaid.key", "==", 1)
            .where("invoice_type.key", "==", invoiceTypeKey)
            .where("dailyShift.key", "==", shiftKey)
            .orderBy("page_key", "desc")
        );
        break;
    }
    return ref;
  }

  studentsRef() {
    return this.db.collection('students')
  }

  parentsRef() {
    return this.db.collection("parents");
  }

  parentsDBRef(schoolKey, search) {
    return this.db.collection("parents", ref => {
      let condition = ref.where("schoolKey", "==", schoolKey)
      if (search) {
        const text = search.toUpperCase()
        condition = condition.where("full_name", ">=", text)
      }
      return condition.limit(100)
    })
  }


  // parentsDBRef(schoolKey, search) {
  //   return this.db.collection("parents", ref => ref.where("schoolKey", "==", schoolKey).limit(100))
  // }

  classroomRef() {
    return this.db.collection("classroom", ref => ref.orderBy("name"));
  }

  buildingRef(schoolKey: any, campusKey: any, isAdmin: boolean, isAllCampus: boolean) {
    return this.db.collection("stores").doc(schoolKey).collection("building", ref => {
      let condition = ref.orderBy("campus.order")
      if (!isAdmin && !isAllCampus) {
        condition = condition.where('campus.key', '==', campusKey)
      }
      return condition;
    });
  }

  // buildingRef(schoolKey: any, campusKey?: any) {
  //   return this.db.collection("stores").doc(schoolKey).collection("building", ref => {
  //     let condition = ref.orderBy("campus.order")
  //     if (campusKey) {
  //       condition = ref.where('campus.key', '==', campusKey)
  //     }
  //     return condition;
  //   });
  // }

  sessionRef() {
    return this.db.collection("institute_session", ref =>
      ref.orderBy("page_key").limit(100)
    );
  }
  employeeDBRef() {
    return this.db.collection("employees", ref => ref.orderBy("full_name").limit(100));
  }

  publicHolidayRef(schoolKey) {
    return this.storeDocRef(schoolKey).collection("public_holiday", ref => ref.orderBy("from_date_key"));
  }
  colorDBRef(schoolKey) {
    return this.storeDocRef(schoolKey).collection("colors", ref => ref.orderBy("order").limit(100));
  }
  trainingBatchLevelRef(levelKey, shiftKey, academicYearKey, campusKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.key", "==", levelKey)
      .where("academicYear.key", "==", academicYearKey)
      .where("campus.key", "==", campusKey)
      .where("shift.key", "==", shiftKey)
      .where("status.key", "==", 1)
    )
  }

  studentNewAdmission(termKey, campusKey, programKey, levelKey) {
    return this.db.collection('academic_year').doc(termKey)
      .collection("campus").doc(campusKey)
      .collection("admission", ref => {
        let condition = ref.where("isFreshman", "==", true)
          .where("isHaveBatch", "==", false)
          .where("trainingProgram.key", "==", programKey)
        if (levelKey) {
          condition = condition.where('trainingGrade.key', '==', levelKey)
        }
        return condition.orderBy('create_date');
      })
  }

  studentNewAdmissionRef(academicYearKey: string, campusKey: string, levelKey: string, field: string, search) {
    if (search) {
      if (search.key) {
        return this.db.collection("academic_year").doc(academicYearKey).collection("admission", ref => ref
          .where("status.key", "==", 1)
          .where("campus.key", "==", campusKey)
          .where("program_academic.key", "==", levelKey)
          .where("isHaveBatch", "==", false)
          .where("key", "==", search.key)
          .limit(Pages.size)
        );
      }
      return this.db.collection("academic_year").doc(academicYearKey).collection("admission", ref => ref
        .where("status.key", "==", 1)
        .where("campus.key", "==", campusKey)
        .where("program_academic.key", "==", levelKey)
        .where("isHaveBatch", "==", false)
        .where(field, ">=", search)
        .orderBy(field)
        .orderBy("page_key")
        .limit(Pages.size)
      );
    }
    return this.db.collection("academic_year").doc(academicYearKey).collection("admission", ref => ref
      .where("status.key", "==", 1)
      .where("campus.key", "==", campusKey)
      .where("program_academic.key", "==", levelKey)
      .where("isHaveBatch", "==", false)
      .limit(Pages.size)
    );
  }
  instituteTrainingLevelRef() {
    return this.db.collection("institute_training_level")
  }
  batchSessionFireRef(sessionKey: string) {
    return this.firestore().collection("institute_training_level_batch").where("session.key", "==", sessionKey);
  }
  batchStudentAdmissionRef(shiftKey, levelKey, programKey, limit: number) {
    return this.db.collection("academics_major_admission", ref => ref
      .where("shift.key", "==", shiftKey)
      .where("admission_type.key", "==", 2)
      .where("program_academic.start_level_key", "==", levelKey)
      .where("program_academic.key", "==", programKey)
      .orderBy("student.full_name")
      .limit(limit)
    )
  }

  generationBatchRef(lastVisible?: any) {
    if (lastVisible) {
      return this.db.collection("academic_generation_batch", ref => ref
        .orderBy("page_key", "desc")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
      );
    }
    return this.db.collection("academic_generation_batch", ref => ref
      .orderBy("page_key", "desc")
      .limit(Pages.size)
    );
  }


  batchLevelDocRef(key) {
    return this.db.collection("institute_training_level_batch").doc(key);
  }

  batchLevelKeyRef(levelKey, academicYearKey, campusKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.key", "==", levelKey)
      .where("academicYear.key", "==", academicYearKey)
      .where("campus.key", "==", campusKey)
    )
  }

  // admissionLevelKeyRef(levelKey, academicYearKey, campusKey) {
  //   return this.db.collection("institute_training_level_batch", ref => ref
  //     .where("level.key", "==", levelKey)
  //     .where("academicYear.key", "==", academicYearKey)
  //     .where("campus.key", "==", campusKey)
  //   )
  // }


  batchLevelByTermAndCampusRef(academicYearKey, campusKey, search) {
    return this.db.collection("institute_training_level_batch", ref => {
      let condition = ref.where("academicYear.key", "==", academicYearKey).where("campus.key", "==", campusKey)
      if (search && !search.key) {
        condition = condition.where('name', '>=', search)
      }
      return condition.orderBy("name").limit(Pages.size);
    })
  }

  newEnglishStudentRef(instituteKey: string, campusKey: string, shiftKey: string, programKey: string, levelKey: string, limit: number, termKey: string) {
    return this.db.collection("schedule_in_progress", ref => ref
      .where("institute.key", "==", instituteKey)
      .where("target_term.key", "==", termKey)
      .where("target_campus.key", "==", campusKey)
      .where("study_session.key", "==", shiftKey)
      .where("test_type.key", "==", programKey)
      .where("testing_result.level.key", "==", levelKey)
      .where("isPaid.key", "==", 1)
      .limit(limit)
      .orderBy("student.full_name")
    );
  }

  admissionStudentBatch(academicYearKey: string, campusKey: string, levelKey: string) {
    return this.db.collection("academic_year").doc(academicYearKey).collection("admission", ref => ref
      .where("status.key", "==", 1)
      .where("campus.key", "==", campusKey)
      .where("program_academic.key", "==", levelKey)
      .where("isHaveBatch", "==", true)
    );
  }

  newAdmissionStudentRef(academicYearKey: string, campusKey: string, levelKey: string, limit: number) {
    return this.db.collection("academic_year").doc(academicYearKey).collection("admission", ref => ref
      .where("status.key", "==", 1)
      .where("campus.key", "==", campusKey)
      .where("program_academic.key", "==", levelKey)
      .where("isHaveBatch", "==", false)
      .limit(limit)
    );
  }
  studentInstituteComingBackRef(selectedInstituteKey, selectedShiftKey, selectedCampusKey, termKey, levelKey, limit) {
    return this.db.collection("institute_no_schedule", ref => ref
      .where("program.category.key", "==", selectedInstituteKey)
      .where("study_session.key", "==", selectedShiftKey)
      .where("target_campus.key", "==", selectedCampusKey)
      .where("target_term.key", "==", termKey)
      .where("subject.key", "==", levelKey)
      .where("isPaid", "==", true)
      .orderBy("student.full_name")
      .limit(limit)
    );
  }
  sessionLevelKeyRef(levelKey, shiftKey) {
    return this.db.collection("institute_training_level_session", ref => ref
      .where("shift.key", "==", shiftKey)
    )
  }
  sessionLevelRef() {
    return this.db.collection("institute_training_level_session")
  }
  instituteFailFireRef() {
    return this.firestore().collection("institute_fail_students")
  }
  removeStudentFromBatchFireRef() {
    return this.firestore().collection("remove_student_from_batch");
  }
  studentEnrollInstituteFireRef() {
    return this.firestore().collection("admission_enrollment_institute_movement");
  }
  studentInstituteNoScheduleFireRef() {
    return this.firestore().collection("institute_no_schedule");
  }
  batchLevelMovementFireRef() {
    return this.firestore().collection("institute_batch_movement")
  }
  instituteNoScheduleStudentFireRef() {
    return this.firestore().collection("institute_no_schedule")
  }
  import_student_to_classFireRef() {
    return this.firestore().collection("import_student_to_class");
  }
  institutesRef() {
    return this.db.collection("institutes", ref =>
      ref.where("instituteType.key", "==", 1)
    );
  }
  termInstituteRef(termType) {
    return this.db.collection("academic_year", ref => ref
      .where("term_type.type", "==", termType.type))
  }
  instituteTermRef(instituteKey) {
    return this.db.collection<any>("academic_year", ref => ref
      .where("institute.key", "==", instituteKey)
      // .orderBy("page_key", "desc")
      .limit(Pages.size / 2)
    );
  }
  sessionByShiftRef(shiftKey: string) {
    return this.db.collection("institute_session", ref => ref
      .where("shift.key", "==", shiftKey));
  }

  sessionByShiftDBRef(schoolKey, campusKey: string, shiftKey: string, field: string) {
    return this.db.collection("stores").doc(schoolKey).collection("institute_session", ref => ref
      .where("shift.key", "==", shiftKey)
      .where("create_by.campus.key", "==", campusKey)
      .orderBy(field)
      .limit(Pages.size));
  }

  autoSessionByShift(schoolKey, campusKey: string, shiftKey: string, field: string, text: any) {
    if (text && !text.key) {
      const search = text.toUpperCase();
      const end = search.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      return this.db.collection("stores").doc(schoolKey).collection("institute_session", ref =>
        ref
          .where("shift.key", "==", shiftKey)
          .where("create_by.campus.key", "==", campusKey)
          .where(field, ">=", search)
          .where(field, '<', end)
          .orderBy(field)
          .limit(Pages.size)
      );
    }

    return this.db.collection("stores").doc(schoolKey).collection("institute_session", ref =>
      ref
        .where("shift.key", "==", shiftKey)
        .where("create_by.campus.key", "==", campusKey)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  classroomByCampusRef(schoolKey, campusKey) {
    return this.db.collection("stores").doc(schoolKey).collection("classroom", ref => ref
      .where("campus.key", "==", campusKey)
      .orderBy('name'));
    // if(isAdmin){
    //   return this.db.collection("stores").doc(schoolKey).collection("classroom", ref => ref.orderBy('name'));
    // }else{
    //   return this.db.collection("stores").doc(schoolKey).collection("classroom", ref => ref
    //   .where("campus.key", "==",campusKey)
    //   .orderBy('name'));
    // }
  }
  programLevelRef(programKey) {
    return this.db.collection("testLevel", ref =>
      ref.where("program.key", "==", programKey).orderBy("program_level.key")
    );
  }
  bookByLevelRef(levelKey) {
    return this.db.collection("institute_training_level_books", ref => ref.where("level.key", "==", levelKey));
    // return this.db.collection("institute_training_level_books");
  }
  instituteProgramsRef(instituteKey) {
    return this.db.collection<any>("testType", ref => ref
      .where("institute.key", "==", instituteKey)
      .orderBy("order")
    );
  }
  trainingLevelRef(programKey) {
    return this.db.collection("institute_training_level", ref => ref
      .where("program.key", "==", programKey)
      .orderBy("level.order"));
  }
  termInstituteActiveRef(type, instituteKey) {
    return this.db.collection("academic_year", ref => ref
      .where("term_type.key", "==", type)
      .where("termstatus.key", "<", 3)
      .where("institute.key", "==", instituteKey)
      .orderBy("termstatus.key")
      .orderBy("page_key", "desc"))
  }

  shiftRef() {
    return this.db.collection("academics_shift", ref => ref.orderBy("order"));
  }
  shiftFirstRef() {
    return this.db.collection("academics_shift", ref => ref.orderBy("order").limit(2));
  }

  holidayByYearRef(year: number, month: number) {
    if (month === 12) {
      return this.db.collection<any>("public_holiday", ref =>
        ref
          .where("year", ">=", year - 1)
          .where("year", "<=", year)
          .orderBy("year")
      );
    }
    return this.db.collection<any>("public_holiday", ref =>
      ref.where("year", "==", year)
    );
  }
  schoolFeeRef() {
    return this.db.collection("school_fee", ref => ref.orderBy("name"));
  }
  feeCategoryRef() {
    return this.db.collection("fee_categories", ref => ref.orderBy("name"));
  }
  schoolProgramRef() {
    return this.db.collection("school_programs", ref => ref.orderBy("name"));
  }

  schoolProgramBySchoolRef(schoolKey) {
    return this.db.collection("stores").doc(schoolKey).collection("school_programs", ref => ref.orderBy("name"));
  }

  schoolRef() {
    return this.db.collection("stores", ref => ref.orderBy("order"));

  }

  schoolFireRef() {
    return this.firestore().collection("stores");
  }

  schoolDocRef() {
    return this.db.collection("stores")
  }

  storeDocRef(key) {
    return this.db.collection("stores").doc(key);
  }

  storeFireDocRef(key) {
    return this.db.firestore.collection("stores").doc(key);
  }

  schoolYearRef(schoolKey: string, campusKey: string) {
    return this.db.collection("stores").doc(schoolKey).collection("campus").doc(campusKey).collection("school_year", ref => ref.orderBy("order", "desc"));
  }

  schoolEducationLevelRef(schoolKey: string, campusKey: string, schoolYearKey: string) {
    return this.db.collection("stores").doc(schoolKey).collection("campus").doc(campusKey).collection("school_year").doc(schoolYearKey).collection("school_education_level", ref => ref.orderBy("order"));
  }

  capitalFeeRef(schoolKey: string, campusKey: string, schoolYearKey: string, levelKey: string) {
    return this.db.collection("stores").doc(schoolKey).collection("campus").doc(campusKey).collection("school_year").doc(schoolYearKey).collection("school_education_level").doc(levelKey).collection("capital_fee", ref => ref.orderBy("type.key"));
  }

  programLevelsRef(schoolKey) {
    return this.storeDocRef(schoolKey).collection("program_levels", ref => ref.orderBy("name"));
  }
  scholarshipTypeRef() {
    return this.db.collection("scholarship_type", ref => ref.orderBy("name"));
  }
  scholarshipApprovalRef(schoolKey: string) {
    return this.db.collection("stores").doc(schoolKey).collection("scholarship_approval");
  }
  subjectRef(ref?) {
    return this.db.collection("subjects", ref)
  }
  subjectDBRef() {
    return this.db.collection("subjects", ref => ref.orderBy("name_en"));
  }
  educationLevelRef() {
    return this.db.collection("education_levels", ref => ref.orderBy("order"));
  }

  otherServiceFeeRef(schoolKey: string) {
    return this.db.collection("stores").doc(schoolKey).collection("admission_service_other");
  }

  otherServiceFeeDocRef(schoolKey: string, key: string) {
    return this.db.collection("stores").doc(schoolKey).collection("admission_service_other").doc(key);
  }

  examCategoryRef() {
    return this.db.collection("exam_categories", ref => ref.orderBy("order"));
  }

  exerciseRef(subjectKey) {
    return this.db.collection('subject_score').doc(subjectKey).collection('exercises', ref => ref.orderBy("order"))
  }

  subjectScoreByGradeRef(schoolKey, key: string) {
    return this.db.collection("stores").doc(schoolKey).collection("subject_score", ref => ref.where("grade.key", "==", key));
  }

  transcriptTemplateRef(schoolKey) {
    return this.db.collection("stores").doc(schoolKey).collection("transcript_templates");
  }

  transcriptTemplateContainersRef(schoolKey, templateKey) {
    return this.db.collection("stores").doc(schoolKey).collection("transcript_templates").doc(templateKey).collection("transcript_template_containers");
  }

  subjectScoreRef() {
    return this.db.collection("subject_score");
  }
  studentAllAdmissionRef(key) {
    return this.db.collection("academics_major_admission", ref =>
      ref.where("student.key", "==", key)
    );
  }

  admissionRef() {
    return this.db.collection("academics_major_admission");
  }

  academicGradeRef() {
    return this.db.collection("academic_grade", ref => ref.orderBy("order"));
  }

  shiftDBRef() {
    return this.db.collection("academics_shift");
  }

  trainingSchoolFeeRef(campusKey, levelKey) {
    return this.db.collection("campus").doc(campusKey).collection("training_levels").doc(levelKey).collection("training_school_fee");
  }
  resourceEducationGradeRef() {
    return this.db.collection("resources_education_grades", ref => ref.orderBy("order"));
  }
  resourceEducationSubjectScoreByGradeRef(key: string) {
    return this.db.collection("resources_education_subject_score", ref => ref.where("grade.key", "==", key));
  }
  academicFireRef() {
    return this.firestore().collection("academic_environment").doc("academic_environment");
  }
  campusFireRef() {
    return this.firestore().collection("campus");
  }

  instructorDocRef(key) {
    return this.db.collection("instructors").doc(key);
  }
  receiptWingEPaymentRef(fromDate: number, toDate: number) {
    return this.db.collection("invoices", ref => ref
      .where("payment_type.key", '==', 2)
      .where("isHeader", "==", true)
      .where("isPaid.key", "==", 1)
      .where("received_date_key", ">=", fromDate)
      .where("received_date_key", "<=", toDate)
      .orderBy("received_date_key", "desc")
    )
  }

  studentInvoiceRef(studentKey) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref.where("isHeader", "==", true));
    // return this.db.collection('students').doc(studentKey).collection<any>("invoices", ref => ref.where("isEnrollVerify", "==", true).where("isPaid.key", "==", paymentStatus.paid.key));
  }

  studentInstallmentRef(studentKey) {
    return this.db.collection("students").doc(studentKey).collection("student_installment", ref => ref.orderBy("create_date", "desc"));
  }

  studentImportPaymentRef(schoolKey, key) {
    return this.db.collection("stores").doc(schoolKey).collection("import_student_payment").doc(key);
  }

  creditNoteFireRef() {
    return this.firestore().collection("credit_note")
  }

  termFireRef() {
    return this.firestore().collection("academic_year");
  }

  termDBRef() {
    return this.db.collection("academic_year");
  }

  instituteScheduleRef() {
    return this.firestore().collection("institute_schedules");
  }

  englishPaidInvoiceRef(termKey) {
    return this.db.collection("invoices", ref => ref
      .where("isPaid.key", "==", paymentStatus.paid.key)
      .where("invoice_type.key", "==", 2)
      .where("school_fee_type.key", "==", 3)
      .where("target_term.key", "==", termKey)
      .where("isFreshmen", "==", true)
      .where("isHeader", "==", false)
      .where("program.key", "==", "DOIEIbAZ6hjcXLB3p1lo")
      .where("dailyShift.status.key", "==", 1));
  }

  englishPaidInvoiceTestingRef(termKey) {
    return this.db.collection("invoices", ref => ref
      .where("isPaid.key", "==", paymentStatus.paid.key)
      .where("invoice_type.key", "==", 1)
      .where("school_fee_type.key", "==", 3)
      .where("issue_term.key", "==", termKey)
      .where("isHeader", "==", false)
      .where("program.key", "==", "ysrk2Kb4u65eexcv13Vi")
      .where("dailyShift.status.key", "==", 1));
  }

  paidInvoiceRef(termKey) {
    return this.db.collection("invoices", ref => ref
      .where("isPaid.key", "==", paymentStatus.paid.key)
      .where("invoice_type.key", "==", 2)
      .where("school_fee_type.key", "==", 1)
      .where("issue_term.key", "==", termKey)
      .where("isHeader", "==", true)
      .where("dailyShift.status.key", "==", 1));
  }

  batchStudentMovementRef() {
    return this.db.collection("institute_student_batch_movement", ref => ref.limit(5000));
  }

  programRef() {
    return this.db.collection<any>("testType", ref => ref.orderBy("name"));
  }

  levelTestTypeRef() {
    return this.db.collection("institute_training_level", ref => ref.orderBy("name"));
  }

  instituteTermDocsRef(instituteKey) {
    return this.db.collection("academic_year", ref => ref
      .where("institute.key", "==", instituteKey)
    )
  }


  offerScheduleBatchFireRef(schoolKey: string) {
    return this.storeFireRef(schoolKey).collection("offer_schedule_batches")
  }

  offerScheduleBatchCalendarRef(schoolKey: string) {
    return this.storeDocRef(schoolKey).collection("schedule_movements")
  }

  promoteBatchLevelFireRef() {
    return this.firestore().collection("institute_promote_level_batch")
  }

  batchLevelFireRef() {
    return this.firestore().collection("institute_training_level_batch")
  }

  studentBatchEnrollmentFireRef() {
    return this.firestore().collection("student_batch_enrollment_invoices")
  }

  studentBatchMovementFireRef() {
    return this.firestore().collection("student_batch_movement");
  }

  batchLevelRef() {
    return this.db.collection("institute_training_level_batch")
  }

  trainingLevelsRef(campusKey, levelKey) {
    return this.db.collection("campus").doc(campusKey).collection("training_levels").doc(levelKey)
  }
  trainingLevelsByGradeRef(campusKey) {
    return this.db.collection("campus").doc(campusKey).collection("training_levels")
  }

  studentRegistrationRef() {
    return this.db.collection("student_registration", ref => ref.where("subject_type.key", "==", 2))
  }

  studentRegistrationFireRef() {
    return this.firestore().collection("student_registration")
  }

  employeeUpdateRef() {
    return this.db.collection("employees", ref => ref
      // .where("status.key", "==", 1).limit(1)
      .orderBy("code")
    )
  }

  batchLevelWrongRef() {
    return this.db.collection("institute_training_level_batch", ref => ref.where("level.program.key", "==", "WgANgpmKCDMbF01F0IM2"))
  }

  batchLevelByCampusRef() {
    return this.db.collection("institute_training_level_batch", ref => ref.where("campus.key", "==", "IR9EP8UXpSoSpQuUxWmn"))
  }

  batchLevelDBRef() {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("status.key", "==", 1)
    );
  }

  batchLevelDBTKRef() {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("promoted_date_key", ">=", 20191211)
      .where("promoted_date_key", "<=", 20191216)
      .orderBy("promoted_date_key")
    );
  }

  batchLevelGradingRef() {
    return this.db.collection("institute_training_level_grading");
  }

  batchLevelGradingFireRef() {
    return this.firestore().collection("institute_training_level_grading");
  }

  termAcademicRef() {
    return this.db.collection("academic_year");
  }

  institutePromoteLevelBatchRef(termKey) {
    return this.db.collection("institute_promote_level_batch", ref => ref.where("term.key", "==", termKey));
  }

  invoicePaidRef() {
    return this.db.collection("invoices", ref => ref
      .where("isHeader", "==", true)
      .where("isPaid.key", "==", paymentStatus.paid.key)
      .where("received_date_key", ">=", 20191030)
      .where("received_date_key", "<=", 20191101)
      .orderBy("received_date_key")
    );
  }

  termAcademicReportRef(schoolKey: string) {
    return this.db.collection("stores").doc(schoolKey).collection("academic_year", ref => ref.orderBy("startDateKey", "desc").limit(50));
  }

  studentDoc(key) {
    return this.db.collection('students').doc<any>(key)
  }


  scholarship_penaltyRef(key) {
    return this.db.collection<any>("students").doc<any>(key).collection('scholarship_penalty', ref => ref.where('isPaid', '==', false));
  }
  allscholarshipRef(key) {
    return this.db.collection<any>('scholarships', ref => ref
      .where('student.key', '==', key)
    );
  }
  studentTranscriptRef(studentKey, admissionKey) {
    return this.db.collection("academic_student_transcript").doc(studentKey).collection("admission").doc(admissionKey).collection("courses");
  }

  studentTranscriptTemplateRef(studentKey, admissionKey) {
    return this.db.collection("academic_student_transcript").doc(studentKey).collection("admission").doc(admissionKey).collection("courses_template");
  }

  batchstudentRef(key) {
    return this.db.collection("institute_training_level_batch").doc(key).collection("students")

  }

  studentKeyRef(key) {
    return this.db.collection("students").doc<any>(key);
  }

  studentRef(schoolKey: string, field: string) {
    return this.db.collection("students", ref => ref
      .where("schoolKey", "==", schoolKey)
      .orderBy(field)
      .limit(Pages.size)
    );
  }


  bookStoreRef(schoolKey: string, field: string) {
    return this.db.collection('stores').doc(schoolKey).collection("book_store_books", ref => ref
      // .where("schoolKey", "==", schoolKey)
      .orderBy(field)
      .limit(Pages.size)
    );
  }
  studentTransfer(studentKey: string) {
    return this.db.collection('academics_student_transfer', ref => ref.where("student.key", "==", studentKey))
  }

  studentFetchRef(field: string, search: string, lastVisible: any) {
    if (search) {
      return this.db.collection("students", ref =>
        ref
          .where(field, ">=", search)
          .orderBy("page_key", "desc")
          .orderBy(field)
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("students", ref =>
        ref
          .orderBy(field)
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    }
  }
  trainingFeeRef() {
    return this.db.collection("academic_training_grade_movement");
  }
  admissionDBRef() {
    return this.db.collection("academics_major_admission");
  }
  admissionFireRef() {
    return this.firestore().collection("academics_major_admission");
  }
  admissionStudentRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref.where("student.key", "==", studentKey));
  }
  studentTranscriptFire() {
    return this.firestore().collection('academic_student_transcript')
  }
  studentTransferFire() {
    return this.firestore().collection('academics_student_transfer')
  }
  studentAdmissionTranscriptFire(studentKey) {
    return this.firestore().collection('academic_student_transcript').doc(studentKey).collection("admission")
  }

  studentSearchRef(schoolKey, field: string, search: string) {
    if (!search) {
      return this.db.collection("students", ref =>
        ref
          .where("schoolKey", "==", schoolKey)
          .orderBy(field,)
          .limit(Pages.size)
      );
    } else {
      const text = search.toUpperCase();
      return this.db.collection("students", ref =>
        ref
          .where("schoolKey", "==", schoolKey)
          .where(field, ">=", text)
          .orderBy(field)
          .limit(Pages.size)
      );
    }

  }

  bookSearchRef(schoolKey, field: string, search: string) {
    const keyword = search ? ConvertService.toCapitalize(search) : '~N/A~';
    console.log(keyword, schoolKey, field);

    return this.db.collection('stores').doc(schoolKey).collection("book_store_books", ref =>
      ref
        .where("keyword", 'array-contains', keyword)
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  searchBookDataRef(schoolKey, field: string, search: string) {
    const keyword = search ? ConvertService.toCapitalize(search) : '~N/A~';
    return this.db.collection('stores').doc(schoolKey).collection("book_library", ref =>
      ref
        .where("keyword", 'array-contains', keyword)
        .orderBy(field)
        .limit(Pages.size)
    );
  }
  // searchBookInventoryDataRef(schoolKey, field: string, search: string) {
  //   const keyword = search ? ConvertService.toCapitalize(search) : '~N/A~';
  //   return this.db.collection('stores').doc(schoolKey).collection("book_library_inventory", ref =>
  //     ref
  //       .where("inventory_status.key", '==', 1)
  //       .where("keyword", 'array-contains', keyword)
  //       .orderBy(field)
  //       .limit(Pages.size)
  //   );
  // }
  searchBookInventoryDataRef(schoolKey, field: string, search: string, campusKey) {
    const keyword = search ? ConvertService.toCapitalize(search) : '~N/A~';
    return this.db.collection('stores').doc(schoolKey).collection("book_library_inventory", ref => {
      let condition = ref
        .where("inventory_status.key", '==', 1)
        .where("keyword", 'array-contains', keyword)
        .where('isDelete', '==', false)
        .orderBy(field).limit(Pages.size)
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    })
  }
  searchBooksDeviceInventoryDataRef(schoolKey, field: string, search: string, typeKey: any, campusKey: any) {
    const keyword = search ? ConvertService.toCapitalize(search) : '~N/A~';
    return this.db.collection('stores').doc(schoolKey).collection("book_library_inventory", ref => {
      let condition = ref
        .where("inventory_status.key", '==', 1)
        .where("keyword", 'array-contains', keyword)
        .where('isDelete', '==', false)
        .orderBy(field)
        .limit(Pages.size);
      if (schoolKey !== 'ngs') {
        condition = condition.where('itemType', '==', typeKey)
      }
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    }

    );
  }
  searchBooksBasicInventoryDataRef(schoolKey, field: string, search: string, typeKey: any, campusKey: any, grade_exam_key: any,) {
    const keyword = search ? ConvertService.toCapitalize(search) : '~N/A~';
    return this.db.collection('stores').doc(schoolKey).collection("book_library_inventory", ref => {
      let condition = ref
        .where("inventory_status.key", '==', 1)
        .where("keyword", 'array-contains', keyword)
        .where('isDelete', '==', false)
        .orderBy(field)
        .limit(Pages.size);
      if (schoolKey !== 'ngs') {
        condition = condition.where('itemType', '==', typeKey)
      }
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (grade_exam_key) {
        condition = condition.where('gradeKey', '==', grade_exam_key)
      }
      return condition
    }

    );
  }

  dailyShiftPaymentDocRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("shift_movements", ref => ref
      .where("create_by.key", "==", uid)
      .where("create_date_key", ">=", fromDate)
      .where("create_date_key", "<=", toDate)
      .orderBy("create_date_key", "desc")
    );
  }

  dailyTestingRef(fromDate: number, toDate: number, campusKey: string, programKey: string) {
    return this.db.collection('testing', ref => {
      // let fromDateKey = moment(new Date()).startOf('day').toDate();;
      // let toDateKey = moment(new Date()).endOf('day').toDate();
      let condition = ref
        .where("status.key", "==", 1)
        .where('create_date', ">=", fromDate)
        .where('create_date', "<=", toDate)
      // .orderBy('create_date', "desc")
      if (campusKey) {
        condition = condition.where("campus.key", "==", campusKey)
      }
      if (programKey) {
        condition = condition.where("programLevel.program.key", "==", programKey)
      }
      return condition
    })
  }

  scholarshipsListingRef(fromDate: number, toDate: number, campusKey: string, programKey: string) {
    return this.db.collection('scholarships', ref => {
      // let fromDateKey = moment(new Date()).startOf('day').toDate();;
      // let toDateKey = moment(new Date()).endOf('day').toDate();
      let condition = ref
        .where("status.key", "==", 1)
        .where('create_date', ">=", fromDate)
        .where('create_date', "<=", toDate)
      // .orderBy('create_date', "desc")
      if (campusKey) {
        condition = condition.where("campus.key", "==", campusKey)
      }
      if (programKey) {
        condition = condition.where("scholarship.key", "==", programKey)
      }
      return condition
    })
  }

  requestPenaltyRef(schoolKey: string, campus: any, fromDate: any, toDate: any) {
    if (campus) {
      return this.db.collection("stores").doc(schoolKey).collection("campus").doc(campus.key).collection("request_penalty", ref => ref
        .where("update_date", ">=", fromDate)
        .where("update_date", "<=", toDate)
        .orderBy("update_date", "desc")
      );
    } else {
      return this.db.collection("stores").doc(schoolKey).collection("request_penalty", ref => ref
        .where("update_date", ">=", fromDate)
        .where("update_date", "<=", toDate)
        .orderBy("update_date", "desc")
      );
    }
  }

  requestVoidRef(schoolKey: string, campus: any, fromDate: any, toDate: any) {
    if (campus) {
      return this.db.collection("stores").doc(schoolKey).collection("campus").doc(campus.key).collection("request_void", ref => ref
        .where("update_date", ">=", fromDate)
        .where("update_date", "<=", toDate)
        .orderBy("update_date", "desc")
      );
    } else {
      return this.db.collection("stores").doc(schoolKey).collection("request_void", ref => ref
        .where("update_date", ">=", fromDate)
        .where("update_date", "<=", toDate)
        .orderBy("update_date", "desc")
      );
    }
  }

  dailyShiftPaymentRef(fromDate: number, toDate: number, campusKey: string) {
    if (campusKey) {
      return this.db.collection("shift_movements", ref => ref
        .where("start_cashier.campus.key", "==", campusKey)
        .where("create_date_key", ">=", fromDate)
        .where("create_date_key", "<=", toDate)
        .orderBy("create_date_key", "desc")
      );
    } else {
      return this.db.collection("shift_movements", ref => ref
        .where("create_date_key", ">=", fromDate)
        .where("create_date_key", "<=", toDate)
        .orderBy("create_date_key", "desc")
      );
    }

  }

  dailyShiftPaymentCampusRef(campuskey, fromDate: number, toDate: number) {
    return this.db.collection("shift_movements", ref => ref
      .where("start_cashier.campus.key", "==", campuskey)
      .where("create_date_key", ">=", fromDate)
      .where("create_date_key", "<=", toDate)
      .orderBy("create_date_key", "desc")
    );
  }

  // importStudentPaymentRef(campusKey: string, campusKey: string, campusKey: string) {
  //   return this.db.collection("shift_movements", ref => ref
  //     .where("start_cashier.campus.key", "==", campuskey)
  //     .where("create_date_key", ">=", fromDate)
  //     .where("create_date_key", "<=", toDate)
  //     .orderBy("create_date_key", "desc")
  //   );
  // }

  installmentPaymentRef(fromDate: number, toDate: number) {
    return this.db.collection("installment_movement", ref => ref
      // .where("received_by.key", "==", uid)
      .where("received_date_key", ">=", fromDate)
      .where("received_date_key", "<=", toDate)
      .orderBy("received_date_key", "desc")
    );
  }
  expensePaymentDocRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("petty_cash", ref => ref
      .where("createBy.key", "==", uid)
      .where("datePaymentKey", ">=", fromDate)
      .where("datePaymentKey", "<=", toDate)
      .orderBy("datePaymentKey", "desc")
    );
  }
  expensePaymentRef(fromDate: number, toDate: number, campusKey: string) {
    if (campusKey) {
      return this.db.collection("petty_cash", ref => ref
        .where("campus.key", "==", campusKey)
        .where("datePaymentKey", ">=", fromDate)
        .where("datePaymentKey", "<=", toDate)
        .orderBy("datePaymentKey", "desc")
      );
    } else {
      return this.db.collection("petty_cash", ref => ref
        .where("datePaymentKey", ">=", fromDate)
        .where("datePaymentKey", "<=", toDate)
        .orderBy("datePaymentKey", "desc")
      );
    }
  }

  matchTermPaymentRef(fromDate: number, toDate: number, campusKey: string) {
    if (campusKey) {
      return this.db.collection("student_align_payment", ref => ref
        .where("campus.key", "==", campusKey)
        .where("create_date_key", ">=", fromDate)
        .where("create_date_key", "<=", toDate)
        .orderBy("create_date_key", "desc")
      );
    } else {
      return this.db.collection("student_align_payment", ref => ref
        .where("create_date_key", ">=", fromDate)
        .where("create_date_key", "<=", toDate)
        .orderBy("create_date_key", "desc")
      );
    }
  }

  receiptReportByTermRef(fromDate: number, toDate: number, termKey: any, cashierKey: any) {
    let ref = this.db.collection("invoices", ref => {
      let colWhere = ref
        .where("isHeader", "==", true)
        .where("dailyShift.status.key", "==", 1)
        .where("isPaid.key", "==", paymentStatus.paid.key)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      if (cashierKey) {
        colWhere = colWhere.where("received_by.key", "==", cashierKey)
      }
      if (termKey) {
        colWhere = colWhere.where("issue_term.key", "==", termKey)
      }

      return colWhere.orderBy("received_date_key", "desc")
    });

    return ref;
  }

  receiptReportDocRef(fromDate: number, toDate: number, uid: string) {
    return this.db.collection("invoices", ref => ref
      .where("isHeader", "==", true)
      .where("isPaid.key", "==", 1)
      // .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
      .where("received_by.key", "==", uid)
      .where("received_date_key", ">=", fromDate)
      .where("received_date_key", "<=", toDate)
    );
  }

  receiptReportRef(fromDate: number, toDate: number, termKey: any, cashierKey: any) {

    if (termKey && !cashierKey) {
      return this.db.collection("invoices", ref => ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("issue_term.key", "==", termKey)
      );
    } else if (!termKey && cashierKey) {
      return this.db.collection("invoices", ref => ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_by.key", "==", cashierKey)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      );
    } else if (termKey && cashierKey) {
      return this.db.collection("invoices", ref => ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_by.key", "==", cashierKey)
        .where("issue_term.key", "==", termKey)
      );
    } else {
      return this.db.collection("invoices", ref => ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      );
    }

  }

  receiptSummaryReportRef(fromDate: number, toDate: number, termKey: any, cashierKey: any, programKey: any) {

    if (termKey && !cashierKey && !programKey) {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", true)
        .where("issue_term.key", "==", termKey)
      );
    } else if (!termKey && cashierKey && !programKey) {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", true)
        .where("received_by.key", "==", cashierKey)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      );
    } else if (termKey && !cashierKey && programKey) {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("issue_term.key", "==", termKey)
        .where("invoice_type.key", "==", programKey)
      );
    } else if (termKey && cashierKey && programKey) {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("received_by.key", "==", cashierKey)
        .where("issue_term.key", "==", termKey)
        .where("invoice_type.key", "==", programKey)
      );
    } else {
      return this.db.collection("invoices", ref => ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", true)
        .where("received_date_key", ">=", fromDate)
        .where("received_date_key", "<=", toDate)
      );
    }

  }

  userRef(uid: string) {
    return this.db.collection('users').doc<any>(uid);
  }

  userDBRef() {
    return this.db.collection('users');
  }

  userValidEmailRef(email) {
    return this.db.collection('users', ref => ref.where('email', '==', `${email}`.toLowerCase().trim()));
  }

  userFireSRef(uid: string) {
    return this.firestore().collection('users').doc(uid);
  }

  gradeSheetStudentBatchRef(batchKey, monthKey, gradeSheetTypeKey) {
    return this.db.collection("grade_sheet_batches", ref => {
      let condition = ref.where("batchKey", '==', batchKey)
      if (gradeSheetTypeKey < 4) {
        condition = condition.where("gradeSheet.gradeSheetType.key", '==', gradeSheetTypeKey)
      }
      if (gradeSheetTypeKey === 4) {
        condition = condition.where("gradeSheet.gradeSheetType.key", '>', 1).orderBy('gradeSheet.gradeSheetType.key')
      }
      if (gradeSheetTypeKey === 1) {
        condition = condition.where("gradeSheet.month.key", '==', monthKey)
      }
      return condition.orderBy('result', 'desc')
    });
  }


  gradeSheetStudentBatchMovementRef(batchKey, monthKey, gradeSheetTypeKey) {
    return this.db.collection("grade_sheet_student_movements", ref => {
      let condition = ref.where("batchKey", '==', batchKey)

      if (gradeSheetTypeKey < 4) {
        condition = condition.where("gradeSheet.gradeSheetType.key", '==', gradeSheetTypeKey)
      }
      if (gradeSheetTypeKey === 4) {
        condition = condition.where("gradeSheet.gradeSheetType.key", '>', 1).orderBy('gradeSheet.gradeSheetType.key')
      }

      if (gradeSheetTypeKey === 1) {
        condition = condition.where("gradeSheet.month.key", '==', monthKey)
      }
      return condition.orderBy('result', 'desc')
    });
  }

  gradeSheetsRef(batchKey) {
    return this.db.collection("grade_sheet_movements", ref => ref
      .where("batchKey", '==', batchKey)
    );
  }

  gradeSheetsFireRef() {
    return this.firestore().collection("grade_sheet_movements");
  }

  gradeSheetsDocRef(key) {
    return this.db.collection("grade_sheet_movements").doc(key);
  }

  gradeSheetsApprovedFireRef() {
    return this.firestore().collection("grade_sheet_movements_approved");
  }

  campusRef() {
    return this.db.collection("campus", ref => ref.orderBy("order"));
  }

  campusSchoolRef(schoolKey) {
    return this.db.collection("campus", ref => ref.where('schoolKey', '==', schoolKey));
  }

  campusBySchoolRef(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection("campus", ref => ref.orderBy("order"));
  }


  campusStoreRef(key) {
    return this.db.collection('stores').doc(key).collection("campus", ref => ref.orderBy("order"));
  }

  campusFirstRef() {
    return this.db.collection("campus", ref => ref.orderBy("order").limit(1));
  }

  schoolProgramsRef() {
    return this.db.collection("school_programs", ref => ref.orderBy("name"));
  }
  collectionDocRef(collectionName) {
    return this.firestore().collection(collectionName);
  }

  creditNoteRef(type: any) {
    if (type === 'all') {
      return this.db.collection('credit_note');
    } else if (type === 'paid') {
      return this.db.collection('credit_note', ref => ref.where('isPaid', '==', true));
    } else if (type === 'unpaid') {
      return this.db.collection('credit_note', ref => ref.where('isPaid', '==', false));
    }
  }

  shiftMovementDocRef(fromDate, toDate) {
    return this.db.collection('shift_movements', ref => ref
      .where("create_date_key", '>=', fromDate)
      .where("create_date_key", '<=', toDate)
    );
  }

  shiftMovementDBRef(key) {
    return this.db.collection("shift_movements").doc<any>(key);
  }

  userDocRef(uid: string) {
    return this.db.collection("users").doc<any>(uid);
  }

  settingDBFireStore() {
    return this.db.collection("testing_options").doc("general");
  }
  paymentOptionRef() {
    return this.db.collection("payment_options", ref => ref.orderBy("order"));
  }
  academicEnvRef() {
    return this.db.collection("academic_environment");
  }

  invoiceCashierRef(user: any) {
    return this.db.collection("invoices", ref =>
      ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", 1)
        .where("received_by.key", "==", user.key)
        .where("dailyShift.key", "==", user.shift.key)
        .orderBy("received_date_key")
    );
  }

  shiftSummaryFireRef() {
    return this.firestore().collection("shift_summary");
  }

  campusDocRef(doc) {
    return this.db.collection('campus').doc(doc);
  }

  // campusRef(schoolKey) {
  //   return this.db.collection('campus', ref=>ref.where('schoolKey','==',schoolKey);
  // }
  cashierRef() {
    return this.db.collection('users', ref => ref.where('role.key', '==', 3));
  }



  lazyUserRef(lastVisible: any, schoolKey, search, filter, isAdmin, campusKey, isAllCampus) {
    if (isAdmin) {
      return this.db.collection<any>("users", ref => {
        let condition = ref
          // .where("schoolKey", "==", schoolKey)
          .where("status.key", "==", 1)
          // .where("campus.key" ,"==", campusKey)
          .orderBy("full_name")
          .limit(100)
        if (search) {
          // const txt = ConvertService.toCapitalize(search)
          const txt = search.toUpperCase()
          condition = condition.where('full_name', '>=', txt)
        }
        if (lastVisible) {
          condition = condition.startAfter(lastVisible)
        }
        return condition
      })
    } else {
      return this.db.collection<any>("users", ref => {
        let condition = ref
          .where("schoolKey", "==", schoolKey)
          .where("status.key", "==", 1)
          .orderBy("full_name")
          .limit(100)
        if (!isAllCampus) {
          condition = condition.where("campus.key", "==", campusKey)
        }
        if (search) {
          // const txt = ConvertService.toCapitalize(search)
          const txt = search.toUpperCase()
          condition = condition.where('full_name', '>=', txt)
        }
        if (lastVisible) {
          condition = condition.startAfter(lastVisible)
        }
        return condition
      })
    }

  }

  lazyCashierRef(lastVisible: any, schoolKey, search, filter) {
    return this.db.collection<any>("users", ref => {
      let condition = ref
        .where("schoolKey", "==", schoolKey)
        .where("status.key", "==", 1)
        .where('role.key', '==', 3)
        .orderBy("full_name")
        .limit(100)
      if (search) {
        // const txt = ConvertService.toCapitalize(search)
        // condition = condition.where('keywords', 'array-contains', txt)
        const txt = search.toUpperCase()
        condition = condition.where('full_name', '>=', txt)
      }
      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }
      return condition
    })

  }
  cashierInvoiceRef(uid, invoice_type, fromDate, toDate) {
    switch (invoice_type) {
      case 0:
        return this.db.collection<any>('invoices', ref => ref
          .where('received_by.key', '==', uid)
          .where("received_date_key", '>=', fromDate)
          .where("received_date_key", '<=', toDate)
          .orderBy("received_date_key")
          .orderBy('invoice_no'));
      default:
        return this.db.collection<any>('invoices', ref => ref
          .where('received_by.key', '==', uid)
          .where("received_date_key", '>=', fromDate)
          .where("received_date_key", '<=', toDate)
          .orderBy("received_date_key")
          .orderBy('invoice_no'));
        break;
    }

    // return this.db.collection('invoices', ref => ref.where('issue_by.key', '==', userkey).where('role.key', '==', 3).where('invoice_type.key', '==', invoice_type).orderBy('issue_date'));
  }
  cashierDocRef(key) {
    return this.db.collection('users').doc(key);
  }

  sysProgramRef() {
    return this.db.collection("sys_programs", ref => ref
      .where("active", "==", true)
    )
  }
  termEnvironmentRef() {
    return this.firestore().collection("academic_environment").doc("academic_environment")
  }
  studentCurrentScheduleRef(termKey, studentKey) {
    return this.db
      .collection("students").doc(studentKey).collection("invoices", ref => ref
        .where("issue_term.key", "==", termKey)
        .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
        .where("isHeader", "==", false)
        .where("isVoid", "==", false)
      );
  }
  academicEnvironmentFireDocRef() {
    return this
      .firestore()
      .collection("academic_environment")
      .doc("academic_environment");
  }
  academicRef() {
    return this.db.collection("academic_environment").doc("academic_environment");
  }
  activeTermRef() {
    return this.db.collection('academic_year', ref => ref
      .where("status.key", "==", 2));
  }
  scholarshipReportRef(programkey, programtype, start, end, term) {
    let ref = this.firestore().collection('scholarships').where('date_key', '>=', Number(start)).where('date_key', '<=', Number(end)).where('enroll_term.key', '==', term.key).where('scholarship_type.key', '==', programtype.key)
    if (programkey != 'all') {
      return ref.where('program.key', '==', programkey)
    }
    return ref;
  }

  allscholarshipTypeRef(type, program) {
    if (program) {
      return this.db.collection<any>('scholarships', ref => ref.where('scholarshipType.key', '==', type).where('program.route', '==', program));
    } else {
      return this.db.collection<any>('scholarships', ref => ref.where('scholarshipType.key', '==', type));
    }
  }
  batch() {
    return this.db.firestore.batch();
  }
  invoiceFireRef() {
    return this.firestore().collection("invoices");
  }
  academicsShiftRef() {
    return this.db.collection("academics_shift");
  }
  studentFireDocument(key: string) {
    return this.firestore().collection("students").doc(key);
  }

  paymentDiscountRef() {
    return this.db.collection("payment_discount", ref => ref.where("status.key", "==", 1));
  }

  paymentDiscountDBRef() {
    return this.db.collection("payment_discount", ref => ref.orderBy("updated_at", "desc"));
  }

  studentDocument(key: string) {
    return this.db.collection("students").doc<any>(key);
  }

  studentSibling(studentKey: string) {
    return this.db.collection("students_sibling", ref => ref.where("studentKey", "array-contains", studentKey));
  }

  studentSiblingRef() {
    return this.db.collection("students_sibling");
  }

  studentTestRef(studentKey) {
    return this.db.collection("testing", ref =>
      ref
        .where("student.key", "==", studentKey)
        .orderBy("create_date", "desc")
        .limit(1)
    );
  }
  testingStudent(key: string) {
    return this.db.collection("testing").doc(key);
  }
  studentAdmissionRef() {
    return this.db.collection("academics_major_admission");
  }

  studentAdmissionDBRef(key) {
    return this.db.collection("academics_major_admission", ref =>
      ref
        .where("student.key", "==", key)
      // .orderBy("program.key", "desc")
      // .limit(1)
    );
  }
  scholarshipByStudent(Key) {
    return this.db.collection("scholarships", ref => ref.where("student.key", "==", Key));
  }
  admissionDocRef(admissionKey) {
    return this.db.collection<any>("academics_major_admission").doc<any>(admissionKey)
  }

  testingDBRef() {
    return this.db.collection("testing");
  }
  studentBatchMovement(termKey: string) {
    return this.db.collection("student_batch_movement", ref => ref.where("term.key", "==", termKey));
  }

  studentListByPUCID(id: string) {
    return this.db.collection("students", ref => ref
      .where("puc_id", ">=", id)
      .orderBy("puc_id", "desc")
      // .limit(10)
    );
  }

  studentPUCID(puc_id: string) {
    return this.db.collection("students", ref => ref.where("puc_id", "==", puc_id));
  }
  installmentFireRef() {
    return this.firestore().collection("installment_movement");
  }
  academicYearFireRef() {
    return this.firestore().collection("academic_year");
  }

  admissionDocFireRef(admissionKey) {
    return this.firestore().collection("academics_major_admission").doc(admissionKey)
  }

  academicYearRef(schoolKey) {
    return this.db.collection("stores").doc(schoolKey).collection("academic_year", ref => ref.orderBy("startDate", "desc").limit(30));
  }

  academicYearByProgramRef(schoolKey, termTypeKey) {
    return this.db.collection("stores").doc(schoolKey).collection("academic_year", ref => ref.where('termType.key', '==', termTypeKey));
  }

  academicYearDocRef(key) {
    return this.db.collection("academic_year").doc(key);
  }

  academicYearByTypeRef(termTypeKey) {
    return this.db.collection("academic_year", ref => ref.where('termType.key', '==', termTypeKey).orderBy('startDate').limit(30));
  }

  academicYearByTypeDBRef(schoolKey, typeKey) {
    return this.db.collection("stores").doc(schoolKey).collection("academic_year", ref => ref.where("termType.key", "==", typeKey));
  }

  academicYearDBRef() {
    return this.db.collection("academic_year", ref => ref.orderBy("startDate", "desc"));
  }

  studentAdmissionFireRef() {
    return this.firestore().collection("academics_major_admission");
  }
  update_admission_student_idFireRef() {
    return this.firestore().collection("update_admission_student_id");
  }
  studentAlignPaymentFireRef() {
    return this.firestore().collection("student_align_payment");
  }
  studentFireRef() {
    return this.firestore().collection("students");
  }
  invoiceFailFireRef() {
    return this.firestore().collection("invoice_fail");
  }
  employeeFireRef() {
    return this.firestore().collection("employees");
  }

  employeeRef() {
    return this.db.collection("employees");
  }
  employeeDocRef(key) {
    return this.db.collection("employees").doc(key);
  }
  instituteSessionRef() {
    return this.db.collection("institute_training_level_session", ref => ref.orderBy("name"));
  }
  studentAdmissionEnDocRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref
      .where("student.key", "==", studentKey)
      .where("admission_type.key", "==", 2)
    )
  }
  batchLevelShiftRef(levelKey, shiftKey, campusKey) {
    return this.db.collection("institute_training_level_batch", ref => ref
      .where("level.key", "==", levelKey)
      .where("shift.key", "==", shiftKey)
      .where("campus.key", "==", campusKey)
      .orderBy("name")
    )
  }
  transcriptFireRef() {
    return this.firestore().collection("academic_student_transcript");
  }
  instituteNoScheduleRef(studentKey) {
    return this.db.collection("institute_no_schedule", ref => ref.where("student.key", "==", studentKey));
  }
  admissionByStudentRef(studentKey: string) {
    return this.db.collection("academics_major_admission", ref => ref.where("student.key", "==", studentKey));
  }
  instituteNoScheduleStudentRef(studentKey) {
    return this.db.collection("institute_no_schedule", ref => ref.where("student.key", "==", studentKey))
  }
  scheduleInProgressStudentRef(studentKey) {
    return this.db.collection("schedule_in_progress", ref => ref.where("student.key", "==", studentKey))
  }
  scheduleInProgressFireRef() {
    return this.firestore().collection("schedule_in_progress")
  }
  userFireRef() {
    return this.firestore().collection("users");
  }

  dailyShiftFireRef() {
    return this.firestore().collection("shift_movements");
  }
  instructorSearchRef(instituteKey, field: string, search: any) {
    if (search) {
      if (search.key) {
        return this.db.collection("employees", ref => ref
          .where("key", "==", search.key)
        )
      }
      return this.db.collection("employees", ref => ref
        // .where(`${instituteKey}.institute.key`, '==', instituteKey)
        .where(field, ">=", search)
        .orderBy("full_name")
        .orderBy("page_key")
        .limit(Pages.size)
      )
    }
    return this.db.collection("employees", ref => ref
      // .where(`${instituteKey}.institute.key`, '==', instituteKey)
      .orderBy("page_key")
      .limit(Pages.size)
    )
  }
  instructorRef() {
    return this.db.collection("instructors");
  }
  academicFirebaseRef(schoolKey) {
    return this.storeRef(schoolKey)
      .collection("academic_environment")
      .doc("academic_environment");
  }
  academicGrade() {
    return this.db.collection("academic_grade", ref => ref.orderBy("name"));
  }

  instituteInstructorRef(schoolKey, search) {
    return this.db.collection("employees", ref => {
      let condition = ref.where("schoolKey", '==', schoolKey)
      if (search && search.key) {
        condition = condition.where('key', '==', search.key);
      }
      if (search && !search.key) {
        const text = search.toUpperCase();
        condition = condition.where('full_name', '>=', text)
      }
      return condition.orderBy("full_name").limit(Pages.size)
    })
  }

  gradeSystemProgramRef(programKey) {
    return this.db.collection("institute_grade_system", ref => ref.where('programKey', '==', programKey));
  }

  gradeSystemRef() {
    return this.db.collection("institute_grade_system");
  }




  classManagerFetchRef(
    programType: number,
    termKey: string,
    scheduleType: number,
    lastVisible: any
  ) {
    return this.db.collection<any>("academics_schedules", ref =>
      ref
        .where("isMaster", "==", true)
        .where("masterMajor.program.key", "==", programType)
        .where("scheduleType.key", "==", scheduleType)
        .where("term.key", "==", termKey)
        .orderBy("page_key", "desc")
        .startAfter(lastVisible.page_key)
        .limit(Pages.size)
    );
  }

  employeeListRef(schoolKey) {
    return this.db.collection("employees", ref => ref
      .where("schoolKey", "==", schoolKey)
      .orderBy("full_name")
      .limit(20));
  }

  instructorsListRef() {
    return this.db.collection("instructors", ref => ref.orderBy('full_name').limit(20));
  }
  englishClassroomSearchRef(schoolKey: string, field: string, search: any) {
    if (search) {
      if (search.key) {
        return this.db.collection("employees", ref => ref
          .where("key", "==", search.key)
        )
      }
      const text = search.toUpperCase()
      return this.db.collection("employees", ref => ref
        .where("schoolKey", "==", schoolKey)
        .where(field, ">=", text)
        .orderBy(`${field}`)
        .limit(Pages.size)
      )
    }
    return this.db.collection("employees", ref => ref
      .where("schoolKey", "==", schoolKey)
      .orderBy("full_name")
      .limit(Pages.size)
    )
  }
  academicClassroomSearchRef(field: string, search: any) {
    if (search) {
      if (search.key) {
        return this.db.collection("instructors", ref => ref
          .where("key", "==", search.key)
        )
      }
      return this.db.collection("instructors", ref => ref
        .where(field, ">=", search)
        .orderBy(`${field}`)
        .limit(Pages.size)
      )
    }
    return this.db.collection("instructors", ref => ref
      .orderBy("page_key")
      .limit(Pages.size)
    )
  }
  // studentInvoiceByAdmission(admissionKey: string, studentKey: string) {
  //   return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
  //     // .where("program.admissionKey", "==", admissionKey)
  //     // .where("isEnrollVerify", "==", true)
  //     .where("isPaid.key", "==", paymentStatus.unpaid.key)
  //   );
  // }

  studentInvoiceByAdmission(admissionKey: string, studentKey: string, invoiceKey) {
    if (invoiceKey) {
      return this.db.collection("students")
        .doc(studentKey)
        .collection("invoices", ref => ref
          .where("headerRef", "==", invoiceKey)
          .where("isPaid.key", "==", paymentStatus.unpaid.key)
        );
    }
    else {
      return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
        .where("isEnrollVerify", "==", true)
        .where("isPaid.key", "==", paymentStatus.unpaid.key)
        .where("program.admissionKey", "==", admissionKey)
        .orderBy("page_key")
      );
    }

    // if (admissionKey) {
    //   return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
    //     .where("isEnrollVerify", "==", true)
    //     .where("isPaid.key", "==", paymentStatus.unpaid.key)
    //     .where("program.admissionKey", "==", admissionKey)
    //     .orderBy("page_key")
    //   );
    // } else {
    //   return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
    //     .where("isEnrollVerify", "==", true)
    //     .where("isPaid.key", "==", paymentStatus.unpaid.key)
    //     .orderBy("page_key")
    //   );
    // }

  }

  studentTestingRef(studentKey) {
    return this.db.collection("testing", ref => ref
      .where("student.key", "==", studentKey));
  }

  studentTestingInvoice(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
      .where("invoice_type.key", "==", invoiceTypesObj.registrationFee.key)
      .where("isPaid.key", "==", paymentStatus.unpaid.key)
    );
  }
  invoiceAllByStudentKeyRef(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref.where("isHeader", "==", true).where("isPaid.key", "==", paymentStatus.paid.key));
  }

  miscellaneousFeeRef() {
    return this.db.collection("admission_services", ref => ref.where("program.name", "==", "Miscellaneous"));
  }

  paymentTermRef(key: string) {
    return this.db.collection("academic_environment").doc(key);
  }

  invoiceRef(Id: string, status: string, currentDate: number) {
    if (status === "testing-fees") {
      return this.db.collection<any>("invoices", ref =>
        ref.where("student.serial_id", "==", Id).where("isVoid", "==", false)
      );
    } else if (status === "abc-course") {
      return this.db.collection<any>("invoices", ref =>
        ref
          .where("student.puc_id", "==", Id)
          .where("isVoid", "==", false)
          .where("invoice_type.key", "==", invoiceTypesObj.abcCourse.key)
      );
    } else if (status === "tuition-fees") {
      return this.db.collection<any>("invoices", ref =>
        ref
          .where("student.puc_id", "==", Id)
          .where("isVoid", "==", false)
          // .where('invoice_type.key', '==', invoiceTypesObj.tuitionFee.key)
          .orderBy("page_key", "desc")
      );
    }
  }

  invoiceItemsRef(studentKey: string, headerRef: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref =>
      ref.where("headerRef", "==", headerRef)
    );
  }
  studentScholarshipRef(Id: string) {
    return this.db.collection<any>("students", ref =>
      ref.where("puc_id", "==", Id).limit(1)
    );
  }
  receivedPaymentFireRef() {
    return this.firestore().collection("received_payment_movement");
  }
  invoicesFireRef() {
    return this.firestore().collection("invoices");
  }

  invoicesReportRef() {
    //Academic Invoice
    // return this.db.collection("invoices", ref => ref.where("invoice_type.key", "==", 2).where("school_fee_type.key", "==", 1).where("isPaid.key", "==", 1).where("isHeader", "==", true));
    //English Invoice
    return this.db.collection("invoices", ref => ref.where("invoice_type.key", "==", 2).where("school_fee_type.key", "==", 3).where("isPaid.key", "==", 1).where("isHeader", "==", true));
  }

  invoicesEnglishTestingProgramRef(termKey?: string) {
    return this.db.collection('academic_year').doc(termKey).collection("invoices", ref => ref
      .where("invoice_type.key", "==", 1)
      .where("school_fee_type.key", "==", 3)
      .where("isPaid.key", "==", 1)
      .where("isHeader", "==", true)
      .where("issue_term.key", "==", termKey)
    );
  }

  invoicesEnglishTestingProgramByCampusRef(termKey: string, campusKey: string) {
    return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("invoices", ref => ref
      .where("invoice_type.key", "==", 1)
      .where("school_fee_type.key", "==", 3)
      .where("isPaid.key", "==", 1)
      .where("isHeader", "==", true)
      .where("issue_term.key", "==", termKey)
    );
  }

  EnglishTestingProgramRef(termKey?: string) {
    return this.db.collection('academic_year').doc(termKey).collection("testing");
  }

  EnglishTestingProgramByCampusRef(termKey: string, campusKey: string) {
    return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("testing");
  }

  invoicesEnglishProgramRef(termKey: string, campusKey: string) {
    if (campusKey) {
      return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("invoices", ref => ref.where("isHeader", "==", true).where("isPaid.key", "==", paymentStatus.paid.key));
    } else {
      return this.db.collection('academic_year').doc(termKey).collection("invoices", ref => ref.where("isHeader", "==", true).where("isPaid.key", "==", paymentStatus.paid.key));
    }
  }
  invoicesEnglishStudentNewRef(termKey: string, campusKey: string) {
    if (campusKey) {
      return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("admission", ref => ref.where('isFreshman', '==', true));
    } else {
      return this.db.collection('academic_year').doc(termKey).collection("admission", ref => ref.where('isFreshman', '==', true));
    }
  }
  invoicesEnglishStudentOldRef(termKey: string, campusKey: string) {
    if (campusKey) {
      return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("admission", ref => ref.where('isFreshman', '==', false));
    } else {
      return this.db.collection('academic_year').doc(termKey).collection("admission", ref => ref.where('isFreshman', '==', false));
    }
  }


  invoicesEnglishStudentUnpaidRef(termKey: string, campusKey: string) {
    if (campusKey) {
      return this.db.collection('academic_year').doc(termKey).collection('campus').doc(campusKey).collection("admission", ref => ref.where('paymentPeriod', '<', 12));
    } else {
      return this.db.collection('academic_year').doc(termKey).collection("admission", ref => ref.where('paymentPeriod', '<', 12));
    }
  }

  invoicesEnglishTestingRef() {
    const termKey = "f1XyZmFRGjlUbtc8KQ84";
    return this.db.collection("invoices", ref => ref
      .where("invoice_type.key", "==", 1)
      .where("school_fee_type.key", "==", 3)
      .where("isPaid.key", "==", 1)
      .where("isHeader", "==", true)
      .where("issue_term.key", "==", termKey)
    );
  }

  registrarFireRef() {
    return this.firestore().collection("registrar_payment");
  }
  studentPucProfileFireRef() {
    return this.firestore().collection("student_puc_profile");
  }

  studentByCampusRef() {
    return this.db.collection("students", ref => ref
      .where("campus.key", "==", "qh0LUi9E0jZB5cduWLJA")
      .limit(490)
    );
  }

  studentAccountRef() {
    return this.db.collection("student_accounts", ref => ref
      .where("status.text", "==", "Actives")
      .limit(490)
    );
  }
  studentAccountFire() {
    return this.firestore().collection("student_accounts");
  }
  studentAccountDocFire() {
    return this.firestore().collection("student_accounts");
  }

  studentAccountDoc(key) {
    return this.db.collection("student_accounts", ref => ref.where("student.key", "==", key));
  }

  studentAccID(pucId) {
    return this.db.collection("students", ref => ref.where("puc_id", "==", pucId));
  }

  studentAccountDocRef(key: string) {
    return this.db.collection("student_accounts").doc<any>(key);
  }

  studentCurrentScheduleTermRef(termKey, studentKey) {
    return this.db.collection("academic_year").doc(termKey).collection("schedules", ref => ref
      .where("enrollmentStudentKey", "array-contains", studentKey)
      .orderBy("schedule_subject.subject.name")
    );
  }
  scheduleInProgressRef(selectedInstituteKey, selectedShiftKey, selectedCampusKey, termKey, levelKey) {
    return this.db.collection("schedule_in_progress", ref => ref
      .where("institute.key", "==", selectedInstituteKey)
      .where("study_session.key", "==", selectedShiftKey)
      .where("target_campus.key", "==", selectedCampusKey)
      .where("target_term.key", "==", termKey)
      .where("testing_result.level.key", "==", levelKey)
      .orderBy("student.full_name")
    );
  }
  studentInstituteNoScheduleRef(selectedInstituteKey, selectedShiftKey, selectedCampusKey, termKey, levelKey) {
    return this.db.collection("institute_no_schedule", ref => ref
      .where("program.category.key", "==", selectedInstituteKey)
      .where("study_session.key", "==", selectedShiftKey)
      .where("target_campus.key", "==", selectedCampusKey)
      .where("target_term.key", "==", termKey)
      .where("subject.key", "==", levelKey)
      .where("isPaid", "==", true)
      .orderBy("student.full_name")
    );
  }
  instituteProgramRef(instituteKey) {
    return this.db.collection("testType", ref => ref.where("institute.key", "==", instituteKey))
  }
  academicQuitStudentsFireRef() {
    return this.firestore().collection("academic_quit_students");
  }
  abcStudentRef() {
    return this.firestore().collection("abc_students");
  }

  prepaidFireRef() {
    return this.firestore().collection("prepaid_movement");
  }
  shiftMovementFireRef() {
    return this.firestore().collection("shift_movements");
  }
  testingFireRef() {
    return this.firestore().collection("testing");
  }
  expiredFeeDate(admisionDate: Date, config: TestOption) {
    return moment(admisionDate)
      .add(config.expired_fee_peroid, "days")
      .toDate();
  }
  invoiceItemsByStudentKeyRef(studentKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref =>
      ref
        .where("isHeader", "==", true)
        .where("isPaid.key", "==", paymentStatus.paid.key)
        .orderBy("create_date", "desc")
        .limit(1)
    );
  }

  invoiceItemsByInvoiceNoRef(invoiceNo: number) {
    return this.db.collection<any>("invoices", ref =>
      ref
        .where("invoice_no", "==", invoiceNo)
        .where("isHeader", "==", true)
        // .orderBy("page_key", "desc")
        .limit(1)
    );
  }

  getInvoiceHeaderRef(studentKey: string, admissionKey: string) {
    return this.db.collection("students").doc(studentKey).collection("invoices", ref => ref
      .where("isHeader", "==", true)
      .where("program.admissionKey", "==", admissionKey)
      .where("isPaid.key", "==", paymentStatus.unpaid.key)
      .orderBy("create_date", "desc")
      .limit(1));
  }
  instituteActiveTermRef(key: string) {
    return this.db.collection("academic_environment").doc<any>(key);
  }
  settingFireStore() {
    return this
      .firestore()
      .collection("testing_options")
      .doc("general");
  }
  sysOption(schoolKey) {
    return this.db.collection("stores").doc(schoolKey).collection("sys_option").doc("general");
  }
  sysSetting() {
    return this.db.collection("testing_options").doc("general");
  }
  invoiceTestingRef(Id: string) {
    return this.db.collection<any>('invoices', ref => ref.where('student.serial_id', '==', Id))
  }
  createId() {
    return this.db.createId();
  }
  shiftMovementRef() {
    return this.db.collection('shift_movements', ref => ref.where("status.key", "==", 2));
  }

  shiftMovementSummaryRef() {
    return this.db.collection('shift_summary', ref => ref.where("allocated_status.key", "<", 2));
  }
  shiftMovementSummaryByCampusRef(campusKey) {
    return this.db.collection('shift_summary', ref => ref.where('campus.key', '==', campusKey).where("allocated_status.key", "<", 2));
  }
  shiftMovementSummaryDocRef(doc) {
    return this.db.collection('shift_summary', ref => ref.where("allocated_status.key", "<", 2)).doc(doc);
  }

  shiftMovementFirRef() {
    return this.firestore().collection('shift_movements');
  }
  shiftMovementSummaryFirRef() {
    return this.firestore().collection('shift_summary');
  }

  instituteRef() {
    return this.db.collection("institutes", ref => ref.orderBy("page_key"));
  }
  termReportRef() {
    return this.db.collection("academic_year", ref => ref
      .where("term_type.key", "==", 2)
      .orderBy("page_key", "desc").limit(10)
    );
  }

  fetchtermRef() {
    return this.db.collection('academic_year', ref => ref
      // .orderBy('page_key', 'desc')
      .limit(12))
  }

  programByInstituteRef(key) {
    return this.db.collection<any>("testType", ref =>
      ref.where("institute.key", "==", key)
        .orderBy("shortName")
    );
  }

  getTestOptions() {
    return this.db.collection("testing_options").doc<any>("general");
  }

  getTestOptionFireSRef() {
    return this.firestore().collection("testing_options").doc("general");
  }

  firestore() {
    return this.db.firestore;
  }

  invoiceByShiftRef(shiftKey: string) {
    return this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", true)
        .orderBy("page_key", "desc")
    );
  }

  invoiceByDetailShiftRef(shiftKey: string) {
    return this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("isHeader", "==", false)
        .orderBy("page_key", "desc")
    );
  }

  invoiceAllSummeryByShiftRef(shiftKey: string) {
    return this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 1)
        .where("dailyShift.key", "==", shiftKey)
    );
  }

  pettyCashByShiftRef(shiftKey: string) {
    return this.db.collection("petty_cash", ref => ref
      .where("shift.key", "==", shiftKey)
      .orderBy("page_key", "desc")
    );
  }

  installmentByShiftRef(shiftKey: string) {
    return this.db.collection("installment_movement", ref => ref
      .where("shift.key", "==", shiftKey)
      .where("isInstallment", "==", true)
      .orderBy("page_key", "desc")
    );
  }

  shiftMovementDocsRef() {
    return this.db.collection("shift_movements");
  }

  invoiceTypeListingByShiftRef(shiftKey: string, type: string) {
    let ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
      ref
        .where("isPaid.key", "==", 4)
    );
    switch (type) {
      case "tuition_fee":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", true)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "academic_program":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", true)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
            .where("school_fee_type.key", "==", enrollPrograms.academic.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "institute_and_center":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", true)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.tuitionFee.key)
            .where("school_fee_type.key", "==", enrollPrograms.institutes.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "testing":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", false)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.testing.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "abc":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", false)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.abcCourse.key)
            .orderBy("page_key", "desc")
        );
        break;
      case "miscellaneous":
        ref = this.db.collection("shift_movements").doc(shiftKey).collection("invoices", ref =>
          ref
            .where("isHeader", "==", false)
            .where("isPaid.key", "==", 1)
            .where("dailyShift.key", "==", shiftKey)
            .where("invoice_type.key", "==", invoiceTypesObj.miscellaneous.key)
            .orderBy("page_key", "desc")
        );
        break;
      default:
        break;
    }
    return ref;
  }

  paidcourseListingRef(fromDate, toDate, term) {
    return this.db.collection("academic_year").doc<any>(term.key).collection('enrollment', ref => ref
      .where("enroll_date_key", ">=", fromDate)
      .where("enroll_date_key", "<=", toDate)
      .where("isPaid", "==", true)
    )
  }

  unpaidcourseListingRef(fromDate, toDate, term) {
    return this.db.collection("academic_year").doc<any>(term.key).collection('enrollment', ref => ref
      .where("enroll_date_key", ">=", fromDate)
      .where("enroll_date_key", "<=", toDate)
      .where("isPaid", "==", false)
    )
  }


  unpaidcourseListingTermRef(term) {
    return this.db.collection("academic_year").doc<any>(term.key).collection('enrollment', ref => ref
      .where("isPaid", "==", false)
    )
  }


  testingListingRef(fromDate, toDate, institute, term, program, isAll, status) {
    switch (status.key) {
      case "puc_students":
        if (isAll) {
          return this.db.collection("testing", ref => ref
            .where("admission_date_key", ">=", fromDate)
            .where("admission_date_key", "<=", toDate)
            .where("institute.key", "==", institute.key)
            // .where("target_term.key","==",term.key)
            .where("isPaidTest", "==", true)
            .where("testing_result.status.key", "==", 1)
            .where("isPaid.key", "==", TestFeeStatus.paid.key)
            .orderBy("admission_date_key")
          );
        }
        else {
          return this.db.collection("testing", ref => ref
            .where("admission_date_key", ">=", fromDate)
            .where("admission_date_key", "<=", toDate)
            .where("institute.key", "==", institute.key)
            // .where("target_term.key","==",term.key)
            .where("isPaidTest", "==", true)
            .where("testing_result.status.key", "==", 1)
            .where("isPaid.key", "==", TestFeeStatus.paid.key)
            .where("test_type.key", "==", program.key)
            .orderBy("admission_date_key")
          );
        }


      default:
        if (isAll) {
          return this.db.collection("testing", ref => ref
            .where("admission_date_key", ">=", fromDate)
            .where("admission_date_key", "<=", toDate)
            .where("institute.key", "==", institute.key)
            // .where("target_term.key","==",term.key)
            .orderBy("admission_date_key")
          );
        }
        else {
          return this.db.collection("testing", ref => ref
            .where("admission_date_key", ">=", fromDate)
            .where("admission_date_key", "<=", toDate)
            .where("institute.key", "==", institute.key)
            // .where("target_term.key","==",term.key)
            .where("test_type.key", "==", program.key)
            .orderBy("admission_date_key")
          );
        }
    }

  }

  cashierOnlineRef() {
    return this.db.collection('users', ref => ref.where('isShiftOpen', '==', true).where('role.key', '==', 3).orderBy('name'));
  }

  cashierOnlineCampusRef(campusKey) {
    return this.db.collection('users', ref => ref.where('campus.key', '==', campusKey).where('isShiftOpen', '==', true).where('role.key', '==', 3).orderBy('name'));
  }

  termRef() {
    return this.db.collection('academic_year', ref => ref.orderBy('startDateKey', 'desc').limit(Pages.size));
  }
  termAcademicActive() {
    // tslint:disable-next-line:max-line-length
    return this.db.collection('academic_year', ref => ref.where('termstatus.key', '==', 2).where('institute.instituteType.key', '==', 2).orderBy('startDateKey').limit(1));
  }

  termSearchRef(value) {
    const { key } = value;
    if (key) {
      return this.db.collection('academic_year', ref => ref
        .where("key", "==", key)
        .limit(Pages.size))
    }
    return this.db.collection('academic_year', ref => ref
      .where("name", ">=", value)
      // .orderBy('name', "desc")
      .limit(Pages.size))
  }

  academicEnvironmentRef() {
    this.db.collection("shift_movements", ref => ref
      .where("allocated_status.key", "<", 3)
      .orderBy("allocated_status.key")
      .where("create_by.campus.key", "==", '1p7qRJAoJt4BKv4Iiuu4')
    ).valueChanges().subscribe(docs => {
    })

    return this.db
      .collection("academic_environment")
      .doc<any>("academic_environment");
  }
  // studentInfo(student) {
  //   return {
  //     name: ConvertService.toNull(student.nameEng),
  //     khmer: ConvertService.toNull(student.nameKh),
  //     phone: ConvertService.toNull(student.mobile_phone),
  //     code: ConvertService.toNull(student.puc_id),
  //     gender: ConvertService.toNull(student.gender),
  //     key: ConvertService.toNull(student.key),
  //     email: ConvertService.toNull(student.email)
  //   };
  // }

  // employeeInfo(employee) {
  //   if (employee) {
  //     return {
  //       key: ConvertService.toNull(employee.key),
  //       first_name: ConvertService.toNull(employee.first_name),
  //       last_name: ConvertService.toNull(employee.last_name),
  //       full_name: ConvertService.toNull(employee.full_name),
  //       mobile_phone: ConvertService.toNull(employee.mobile_phone),
  //       code: ConvertService.toNull(employee.code),
  //       gender: ConvertService.toNull(employee.gender),
  //       email: ConvertService.toNull(employee.email),
  //       nationality: ConvertService.toNull(employee.nationality),
  //       fileUrl: ConvertService.toNull(employee.fileUrl),
  //       employee_type: ConvertService.toNull(employee.employee_type),
  //     };
  //   } else return null;
  // }

  departmentInfo(department) {
    return {
      name: ConvertService.toNull(department.name),
      shortcut: ConvertService.toNull(department.shortcut),
      key: ConvertService.toNull(department.key),
      building: ConvertService.toNull(department.building),
      campus: ConvertService.toNull(department.campus),
      floor: ConvertService.toNull(department.floor),
    };
  }

  officeInfo(office) {
    return {
      name: ConvertService.toNull(office.name),
      key: ConvertService.toNull(office.key),
      employee: ConvertService.toNull(office.employee),
      department: ConvertService.toNull(office.department),
    };
  }

  campusInfo(campus) {
    return {
      name: ConvertService.toNull(campus.name),
      key: ConvertService.toNull(campus.key),
    };
  }
  buildingInfo(building) {
    return {
      name: ConvertService.toNull(building.name),
      key: ConvertService.toNull(building.key),
    };
  }
  floorInfo(floor) {
    return {
      name: ConvertService.toNull(floor.name),
      key: ConvertService.toNull(floor.key),
    };
  }

  countryInfo(item) {
    if (item) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }

  // nationalityInfo(item) {
  //   if (item) {
  //     return {
  //       name: ConvertService.toNull(item.name),
  //       key: ConvertService.toNull(item.key),
  //     };
  //   } else return null;
  // }
  nationalityInfo(item) {
    if (item) {
      return {
        nationality: ConvertService.toNull(item.nationality),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }

  // positionInfo(item) {
  //   if (item) {
  //     return {
  //       shortcut: ConvertService.toNull(item.shortcut),
  //       name: ConvertService.toNull(item.name),
  //       key: ConvertService.toNull(item.key),
  //     };
  //   } else return null;
  // }
  userInfo(item) {
    if (item) {
      return {
        name: ConvertService.toNull(item.full_name),
        full_name: ConvertService.toNull(item.full_name),
        email: ConvertService.toNull(item.email),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }



  cityInfo(item) {
    if (item) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }
  districtInfo(item) {
    if (item) {
      return {
        code: ConvertService.toNull(item.code),
        name: ConvertService.toNull(item.name),
        key: ConvertService.toNull(item.key),
      };
    } else return null;
  }

  // semployeeInfo(employee) {
  //   if (employee) {
  //     return {
  //       key: ConvertService.toNull(employee.key),
  //       first_name: ConvertService.toNull(employee.first_name),
  //       last_name: ConvertService.toNull(employee.last_name),
  //       full_name: ConvertService.toNull(employee.full_name),
  //       mobile_phone: ConvertService.toNull(employee.mobile_phone),
  //       code: ConvertService.toNull(employee.code),
  //       gender: ConvertService.toNull(employee.gender),
  //       email: ConvertService.toNull(employee.email),
  //       nationality: ConvertService.toNull(employee.nationality),
  //       fileurl: ConvertService.toNull(employee.fileurl),
  //       employee_type: ConvertService.toNull(employee.employee_type),
  //     };
  //   } else return null;
  // }

  facultyDocsRef() {
    return this.db.collection("faculties", ref =>
      ref.orderBy("name")
    );
  }

  checkInOutRef(userKey) {
    return this.db.collection('check_in_out', ref => ref
      .where('checkUser.key', '==', userKey)
      .where('isCheckOut', '==', false)
      .orderBy('created_at', 'desc')
      .limit(1))
  }

  hospitalCheckInOutRef(userKey) {
    return this.db.collection('hospital_check_in_out', ref => ref
      .where('checkUser.key', '==', userKey)
      .where('isCheckOut', '==', false)
      .orderBy('created_at', 'desc')
      .limit(1))
  }

  getCheckInOutRef(lastVisible: any, fromDate: number, toDate: number) {
    return this.db.collection('check_in_out', ref => {
      // let condition = ref.orderBy('date_key', 'desc')
      let condition = ref.where('checkType.key', '==', 1).orderBy('date_key', 'desc')
      if (fromDate && toDate) {
        condition = condition.where('date_key', '>=', fromDate).where('date_key', '<=', toDate)
      }
      if (lastVisible) {
        const startAfter = lastVisible['created_at'];
        condition = condition.orderBy('created_at', 'desc')
          .startAfter(startAfter)
      }
      else {
        condition = condition.orderBy('created_at', 'desc')
      }
      return condition
    })
  }
  getReportBookInventoryRef(collectionName, schoolKey, typeKey, fromDate: number, toDate: number) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.where('isDelete', '==', false).orderBy('createDateKey', 'desc')

      if (typeKey) {
        condition = condition.where('itemType', '==', typeKey)
      }
      if (fromDate && toDate) {
        condition = condition.where('createDateKey', '>=', fromDate).where('createDateKey', '<=', toDate)
      }
      else {
        condition = condition.orderBy('created_at', 'desc')
      }
      return condition
    })
  }
  bookCheckInOutRef(collectionName, orderBy, schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => ref.orderBy(orderBy))
  }

  async fetchDropdownData(collectionName: string, schoolKey: string, orderField: string, order?: any) {
    const ref = this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => ref
      .where('status.key', '==', 1)
      .where('keyword', 'array-contains', '~N/A~')
      .limit(20).orderBy(orderField))
    const data = pushToArray(await ref.get().toPromise());
    return data;
  }


  async fetchFilterDropdownData(collectionName: string, schoolKey: string, text: any, orderField: string, order?: any) {
    const ref = this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      if (text && !text.key) {
        const search = text.toUpperCase();
        console.log('search', search);
        return ref
          .where('status.key', '==', 1)
          .limit(20).orderBy(orderField)
          .where('keyword', 'array-contains', search)
      } else {
        return ref
          .where('status.key', '==', 1)
          .limit(20).orderBy(orderField)
          .where('keyword', 'array-contains', '~N/A~')
      }
    })
    const data = pushToArray(await ref.get().toPromise());
    return data;
  }

  async fetchDropdownTypeAssignmentData(collectionName: string, orderField: string, typeKey, schoolKey, campusKey) {
    const ref = this.db.collection(collectionName, ref => {
      let condition = ref
        .where('isDelete', '==', false)
        .where('status.key', '==', 1)
        .where('type_user.key', '==', typeKey)
        .where('keyword', 'array-contains', '~N/A~')
        .limit(20)
        .orderBy(orderField)
      if (schoolKey === 'ngs') {
        condition = condition
          .where('fromCampus.key', '==', campusKey)
      }
      return condition
    })
    const data = pushToArray(await ref.get().toPromise());
    return orderBy(data, 'shortcutKey');
  }
  async fetchFilterDropdownTypeAssignmentData(collectionName: string, text: any, orderField: string, typeKey, order?: any) {
    const ref = this.db.collection(collectionName, ref => {
      if (text && !text.key) {
        const search = text.toUpperCase();
        console.log('search', search);

        return ref
          .where('status.key', '==', 1)
          .where('type_user.key', '==', typeKey)
          .limit(20).orderBy(orderField)
          .where('keyword', 'array-contains', search)
      } else {
        return ref
          .where('status.key', '==', 1)
          .where('type_user.key', '==', typeKey)
          .limit(20).orderBy(orderField)
          .where('keyword', 'array-contains', '~N/A~')
      }
    })
    const data = pushToArray(await ref.get().toPromise());
    return data;
  }

  getStatistics(startDate, endDate, stKey) {
    return this.db.collection('check_in_out_statistics').doc(stKey).collection('check_in_out_statistics_date', ref => ref.where('key', '>=', startDate).where('key', '<=', endDate))
  }

  getStatisticsBorrow(startDate, endDate, stKey) {
    return this.db.collection('statistics_items_borrow_return').doc(stKey).collection('statistics_items_borrow_return_date', ref => ref.where('key', '>=', startDate).where('key', '<=', endDate))
  }

  getStatisticsBook(startDate, endDate, stKey) {
    return this.db.collection('book_library_statistics').doc(stKey).collection('book_library_statistics_date', ref => ref.where('key', '>=', startDate).where('key', '<=', endDate))
  }

  getStatisticsBookInventory(startDate, endDate, stKey, campusKey) {
    return this.db.collection('stores').doc(stKey).collection('book_library_inventory', ref => {
      let condition = ref.where('createDateKey', '>=', startDate).where('createDateKey', '<=', endDate)
      if (stKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      return condition
    })
  }

  getReportEdit(startDate, endDate, stKey) {
    return this.db.collection('stores').doc(stKey).collection('report_edit', ref => ref.where('date_key', '>=', startDate).where('date_key', '<=', endDate).orderBy('date_key', 'desc'))
  }
  getReportEdit_i(startDate, endDate, stKey) {
    return this.db.collection('stores').doc(stKey).collection('report_edit_i', ref => ref.where('date_key', '>=', startDate).where('date_key', '<=', endDate).orderBy('date_key', 'desc'))
  }
  getReportEdit_ii(startDate, endDate, stKey) {
    return this.db.collection('stores').doc(stKey).collection('report_edit_ii', ref => ref.where('date_key', '>=', startDate).where('date_key', '<=', endDate).orderBy('date_key', 'desc'))
  }
  getReportEdit_iii(startDate, endDate, stKey) {
    return this.db.collection('stores').doc(stKey).collection('report_edit_iii', ref => ref.where('date_key', '>=', startDate).where('date_key', '<=', endDate).orderBy('date_key', 'desc'))
  }
  getReportEdit_iv(startDate, endDate, stKey) {
    return this.db.collection('stores').doc(stKey).collection('report_edit_iv', ref => ref.where('date_key', '>=', startDate).where('date_key', '<=', endDate).orderBy('date_key', 'desc'))
  }

  getlibraryTypeAssignmentRef(campusKey) {
    return this.db.collection('library_type_assignment', ref => ref.where('fromCampus.key', '==', campusKey))
  }

  getCheckInCheckOut(startDate, endDate) {
    return this.db.collection('check_in_out', ref => ref.where('date_key', '>=', startDate).where('date_key', '<=', endDate))
  }
  getCheckInCheckLibrary(startDate, endDate) {
    return this.db.collection('check_in_out', ref => ref.where('date_key', '>=', startDate).where('date_key', '<=', endDate).where('checkType.key', '==', 1))
  }

  getCheckInCheckOutAttendence(fromDate, toDate) {
    return this.db.collection('check_in_out', ref => ref.where('date_key', '>=', fromDate).where('date_key', '<=', toDate))
  }

  getDataBorrowBook(startDate, endDate, schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection('library_borrow_items', ref => ref.where('borrowDateKey', '>=', startDate).where('borrowDateKey', '<=', endDate))
  }
  getDataBorrowReturn(schoolKey, startDate, endDate) {
    return this.db.collection('stores').doc(schoolKey).collection('library_borrow_items', ref => ref.where('date_key', '>=', startDate).where('date_key', '<=', endDate))
  }
  calculateHourDifference(startDate, endDate) {
    // const issueDate = new Date(date);
    const start = startDate;
    const end = endDate;
    const diffInMilliseconds = Math.abs(end.getTime() - start.getTime());
    const diffInSeconds = diffInMilliseconds / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = Math.floor(diffInMinutes % 60);
    return {
      hours: hours,
      minutes: minutes
    };
  }
  startDate(date: Date) {
    return moment(date).startOf('day').toDate();
  }
  endDate(date: Date) {
    return moment(date).endOf('day').toDate();
  }

  getCollectionDataRefs(collectionName, schoolKey, field, campusKey, search, lastVisible: any) {
    return this.db.collection('stores').doc(schoolKey).collection(collectionName, ref => {
      let condition = ref.where('isDelete', '==', false).orderBy(field)
      // .limit(Pages.size)
      if (search) {
        const keyword = search ? ConvertService.toCapitalize(search) : '~N/A~';
        condition = condition.where('keyword', 'array-contains', keyword)
      }
      if (schoolKey === 'ngs') {
        condition = condition.where('fromCampus.key', '==', campusKey)
      }
      if (lastVisible) {
        const startAfter = lastVisible[field];
        condition = condition.startAfter(startAfter)
      }
      return condition.limit(Pages.size)
    })
  }

  getCollectionGenres(schoolKey) {
    return this.db.collection('stores').doc(schoolKey).collection(COLLECTION_NAME.BOOK_STORE_GENRES, ref => ref.where('isDelete', '==', false))
  }


  getHospitalCheckInOutRef(lastVisible: any, fromDate: number, toDate: number) {
    return this.db.collection('hospital_check_in_out', ref => {
      let condition = ref
        .where('checkType.key', '==', 1)
        .orderBy('date_key', 'desc')
      if (fromDate && toDate) {
        condition = condition
          .where('date_key', '>=', fromDate)
          .where('date_key', '<=', toDate)
      }
      if (lastVisible) {
        const startAfter = lastVisible['created_at'];
        condition = condition
          .orderBy('created_at', 'desc')
          .startAfter(startAfter)
      }
      else {
        condition = condition
          .orderBy('created_at', 'desc')
      }
      return condition
    })
  }

}