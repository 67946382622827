import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  
  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
  ) {
    
  }

  authRef(){
    return this.afAuth;
  }

  authUserRef(){
    return this.afAuth.signInWithEmailAndPassword;
  }
  
  userRef(){
    return this.afs.collection('users');
  }

  isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  canActiveRef(){
    return this.afAuth.user
  }

  async currentUserRef(){
    const user = await this.afAuth.authState.pipe(first()).toPromise();
    return user;
  }
 
}