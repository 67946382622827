<div class="dialog-wrapper">
  <div class="dialog-header">
    <div class="title">
      {{msg?.title}}
    </div>
    <div class="flex1"></div>
    <a mat-dialog-close class="close-dialog" (click)="onClose()">
      <i class="material-icons">&#xE14C;</i>
    </a>
  </div>
  <div class="dialog-content">
    <div class="form-content">
      <div class="aside-warning">
        <i class="material-icons">warning</i>
        <span>
          {{msg?.memo}}
        </span>
      </div>
      <ng-container *ngIf="!msg?.remove_note">
        <p>This item
          <strong>{{msg?.name}}</strong> will delete from system and you cannot recover back.
        </p>
        <p>Are you sure you want to delete?</p>
      </ng-container>

      <p *ngIf="msg?.remove_note">
        {{msg?.remove_note}}
      </p>
    </div>
  </div>
  <div class="dialog-footer">
    <div class="flex1"></div>
    <button mat-dialog-close type="button" class="enbutton cancel m-r16" mat-button (click)="onClose()">Cancel</button>
    <button type="submit" class="enbutton delete-btn m-r16" (click)="dialogRef.close('yes')"
      color="warn">{{msg.button?msg.button:'Delete'}}</button>

    <!-- <button type="submit" class="enbutton primary m-r16" [disabled]="!form.valid || store?.process || process"
      (click)="create(form.value)">Save</button> -->
  </div>
</div>
