import { Pipe, PipeTransform } from '@angular/core';
import { groupBy } from 'rxjs/operators';
import { sum } from '../services/convert.service';

@Pipe({
  name: 'findGrade'
})
export class FindGradePipe implements PipeTransform {

  transform(gradeListing: any[], enrollmentKey, scheduleSubjectKey, field): unknown {
    if (gradeListing) {
      const doc = gradeListing.find(m => m.enrollmentKey === enrollmentKey && m.scheduleSubjectKey === scheduleSubjectKey)
      return doc ? (field === 'gradingScale' ? doc.gradingScale.grade : doc[field] || '-') : '-'
    }
    return null;
  }

}
@Pipe({
  name: 'sumSemesterGrade'
})
export class SumSemesterGradePipe implements PipeTransform {

  transform(gradeListing: any[], schedules: any, scheduleSubjectKey: string, studentKey: string): any {
    if (gradeListing) {
      let data = []
      schedules.map(f => {
        const scc = gradeListing.filter(g => g.scheduleSubjectKey === f.scheduleSubjectKey && g.studentKey === studentKey && g.scheduleSubjectKey === scheduleSubjectKey)
        data.push(...scc)
      });
      const result = sum(data, 'result')

      return result / 2
      // const doc = gradeListing.find(m => m.enrollmentKey === enrollmentKey && m.scheduleSubjectKey === scheduleSubjectKey);
    }
    return null;
  }

}

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {
  transform(dateString: string): string {
    if (!dateString) return '';

    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  }
}


@Pipe({
  name: 'sumGrade'
})
export class SumGradePipe implements PipeTransform {

  transform(gradeListing: any[], schedules: any, studentKey: string): any {
    if (gradeListing) {
      let data = []
      schedules.map(f => {
        const scc = gradeListing.filter(g => g.scheduleSubjectKey === f.scheduleSubjectKey && g.studentKey === studentKey);
        data.push(...scc)
      });
      const result = sum(data.map(m=>({...m,result:(m.result+(m.gradeSheet.addOn||0))})), 'result')
      return result
      // const doc = gradeListing.find(m => m.enrollmentKey === enrollmentKey && m.scheduleSubjectKey === scheduleSubjectKey);
    }
    return null;
  }

}


@Pipe({
  name: 'sumMultiplierGrade'
})
export class SumMultiplierGradePipe implements PipeTransform {

  transform(gradeListing: any[], schedules: any, studentKey: string): any {
    if (gradeListing) {
      let data = []
      schedules.map(f => {
        const scc = gradeListing.filter(g => g.scheduleSubjectKey === f.scheduleSubjectKey && g.studentKey === studentKey)
        data.push(...scc)
      });
      const result = sum(data.map(m=>({...m,result:(m.result+(m.gradeSheet.addOn||0))})), 'result')
      return result / 2
    }
    return null;
  }

}


@Pipe({
  name: 'groupByGrade'
})
export class GroupByGradePipe implements PipeTransform {

  transform(gradeListing: any[], grade): any {
    if (gradeListing) {
      const doc = gradeListing.filter(m => m.gradingScale&&m.gradingScale.grade === grade)
      return doc.length;
    }
    return null;
  }

}

@Pipe({
  name: 'arrayLengthPercentage'
})
export class ArrayLengthPercentagePipe implements PipeTransform {
  transform(length: number): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; 
    const totalDaysInMonth = new Date(year, month, 0).getDate(); 
    const percentage = (length / totalDaysInMonth) * 100;
    return percentage.toFixed(2) + '%';
  }
}