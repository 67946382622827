import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
declare var $: any;
import * as FileSaver from 'file-saver';
import { formatDate } from '@angular/common';
import { assets } from './mapping.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfMakeUnicode from 'pdfmake-unicode';

@Injectable({ providedIn: 'root' })
export class PrintService {
  // exportAsExcelFile(data: { ID: any; Name: any; Score: any; }[], arg1: string) {
  //   throw new Error('Method not implemented.');
  // }
  public exportAsExcelFile(rows: any[], excelFileName: string): void {
    if (rows.length > 0) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
      const workbook: XLSX.WorkBook = { Sheets: { 'Compte-rendu': worksheet }, SheetNames: ['Compte-rendu'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    } else {
      // this.notifService.message('Aucune ligne à exporter...');
    }
  }

  constructor() { }
  public exportAsExcelFileGrading(rows: any[], excelFileName: string): void {
    if (rows.length > 0) {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);
      const workbook: XLSX.WorkBook = { Sheets: { 'Compte-rendu': worksheet }, SheetNames: ['Compte-rendu'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    } else {
      // this.notifService.message('Aucune ligne à exporter...');
    }
  }
  private saveAsExcelFile(buffer: any, baseFileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, baseFileName + '_' + this.getDateFormat(new Date()) + EXCEL_EXTENSION);
  }
  private getDateFormat(date: Date): string {
    return formatDate(date, 'yyyyMMdd_HHmmss', 'en-US');
  }

  print(section: any, size: any) {
    switch (size) {
      case 'a5':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/a5.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        });
        break;
      case 'a4':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/a4.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        })
        break;
      case 'ca4':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/ca4.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        })
        break;
      case 'a4l':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/a4l.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        })
        break;
      case 'a5l':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/a5l.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        })
        break;
      case 'rp':
        $(section).print({
          globalStyles: true,
          mediaPrint: true,
          stylesheet: "/../assets/styles/rollpaper.css",
          iframe: true,
          timeout: 5000,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          title: null,
          noPrintSelector: ".no-print",
          doctype: '<!doctype html>'
        })
        break;

      default:
        break;
    }
  }
  pdfMakeInit() {
    (pdfMake as any).vfs  = pdfMakeUnicode.pdfMake.vfs;
    (pdfMake as any).fonts = {
      Battambang: {
        normal: assets('fonts/Battambang-Regular.ttf'),
        bold: assets('fonts/Battambang-Bold.ttf'),
      },
      Moul: {
        normal: `https://fonts.ensophearak.xyz/KhmerOS_muollight.ttf`,
      },

      Roboto: {
        normal:assets('fonts/Roboto-Regular.ttf'),
        bold:assets('fonts/Roboto-Bold.ttf'),
        italics:assets('fonts/Roboto-Italic.ttf'),
        bolditalics:assets('fonts/Roboto-BoldItalic.ttf'),
      },
      OCRB: {
        normal: assets('fonts/OCRB-Regular.ttf'),
        bold:assets('fonts/OCRB-Medium.ttf'),
      },
      Time: {
        normal: assets('fonts/times_new_roman.ttf'),
        bold:assets('fonts/times_new_roman_bold.ttf'),
      },
    };
  }

}
