import { Router } from '@angular/router';
import { observable, action, computed, toJS, } from 'mobx';
import { Injectable, NgZone } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { auth } from 'firebase/app';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { UserStore } from './user.store';
import { SCHOOL } from '../dummy/config';
import { MappingService, pushToArray, pushToObject } from 'src/app/shared/services/mapping.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireFunctions } from '@angular/fire/functions';
import { APPS } from '../dummy/app';
import firebase from 'firebase/app';
@Injectable({ providedIn: 'root' })
export class AuthStore {
  @observable remember: boolean = false;
  @observable isLogged: boolean = false;
  @observable process: boolean = false;
  @observable loading: boolean = true;
  @observable error: any;
  @observable user: any = null;
  @observable hqAccount: boolean = false;
  @observable uid: any = null;
  @observable userRole: any = null;
  @observable storeKey: string = SCHOOL.key;
  @observable appName: any = SCHOOL.name;
  @observable selectedCampus: any = null;
  @observable selectedSchool: any = null;
  @observable selectedCompany: any = null;
  @observable memberOf: any = null;
  @observable selectedTerm: any = null;
  @observable selectedYear: any = null;
  @observable terms: any[] = [];
  @observable root: boolean = false;
  @observable appModules: any[] = [];
  @observable appModulePermissions: any[] = [];
  @observable appModulePermissionsOption: any[] = [];
  @observable apps: any[] = [];
  @observable appPrograms: any[] = [];
  @observable config: any = null;
  @observable sysOption: any = null;
  @observable academicEnv: any = null;
  @observable campusNotification: any = null;
  school = SCHOOL;
  constructor(
    private router: Router,
    private auth: AuthService,
    private ds: DataService,
    private ngZone: NgZone,
    private userStore: UserStore,
    private afMessaging: AngularFireMessaging,
    private fun: AngularFireFunctions
  ) {
    this.fetchCanActive();
  }

  @action
  allowApp(appKey) {
    if (this.root) return true;
    if (Array.isArray(this.apps)) {
      return this.apps.filter(m => m.key === appKey).length > 0;
    }
    return false;
  }

  @action
  allowModule(moduleKey) {

  }

  @action
  allowFunction(funKey) {

  }

  @action
  changePassword(oldPassword: any, newPassword: any, callback) {
    const user = firebase.auth().currentUser;
    this.auth.authRef().signInWithEmailAndPassword(user.email, oldPassword).then((account) => {
      user.updatePassword(newPassword).then(() => {
        callback(true, null)
      }).catch(error => {
        callback(false, error)
      })
    }).catch(error => {
      callback(false, error)
    })
  }

  @action
  updateBadge(callback?) {
    const { total_read_testing } = this.user;
    const totalRead = ((this.campusNotification && this.campusNotification.total_testing) || 0) - (total_read_testing || 0)
    if (typeof totalRead === 'number' && totalRead > 0) {
      this.ds.userDocRef(this.uid).update({
        total_read_testing: this.ds.fieldValue().increment(totalRead)
      }).then(() => {
        callback && callback(true)
      })
    } else {
      callback && callback(false)
    }
  }

  @observable fetchCampusNotificationsRef: any = null;
  @observable fetchNotificationsRef: any = null;
  @observable notifications: any[] = [];
  @observable fetchingNotification: boolean = false;
  @observable lastVisibleNotification: any = null;
  @observable loadingNotify: boolean = true;

  @action
  async fetchCampusNotifications() {
    this.uid = (await this.auth.currentUserRef()).uid;
    const doc = await this.ds.userRef(this.uid).get().toPromise();
    const item = pushToObject(doc);
    const { memberOf, appsObject, schoolKey, school, campusKey } = item;
    this.user = {
      ...item,
      displayName: `${item.displayName}`.toLowerCase(),
    };
    this.selectedCampus = item && item.campus;
    this.memberOf = memberOf;
    this.hqAccount = memberOf && memberOf.key === 0;
    this.apps = appsObject;
    this.selectedSchool = school;
    this.fetchCampusNotificationsRef = this.ds.campusNotificationRef(schoolKey, campusKey)
      .valueChanges()
      .subscribe(doc => {
        this.campusNotification = doc;
      })
    this.fetchNotificationsRef = this.ds.notificationsRef(this.hqAccount, schoolKey, campusKey, null)
      .valueChanges()
      .subscribe(docs => {
        this.notifications = docs;
        if (docs.length > 0 && docs.length >= APPS.SIZE) {
          this.lastVisibleNotification = docs[docs.length - 1];
        } else {
          this.lastVisibleNotification = null;
        }
      })
  }

  @action
  async fetchMoreNotification() {
    if (this.fetchingNotification || this.lastVisibleNotification === null || this.loadingNotify) {
      return;
    }
    this.fetchingNotification = true;
    const { schoolKey, campusKey } = this.user;
    const docsData = await this.ds.notificationsRef(this.hqAccount, schoolKey, campusKey, this.lastVisibleNotification).get().toPromise();
    const docs = pushToArray(docsData);
    if (docs.length > 0 && docs.length >= APPS.SIZE) {
      this.lastVisibleNotification = docs[docs.length - 1];
    } else {
      this.lastVisibleNotification = null;
    }
    this.notifications = this.notifications.slice().concat(docs);
    this.fetchingNotification = false;
  }

  @action
  changeAvatar(file, callback?) {
    this.process = true;
    const batch = this.ds.batch();
    batch.set(this.ds.employeeFireRef().doc(this.uid), {
      photoFile: file,
      fileUrl: file.downloadUrl,
      updated_at: this.ds.serverTimestamp(),
      key: this.uid,
    }, { merge: true })
    batch.update(this.ds.userFireRef().doc(this.uid), {
      photoFile: file,
      fileUrl: file.downloadUrl,
      updated_at: this.ds.serverTimestamp(),
    })
    batch.commit().then(() => {
      callback && callback(true, null)
    }).catch((error) => {
      callback && callback(false, error)
    }).finally(() => {
      this.process = false;
    })
  }

  @action
  async fetchStudentTestingDoc(studentKey: string) {
    const studentTestingDoc = await this.ds.studentTestRef(studentKey).get().toPromise();
    return MappingService.pushToArray(studentTestingDoc)
  }

  @action
  async fetchActiveTerm(schoolKey: string, callback?: (term: any) => any) {
    const doc = await this.ds.storeDocRef(schoolKey).collection("academic_environment").doc("academic_environment").get().toPromise();
    if (doc.exists) {
      const { term, year } = pushToObject(doc);
      this.selectedTerm = term;
      this.selectedYear = year;
      return callback && callback(term);
    }
  }

  @observable fetchTermsRef: any = null;
  @action
  fetchTerms(callback?) {
    // this.process = true;
    // this.fetchTermsRef = this.ds.termAcademicReportRef()
    //   .valueChanges()
    //   .subscribe(docs => {
    //     this.terms = docs;
    //     this.process = false;
    //     if (callback) callback(this.terms)
    //   })
  }

  @observable fetchInstituteTermRef: any = null;
  @action
  fetchInstituteTerm(instituteKey: string, callback?) {
    this.process = true;
    this.fetchInstituteTermRef = this.ds.instituteTermDocsRef(instituteKey)
      .valueChanges().subscribe(docs => {
        this.terms = docs;
        this.process = false;
        if (callback) callback(this.terms)
      })
  }

  @action
  async fetchUser() {
    this.loading = true;
    const userDoc = (await this.auth.currentUserRef());
    if (!userDoc) {
      this.signOut()
      this.loading = false;
      return;
    }
    this.uid = userDoc && userDoc.uid;
    if (!this.user) {
      const doc = await this.ds.userRef(this.uid).get().toPromise();
      const item = pushToObject(doc);
      const { memberOf, appsObject } = item;
      this.user = {
        ...item,
        displayName: `${item.displayName}`.toLowerCase(),
      };
      const { isAdmin, schoolKey, school } = item
      this.storeKey = schoolKey;
      this.root = isAdmin;
      this.hqAccount = isAdmin;
      this.selectedSchool = school;
      this.appName = school && school.name;
      this.selectedCampus = item && item.campus;
      this.memberOf = memberOf;
      this.userRole = memberOf;
      this.hqAccount = memberOf && memberOf.key === 0;
      this.apps = appsObject;
      if (!isAdmin) {
        this.loadPermissionModules()
      }
    }
    if (!this.config) {
      const configDoc = await this.ds.sysSetting().get().toPromise();
      this.config = MappingService.pushToObject(configDoc);
    }
    if (!this.academicEnv) {
      const sysConfigDoc = await this.ds.storeDocRef(this.storeKey).collection("academic_environment").doc("academic_environment").get().toPromise();
      this.academicEnv = MappingService.pushToObject(sysConfigDoc);
    }

    if (!this.sysOption) {
      const sysOptionDoc = await this.ds.storeDocRef(this.storeKey).collection("sys_option").doc("general").get().toPromise();
      this.sysOption = MappingService.pushToObject(sysOptionDoc);
    }

    this.fetchPermission(this.user)
    this.loading = false;
    return this.user;
  }

  @action
  async fetchUserDoc(callback?) {
    this.loading = true;
    const userDoc = (await this.auth.currentUserRef());
    if (!userDoc) {
      this.signOut()
      this.loading = false;
      return;
    }
    this.uid = userDoc && userDoc.uid;
    if (!this.user) {
      const doc = await this.ds.userRef(this.uid).get().toPromise();
      const item = pushToObject(doc);
      const { memberOf, appsObject } = item;
      this.user = {
        ...item,
        displayName: `${item.displayName}`.toLowerCase(),
      };
      const { isAdmin, schoolKey, school } = item
      this.storeKey = schoolKey;
      this.root = isAdmin;
      this.hqAccount = isAdmin;
      this.selectedSchool = school;
      this.appName = school && school.name;
      this.selectedCampus = item && item.campus;
      this.memberOf = memberOf;
      this.userRole = memberOf;
      this.hqAccount = memberOf && memberOf.key === 0;
      this.apps = appsObject;
      if (!isAdmin) {
        this.loadPermissionModules()
      }
    }

    if (!this.config) {
      const configDoc = await this.ds.sysSetting().get().toPromise();
      this.config = MappingService.pushToObject(configDoc);
    }
    if (!this.academicEnv) {
      const sysConfigDoc = await this.ds.storeDocRef(this.storeKey).collection("academic_environment").doc("academic_environment").get().toPromise();
      this.academicEnv = MappingService.pushToObject(sysConfigDoc);
    }

    if (!this.sysOption) {
      const sysOptionDoc = await this.ds.storeDocRef(this.storeKey).collection("sys_option").doc("general").get().toPromise();
      this.sysOption = MappingService.pushToObject(sysOptionDoc);
    }

    await this.fetchPermission(this.user)
    this.loading = false;
    callback && callback(this.user)
  }

  @action
  async fetchCampusByRole(user: any) {
    const { schoolKey, campusKey, memberOf, isAllCampus } = user
    // const campusDoc: any = await this.ds.campusSchoolRef(schoolKey).get().toPromise();
    const campusDoc: any = await this.ds.storeDocRef(schoolKey).collection("campus", ref => ref.where("status.key", "==", 1)).get().toPromise();
    let campusData = MappingService.pushToArray(campusDoc);
    campusData = MappingService.orderBy(campusData, "order")
    if (memberOf.key > 0 && !isAllCampus)
      campusData = campusData.filter((m: any) => m.key === campusKey)
    return campusData;
  }

  @action
  async fetchEmployee() {
    const uid = (await this.auth.currentUserRef()).uid;
    const doc = await this.ds.employeeDBRef().doc(uid).get().toPromise();
    const item = pushToObject(doc);
    this.selectedCampus = item && item.campus;
    return item;
  }

  @observable requestPermissionNotificationRef: any = null;
  @action
  requestPermissionNotification(uid, campusKey) {
    this.requestPermissionNotificationRef = this.afMessaging.requestToken.subscribe(async token => {
      const sps_uid_notify = JSON.parse(localStorage.getItem('sps_uid_notify')) || null;
      const sps_campus_notify = JSON.parse(localStorage.getItem('sps_campus_notify')) || null;
      const sps_notify = JSON.parse(localStorage.getItem('sps_notify')) || null;

      if (!sps_uid_notify || sps_uid_notify !== uid) {
        await this.fun.httpsCallable('subscribeToTopic')({ topic: uid, token }).toPromise();
      }
      if (!sps_campus_notify || sps_campus_notify !== campusKey) {
        await this.fun.httpsCallable('subscribeToTopic')({ topic: campusKey, token }).toPromise();
      }
      if (!sps_notify || sps_notify !== 'sps_notify') {
        await this.fun.httpsCallable('subscribeToTopic')({ topic: 'sps_notify', token }).toPromise();
      }
      this.afMessaging.onMessage(payload => { })
      localStorage.setItem('sps_uid_notify', JSON.stringify(uid))
      localStorage.setItem('sps_campus_notify', JSON.stringify(campusKey))
      localStorage.setItem('sps_notify', JSON.stringify('sps_notify'))
    })
  }

  @observable fetchCanActiveRef: any = null;
  @observable fetchCanActiveAuthRef: any = null;
  @observable fetchCompanyRef: any = null;
  @observable fetchPermissionModulesRef: any = null;
  @observable fetchPermissionProgramsRef: any = null;

  @action
  fetchCanActive() {
    this.error = null;
    this.loading = true;
    this.fetchCanActiveAuthRef = this.auth.canActiveRef().subscribe(async user => {
      this.loading = true;
      if (user) {
        this.user = JSON.parse(localStorage.getItem('user')) || null;
        this.uid = user.uid;
        //read default permission
        this.appModulePermissions = JSON.parse(localStorage.getItem('auxswot_apps_modules_permissions')) || null;
        this.apps = JSON.parse(localStorage.getItem('auxswot_apps')) || null;
        this.appModules = JSON.parse(localStorage.getItem('auxswot_apps_modules')) || null;
        this.appPrograms = JSON.parse(localStorage.getItem('auxswot_apps_programs')) || null;
        //request for notifications
        const awaitUser = await this.ds.userDocRef(user.uid).get().toPromise()
        const userDoc = pushToObject(awaitUser);
        this.user = {
          ...userDoc,
          displayName: `${userDoc.displayName}`.toLowerCase(),
        };
        const { campusKey, isAdmin, schoolKey, school, campus, groupAccountKey, companyData } = userDoc
        if (!isAdmin && (!Array.isArray(groupAccountKey) || (Array.isArray(groupAccountKey) && groupAccountKey.length === 0))) {
          this.resetLocal()
          alert("You don't have any permission to access all applications in system. Please try another!")
          this.router.navigate(['/auth']);
          this.signOut()
          this.loading = false;
          return;
        }
        this.root = isAdmin;
        this.hqAccount = isAdmin;
        this.storeKey = schoolKey;
        this.appName = school && school.name;
        this.selectedCampus = campus;
        this.selectedSchool = school;
        // this.requestPermissionNotification(user.uid, campusKey)
        //fetch Company
        this.fetchCompanyRef = this.ds.companyUserRef(this.root, companyData?.key || SCHOOL.key).valueChanges().subscribe(com => {
          this.selectedCompany = com;
        })
        //fetch permission modules
        if (!isAdmin) {
          this.fetchPermissionProgramsRef = this.ds.groupModulesAppRef(ref => ref.where('key', 'in', groupAccountKey)).valueChanges().subscribe((docs: any[]) => {
            this.appPrograms = MappingService.groupBy(docs.map(m => m.programs), "key", "key");
            localStorage.setItem('auxswot_apps_programs', JSON.stringify(this.appPrograms));
          })
          this.fetchPermissionModulesRef = this.ds.groupModulesMovementAppRef(ref => ref.where('groupData.key', 'in', groupAccountKey))
            .valueChanges().subscribe(docs => {
              this.appModulePermissions = docs;
              let options = []
              docs.map((m: any) => {
                options = options.concat(m.options)
              })
              this.apps = MappingService.groupBy(this.appModulePermissions.map(m => m.app), "key", "key");
              this.appModules = MappingService.groupBy(this.appModulePermissions.map(m => m.moduleData), "key", "key");
              this.appModulePermissionsOption = options;
              if (docs.length > 0) {
                localStorage.setItem('auxswot_apps', JSON.stringify(this.apps));
                localStorage.setItem('auxswot_apps_modules', JSON.stringify(this.appModules));
                localStorage.setItem('auxswot_apps_modules_permissions', JSON.stringify(this.appModulePermissions));
              } else {
                this.resetPermission()
              }
            })
        }
        //subscribe for change user profile
        this.fetchCanActiveRef = this.ds.userRef(user.uid)
          .valueChanges()
          .subscribe(async (doc: any) => {
            this.user = {
              ...doc,
              displayName: `${doc.displayName}`.toLowerCase(),
            };
            this.selectedCampus = doc.campus;
            this.userRole = doc.memberOf;
            localStorage.setItem('user', JSON.stringify(this.user));
            this.loading = false;
          })
      } else {
        this.signOut();
        this.loading = false;
      }
    })
  }

  @action
  fetchPermission(user: any) {
    const { campusKey, isAdmin, schoolKey, school, campus, groupAccountKey, companyData } = user
    if (!isAdmin && (!Array.isArray(groupAccountKey) || (Array.isArray(groupAccountKey) && groupAccountKey.length === 0))) {
      this.resetLocal()
      alert("You don't have any permission to access all applications in system. Please try another!")
      this.router.navigate(['/auth']);
      this.signOut()
      this.loading = false;
      return;
    }
    this.root = isAdmin;
    this.hqAccount = isAdmin;
    this.storeKey = schoolKey;
    this.appName = school && school.name;
    this.selectedCampus = campus;
    this.selectedSchool = school;
    // this.requestPermissionNotification(user.key, campusKey)
    //fetch Company
    this.fetchCompanyRef = this.ds.companyUserRef(this.root, companyData?.key || SCHOOL.key).valueChanges().subscribe(com => {
      this.selectedCompany = com;
    })
    //fetch permission modules
    if (!isAdmin) {
      this.fetchPermissionProgramsRef = this.ds.groupModulesAppRef(ref => ref.where('key', 'in', groupAccountKey)).valueChanges().subscribe((docs: any[]) => {
        this.appPrograms = MappingService.groupBy(docs.map(m => m.programs), "key", "key");
        localStorage.setItem('auxswot_apps_programs', JSON.stringify(this.appPrograms));
      })
      this.fetchPermissionModulesRef = this.ds.groupModulesMovementAppRef(ref => ref.where('groupData.key', 'in', groupAccountKey))
        .valueChanges().subscribe(docs => {
          this.appModulePermissions = docs;
          let options = []
          docs.map((m: any) => {
            options = options.concat(m.options)
          })
          this.apps = MappingService.groupBy(this.appModulePermissions.map(m => m.app), "key", "key");
          this.appModules = MappingService.groupBy(this.appModulePermissions.map(m => m.moduleData), "key", "key");
          this.appModulePermissionsOption = options;
          if (docs.length > 0) {
            localStorage.setItem('auxswot_apps', JSON.stringify(this.apps));
            localStorage.setItem('auxswot_apps_modules', JSON.stringify(this.appModules));
            localStorage.setItem('auxswot_apps_modules_permissions', JSON.stringify(this.appModulePermissions));
          } else {
            this.resetPermission()
          }
        })
    }
    //subscribe for change user profile
    this.fetchCanActiveRef = this.ds.userRef(user.key)
      .valueChanges()
      .subscribe(async (doc: any) => {
        this.user = {
          ...doc,
          displayName: `${doc.displayName}`.toLowerCase(),
        };
        this.selectedCampus = doc.campus;
        this.userRole = doc.memberOf;
        localStorage.setItem('user', JSON.stringify(this.user));
        this.loading = false;
      })
  }

  @action
  loadPermissionModules() {
    this.apps = JSON.parse(localStorage.getItem('auxswot_apps')) || null;
    this.appModules = JSON.parse(localStorage.getItem('auxswot_apps_modules')) || null;
    this.appModulePermissions = JSON.parse(localStorage.getItem('auxswot_apps_modules_permissions')) || null;
  }

  resetPermission() {
    localStorage.removeItem('auxswot_apps');
    localStorage.removeItem('auxswot_apps_modules');
    localStorage.removeItem('auxswot_apps_modules_permissions');
    localStorage.removeItem('auxswot_apps_programs');
  }

  @action
  resetLocal() {
    localStorage.removeItem('auxswot_apps');
    localStorage.removeItem('auxswot_apps_programs');
    localStorage.removeItem('auxswot_apps_modules');
    localStorage.removeItem('auxswot_apps_modules_permissions');
    localStorage.removeItem('user');
  }

  @action
  signIn(email, password) {
    this.process = true;
    this.error = null;
    return this.auth.authRef().signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(async () => {
          const { user } = result;
          if (user) {
            const docs = await this.ds.userRef(user.uid).get().toPromise();

            if (!docs.exists) {
              this.resetLocal()
              this.error = "Invalid your email and password or access denied.";
              this.router.navigate(['/auth']);
              this.process = false;
              return;
            }
            const doc = pushToObject(docs)
            if (doc.schoolKey !== this.school.key) {
              this.resetLocal()
              this.error = "Invalid your email and password or access denied.";
              this.router.navigate(['/auth']);
              this.process = false;
              return;
            }
            console.log('doc', toJS(doc));

            localStorage.setItem('user', JSON.stringify(this.user));
            JSON.parse(localStorage.getItem('user'));
            this.router.navigate(['/']);
            this.process = false;
          }
        });
      }).catch((error) => {
        this.error = error;
        this.process = false;
      })
  }

  // Sign up with email/password
  @action
  signUp(email, password) {
    return this.auth.authRef().createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.setUserData(result.user);
        // this.sendVerificationMail();

      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verification when new user sign up
  @action
  sendVerificationMail() {
    return this.user.sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email-address']);
      })
  }

  // Reset Forgot password
  @action
  forgotPassword(passwordResetEmail) {
    return this.auth.authRef().sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  @computed
  get isLoggedIn(): boolean {
    const user = localStorage.getItem('user');
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return user !== null ? true : false;
  }

  // Sign in with Google
  @action
  googleAuth() {
    return this.authLogin(new auth.GoogleAuthProvider()).then(() => {
      this.router.navigate(['dashboard']);
    });
  }

  @action
  resetPassword(email) {
    return this.auth.authRef().sendPasswordResetEmail(email);
  }

  // Auth logic to run auth providers
  @action
  authLogin(provider) {
    return this.auth.authRef().signInWithPopup(provider)
      .then((result) => {
        this.setUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  @action
  setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.auth.userRef().doc(user.uid);
    const userData: any = {
      key: user.uid,
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out
  @action
  signOut() {
    this.error = null;
    this.fetchCanActiveRef && this.fetchCanActiveRef.unsubscribe();
    this.fetchCanActiveAuthRef && this.fetchCanActiveAuthRef.unsubscribe();
    this.requestPermissionNotificationRef && this.requestPermissionNotificationRef.unsubscribe();
    this.fetchNotificationsRef && this.fetchNotificationsRef.unsubscribe();
    this.fetchCampusNotificationsRef && this.fetchCampusNotificationsRef.unsubscribe();
    this.fetchPermissionModulesRef && this.fetchPermissionModulesRef.unsubscribe();
    this.fetchPermissionProgramsRef && this.fetchPermissionProgramsRef.unsubscribe();

    this.fetchCompanyRef && this.fetchCompanyRef.unsubscribe();
    this.auth.authRef().signOut().then(() => {
      this.user = null;
      this.uid = null;
      this.selectedCampus = null;
      this.selectedSchool = null;
      this.selectedCompany = null;
      this.memberOf = null;
      this.selectedTerm = null;
      this.selectedYear = null;
      this.terms = [];
      this.root = false;
      this.appModules = [];
      this.appModulePermissions = [];
      this.apps = [];
      this.config = null;
      this.sysOption = null;
      this.academicEnv = null;
      this.appPrograms = [];
      this.userStore.clearStore();
      this.router.navigate(["/auth/login"]);
      this.resetLocal()
    })
  }

}
