import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cashierByCampus'
})
export class CashierByCampusPipe implements PipeTransform {

  transform(values: Array<any>, args?: any): any {
    if (args.isAdmin || args.isAllCampus) {
      return values;
    } else {
      return values.filter(m => m.campusKey === args.campusKey);
    }
  }
}


@Pipe({
  name: 'userByCampus'
})
export class UserByCampusPipe implements PipeTransform {

  transform(values: Array<any>, args?: any): any {
    if (args.isAdmin || args.isAllCampus) {
      return values;
    } else {
      return values.filter(m => m.campusKey === args.campusKey);
    }
  }
}


@Pipe({
  name: 'campusByUser'
})
export class CampusByUserPipe implements PipeTransform {

  transform(values: Array<any>, args?: any): any {
    if (args.isAdmin || args.isAllCampus) {
      return values;
    } else {
      return values.filter(m => m.key === args.campusKey);
    }
  }
}




