<form [formGroup]="form" class="dialog-wrapper form-dialog-wrapper" *mobxAutorun>
    <div class="dialog-header">
        <div class="title">
            Change password
        </div>
        <div class="flex1"></div>
        <a mat-dialog-close class="close-dialog" (click)="dialogRef.close('no')">
            <i class="material-icons">&#xE14C;</i>
        </a>
    </div>
    <div class="dialog-content" style="padding: 0;">
        <div class="form-wrapper mat-dialog-container-bg">
            <div class="form-group ">
                <mat-form-field appearance="outline">
                    <mat-label>Current password</mat-label>
                    <input type="password" #focusInput placeholder="Enter your current password" matInput
                        formControlName="oldPassword">
                    <mat-error *ngIf="form.controls['oldPassword'].hasError('required')">
                        Please fill the information above.
                    </mat-error>
                    <mat-error *ngIf="form.controls['oldPassword'].hasError('minlength')">
                        Your password must be at least 6 characters long.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group ">
                <mat-form-field appearance="outline">
                    <mat-label>New password</mat-label>
                    <input type="password" placeholder="Enter min 6 char" matInput formControlName="password">
                    <mat-error *ngIf="form.controls['password'].hasError('required')">
                        Please fill the information above.
                    </mat-error>
                    <mat-error *ngIf="form.controls['password'].hasError('minlength')">
                        Your password must be at least 6 characters long.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group ">
                <mat-form-field appearance="outline">
                    <mat-label>Confirm password</mat-label>
                    <input type="password" placeholder="Enter new password again" matInput formControlName="confirm">
                    <mat-error *ngIf="form.controls['confirm'].hasError('required')">
                        Please fill the information above.
                    </mat-error>
                    <mat-error *ngIf="form.controls['confirm'].hasError('minlength')">
                        Your password must be at least 6 characters long.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="dialog-footer">
        <div class="flex-1"></div>
        <a mat-button class="mat-puc cancel" (click)="dialogRef.close('no')"><span>Cancel</span></a>
        <app-primary-button (onPress)="onSave(form.value)" [disable]="!form.valid" [process]="processClose"
            label="Update">
        </app-primary-button>
    </div>
</form>