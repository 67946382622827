import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { RECEIVED_STATUS } from '../dummy/app';
import { MOVEMENT_STATUS_DATA } from '../dummy/data';
import { Pages } from '../dummy/pages';
import { AuthStore } from '../stores/auth.store';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private db: AngularFirestore,
    private auth: AuthStore
  ) {

  }

  accountSettingRef() {
    return this.storeRef().collection('sys_config').doc(`general`);
  }

  journal() {
    return this.db.collection('stores').doc(this.auth.storeKey).collection('general_journals')
  }

  generalJournalRef() {
    return this.firestore().collection('general_journals');
  }

  displayText(item: any): string {
    return item ? item.text : item;
  }

  displayName(item: any): string {
    return item ? item.name : item;
  }

  displayFullName(item: any): string {
    return item ? item.full_name : item;
  }


  compareObject(o1: any, o2: any): boolean {
    if (o2 && o1) {
      return o1.key === o2.key;
    }
    return false;
  }

  firestore() {
    return this.db.firestore;
  }

  batch() {
    return this.db.firestore.batch();
  }

  createId() {
    return this.db.createId();
  }

  serverTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  storeRef() {
    return this.db.collection('stores').doc(this.auth.storeKey)
  }

  storeFireRef() {
    return this.firestore().collection('stores').doc(this.auth.storeKey)
  }

  customersRef() {
    return this.storeRef().collection('customers')
  }

  suppliersRef() {
    return this.storeRef().collection('suppliers')
  }

  employeesRef(branchKey) {
    return this.db.collection('employees', ref => ref.where('campus.key', '==', branchKey))
  }


  usersRef() {
    return this.db.collection('users')
  }

  supplierRef(lastVisible?: any) {
    return this.storeRef().collection('suppliers', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  unitMeasurementRef(lastVisible?: any) {
    return this.storeRef().collection('unit_measurements', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  paymentTermsRef(lastVisible?: any) {
    return this.storeRef().collection('payment_terms', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  policyRemarksListRef() {
    return this.storeRef().collection('policy_remarks', ref => ref.orderBy('name'))
  }

  policyRemarksRef(lastVisible?: any) {
    return this.storeRef().collection('policy_remarks', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  timeAttendancePolicyRef(userTypeKey, lastVisible?: any) {
    return this.storeRef().collection('time_and_attendances_policy', ref => {
      let condition = ref.where('userType.key', '==', userTypeKey).orderBy('fromHoursNumber');
      return condition;
    })
  }

  categoryRef(lastVisible?: any) {
    return this.storeRef().collection('categories', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  transcriptTemplateStoreRef(queryFn?: QueryFn) {
    return this.storeRef().collection('transcript_templates', queryFn);
  }

  transcriptTemplateContainerRef(templateKey, queryFn?: QueryFn) {
    return this.storeRef().collection('transcript_templates').doc(templateKey).collection("transcript_template_containers", queryFn);
  }

  transcriptTemplateRef(lastVisible?: any) {
    return this.storeRef().collection('transcript_templates', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  stockTransferRef(branchKey?, displayStatus?: any, lastVisible?: any) {
    return this.storeRef().collection('stock_transfers', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      if (branchKey) {
        condition = condition.where('branch.key', '==', branchKey)
      }
      if (displayStatus && displayStatus !== 'all') {
        const movementStatus = MOVEMENT_STATUS_DATA[displayStatus];
        if (movementStatus) {
          condition = condition.where('movementStatus.key', '==', movementStatus.key)
        }
        else {
          condition = condition.where('movementStatus.key', '==', displayStatus)
        }
      }
      return condition;
    })
  }

  stockReceivedTransferRef(branchKey, displayStatus?: any, lastVisible?: any) {
    return this.storeRef().collection('stock_transfers', ref => {
      let condition = ref.where('branchReceived.key', '==', branchKey)
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      if (displayStatus !== 'all') {
        const movementStatus = RECEIVED_STATUS[displayStatus];
        if (movementStatus) {
          condition = condition.where('receivedStatus.key', '==', movementStatus.key)
        }
        else {
          condition = condition.where('receivedStatus.key', '==', displayStatus)
        }
      }
      return condition.orderBy('created_at', 'desc').limit(Pages.size);
    })
  }

  billRef(lastVisible?: any, displayStatus?: any) {
    return this.storeRef().collection('bills', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      const movementStatus = MOVEMENT_STATUS_DATA[displayStatus];
      if (displayStatus && displayStatus !== 'all' && movementStatus) {
        condition = condition.where('movementStatus.key', '==', movementStatus.key)
      }
      return condition;
    })
  }

  stockAdjustmentRef(lastVisible?: any, status?: any) {
    return this.storeRef().collection('stock_adjustments', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (status) {
        condition = condition.where("movementStatus.key", "==", status.key)
      }
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  journalRef(lastVisible?: any, status?: any) {
    return this.storeRef().collection('journal_entry', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (status) {
        condition = condition.where("movementStatus.key", "==", status.key)
      }
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  pettyCashRef(lastVisible?: any, status?: any) {
    return this.storeRef().collection('petty_cash', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (status) {
        condition = condition.where("movementStatus.key", "==", status.key)
      }
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }



  billsRef(lastVisible?: any) {
    return this.storeRef().collection('bills', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }


  stockAdjustmentHeaderRef() {
    return this.storeRef().collection('stock_adjustments');
  }

  stockAdjustmentStoreRef() {
    return this.storeFireRef().collection('stock_adjustments');
  }

  stockTransferStoreRef() {
    return this.storeFireRef().collection('stock_transfers');
  }

  billFireRef() {
    return this.storeFireRef().collection('bills');
  }

  accountJournalEntryStoreRef() {
    return this.storeFireRef().collection('journal_entry');
  }

  accountPettyCashStoreRef() {
    return this.storeFireRef().collection('petty_cash');
  }


  //billDetailRef
  billDetailItemRef(headerKey) {
    return this.billFireRef().doc(headerKey).collection('bill_items')
  }

  //billHeaderRef
  billHeaderRef() {
    return this.db.collection("bills");
  }
  storeConfigRef() {
    return this.storeFireRef().collection('sys_config').doc('general');
  }

  categoryDataRef() {
    return this.storeRef().collection('categories', ref => ref.orderBy('name'));
  }

  productsRef(productTypeKey, lastVisible?: any) {
    return this.storeRef().collection('products', ref => {
      let condition = ref.where('productType.key', '==', productTypeKey).orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  productRef(ref?: QueryFn) {
    return this.storeRef().collection(`products`, ref)
  }

  productDBRef() {
    return this.storeRef().collection(`products`)
  }

  productByBranchRef(branchKey) {
    return this.storeRef().collection('branches').doc(branchKey).collection(`products`, ref => ref.orderBy('name'))
  }

  productByBranchStoreRef(branchKey, productId) {
    return this.storeRef().collection('branches').doc(branchKey).collection(`products`).doc(productId)
  }

  unitConverterRef(unitKey, lastVisible?: any) {
    return this.storeRef().collection('unit_measurements').doc(unitKey).collection('unit_converters', ref => {
      let condition = ref.orderBy('created_at', 'desc').limit(Pages.size);
      if (lastVisible) {
        condition = condition.startAfter(lastVisible['created_at'])
      }
      return condition;
    })
  }

  unitMeasurementDataRef() {
    return this.storeRef().collection('unit_measurements', ref => ref.orderBy('name'))
  }

}
