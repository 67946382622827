import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { AuthGuard } from './core/guards/auth.guard';
import { SecureInnerPagesGuard } from './core/guards/secure-inner-pages.guard';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [SecureInnerPagesGuard],
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },

  {
    path: '',
    canActivate: [AuthGuard], component: MainLayoutComponent,
    loadChildren: () => import('./modules/library/library.module').then(m => m.LibraryModule)
  },


  // Fallback when no prior routes is matched
  // { path: 'page-not-found', component: PageNotFoundComponent },
  // { path: '**', redirectTo: '/page-not-found', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
