import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'permissionApp'
})
export class PermissionAppPipe implements PipeTransform {
  transform(data: Array<any>, args: string): any {
    let result = false;
    if (data && data.length > 0)
      result = data.filter(m => m.key === args).length > 0 ? true : false;
    return result;
  }
}
