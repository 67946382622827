import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBookCheckbox'
})
export class FilterBookCheckboxPipe implements PipeTransform {
  constructor() { }
  transform(formValue: string, book: any, type: string): any {
    const arrays: any[] = formValue[type];
    if(!arrays) return false;
    const check = arrays.find(obj => obj?.book?.key == book?.book?.key) ? true : false;
    return check;
  }
}
